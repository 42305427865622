import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import TextBox from '../../formInputs/TextBox';
import RadioGroup from '../../formInputs/RadioGroup';
import DropDown from '../../formInputs/DropDown';
import { Block } from './../../components';

class USCitizenStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4>{translation.TAXATIONPLACE}:</h4>

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.Countryoftaxresidence}</ControlLabel>
              <Field
                name="countryOfTax"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.CountryTaxResidence}
                component={DropDown}
                options={[
                  { key: 'France', text: 'France', value: 'France' },
                  { key: 'Angleterre', text: 'Angleterre', value: 'Angleterre' },
                  { key: 'Allemagne', text: 'Allemagne', value: 'Allemagne' },
                  { key: 'Belgique', text: 'Belgique', value: 'Belgique' },
                  { key: 'Espagne', text: 'Espagne', value: 'Espagne' },
                  { key: 'Italie', text: 'Italie', value: 'Italie' },
                  { key: 'Autriche', text: 'Autriche', value: 'Autriche' },
                  { key: 'Luxembourg', text: 'Luxembourg', value: 'Luxembourg' }
                ]}
              />
            </Block>
          </Col>

        </Row>


        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.DoyouhaveanUSgreencard} ?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isUSGreenCard"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>
                {translation.IsthesubscriberUSPersonorUStaxresident}
                <a target="_blank" href="/us-citizen-info">
                  <i className="fa fa-info-circle info-ic cs-info-ic" aria-hidden="true" />
                </a>
              </ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isUSPersonOrTaxRes"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>
                {translation.IsthepersonpayingthepremiumsMoreInformation}
              </ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isPayingPremiumUS"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.IsoneofthenamedbeneficiariesUStaxresident}? </ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isBeneficiariesUS"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>
      </Form>
    );
  }
}

USCitizenStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  previousPage: PropTypes.func.isRequired
};

export default connect(
  state => ({
    translation: state.translation.keys
    // initialValues: state.companies.resendInviteItem,
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate: reduxFormValidator({
  //   name: {
  //     required: true,
  //   },
  //   email: {
  //     required: true,
  //   }
  // }),
})(USCitizenStep));
