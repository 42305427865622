import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Table } from 'react-bootstrap';
import { Block } from './../../components';

class Benchmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    this.updateData(this.props.portfolioWorldRegions);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioWorldRegions !== nextProps.portfolioWorldRegions) {
      this.updateData(nextProps.portfolioWorldRegions);
    }
  }

  updateData = (portfolioWorldRegions) => {
    const data = [];
    if (portfolioWorldRegions) {
      let other = 0;
      portfolioWorldRegions.map((item) => {
        if (item[1] < 5) {
          other += item[1];
        } else {
          data.push({
            name: item[0],
            stock: item[1],
          });
        }
        return true;
      });
      data.push({
        name: 'Other',
        stock: other.toFixed(2),
      });
    }
    this.setState({
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      data,
      column,
      direction
    } = this.state;
    // console.log(data);
    const { translation } = this.props;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        return (
          <tr key={item.name}>
            <td>{item.name}</td>
            <td>{item.stock}%</td>
          </tr>
        );
      });
    }

    return (
      <Block className="d-block">
        <Block className="table-responsive custom-table table-heading-bg">
          <Table className="table">
            <thead>
              <tr>
                <th
                  className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('name')}
                >
                  {translation.Name}
                </th>
                <th
                  className={column === 'stock' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('stock')}
                >
                  {translation.Stock}
                </th>
              </tr>
            </thead>

            <tbody>
              {tableData}
            </tbody>
          </Table>
        </Block>
      </Block>
    );
  }
}


Benchmark.propTypes = {
  portfolioWorldRegions: PropTypes.any,   
  translation: PropTypes.any.isRequired
};

Benchmark.defaultProps = {
  dispatch: null,
  portfolioWorldRegions: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioWorldRegions: state.chart.portfolioWorldRegions,
    portfolioId: state.auth.selectedPortfolioId,
    translation: state.translation.keys
  }))(Benchmark);

