import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { Field } from 'redux-form';
import moment from 'moment';

import { Block } from './../../components';
import { setSignupMultiFields } from './../../redux/modules/auth';
import DropDown from '../../formInputs/DropDown';

class FieldsPEA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.signUpMultiFields.PEA
    };
  }

  addLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows + 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.PEA = rows + 1;
    dispatch(setSignupMultiFields(obj));
  }

  deleteLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows - 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.PEA = rows - 1;
    dispatch(setSignupMultiFields(obj));
  }

  render() {
    const openingYearsOps = [];
    const { translation } = this.props;
    for (let i = 1992; i <= moment().format('YYYY'); i++) {
      const r = {
        key: i, text: i, value: i
      };
      openingYearsOps.push(r);
    }
    const { rows } = this.state;
    const fields = [];
    for (let i = 1; i <= rows; i++) {
      fields.push(
        <Row key={i} className="add-row">
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.TotalPayment}</ControlLabel>
              <Field
                name={`PEA_totalPayment_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Total Payment"
                component={DropDown}
            
                options={[
                  { key: 'one', text: `${translation.Between} 1k€ et 50k€`, value: '1K - 50K' },
                  { key: 'two', text: `${translation.Between} 50k€ et 100k€`, value: '50K - 100K' },
                  { key: 'three', text: `${translation.Between} 100k€ et 140k€`, value: '100K - 140K' },
                  { key: 'four', text: `${translation.GreaterThan} 140k€`, value: '> 140K' }
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.OpeningYear}</ControlLabel>
              <Field
                name={`PEA_OpeningYear_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Opening Year"
                component={DropDown}
                options={openingYearsOps}
              />
            </Block>
          </Col>

          {i !== 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.deleteLine}>
              <i className="material-icons red-text">remove_circle</i>
            </Block>
          }
          {i === 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.addLine}>
              <i className="material-icons">add_circle</i>
            </Block>
          }
        </Row>
      );
    }
    return (
      <React.Fragment>
        {fields}
      </React.Fragment>
    );
  }
}

FieldsPEA.propTypes = {
  dispatch: PropTypes.func,
  signUpMultiFields: PropTypes.any
};

FieldsPEA.defaultProps = {
  dispatch: null,
  signUpMultiFields: {}
};

export default connect(
  state => ({
    signUpMultiFields: state.auth.signUpMultiFields,
    translation: state.translation.keys
  })
)(FieldsPEA);
