import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'semantic-ui-css/semantic.min.css';
import ScrollToTop from '../../components/ScrollToTop';
import routes from '../../routes';
import './App.css';
import '../../style/css/main.css';
import '../../style/fonts/fonts.css';

const App = ({ children }) => (
  <Router>
    <Switch>
      <ScrollToTop>
        <ReduxToastr
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
        <div className="equalHeight">{renderRoutes(routes)}</div>
      </ScrollToTop>
    </Switch>
  </Router>
);

App.propTypes = {
  children: PropTypes.object,
};

App.defaultProps = {
  children: null,
  data: null
};

export default App;
