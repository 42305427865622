import store from 'store2';
import { push } from 'react-router-redux';

const LOAD = 'auth/LOAD';
const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
const LOAD_FAIL = 'auth/LOAD_FAIL';

const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';

const FORGOTPASSWORD = 'auth/FORGOTPASSOWRD';
const FORGOTPASSWORD_SUCCESS = 'auth/FORGOTPASSOWRD_SUCCESS';
const FORGOTPASSWORD_FAIL = 'auth/FORGOTPASSOWRD_FAIL';

const RESETPASSWORD = 'auth/RESETPASSWORD';
const RESETPASSWORD_SUCCESS = 'auth/RESETPASSWORD_SUCCESS';
const RESETPASSWORD_FAIL = 'auth/RESETPASSWORD_FAIL';

const CHANGEPASSWORD = 'auth/CHANGEPASSWORD';
const CHANGEPASSWORD_SUCCESS = 'auth/CHANGEPASSWORD_SUCESS';
const CHANGEPASSWORD_FAIL = 'auth/CHANGEPASSWORD_FAIL';

const ADMIN_CONFIG_DATA = 'auth/ADMIN_CONFIG_DATA';
const ADMIN_CONFIG_DATA_SUCCESS = 'auth/ADMIN_CONFIG_DATA_SUCESS';
const ADMIN_CONFIG_DATA_FAIL = 'auth/ADMIN_CONFIG_DATA_FAIL';

const ADMINCONFIG = 'auth/ADMINCONFIG';
const ADMINCONFIG_SUCCESS = 'auth/ADMINCONFIG_SUCCESS';
const ADMINCONFIG_FAIL = 'auth/ADMINCONFIG_FAIL';

const ADMINLINKING = 'auth/ADMINLINKING';
const ADMINLINKING_SUCCESS = 'auth/ADMINLINKING_SUCCESS';
const ADMINLINKING_FAIL = 'auth/ADMINLINKING_FAIL';

const USERS_ALL = 'auth/USERS_ALL';
const USERS_ALL_SUCCESS = 'auth/USERS_ALL_SUCCESS';
const USERS_ALL_FAIL = 'auth/USERS_ALL_FAIL';

const NEW_POLICIES = 'auth/NEW_POLICIES';
const NEW_POLICIES_SUCCESS = 'auth/NEW_POLICIES_SUCCESS';
const NEW_POLICIES_FAIL = 'auth/NEW_POLICIES_FAIL';

const SETPASSWORD = 'auth/SETPASSWORD';
const SETPASSWORD_SUCCESS = 'auth/SETPASSWORD_SUCCESS';
const SETPASSWORD_FAIL = 'auth/SETPASSWORD_FAIL';

const UPDATE_TERMSOFSERVICE = 'auth/UPDATE_TERMSOFSERVICE';
const UPDATE_TERMSOFSERVICE_SUCCESS = 'auth/UPDATE_TERMSOFSERVICE_SUCCESS';
const UPDATE_TERMSOFSERVICE_FAIL = 'auth/UPDATE_TERMSOFSERVICE_FAIL';

const UPDATE_PERSONAL_PROFILE = 'auth/UPDATE_PERSONAL_PROFILE';
const UPDATE_PERSONAL_PROFILE_SUCCESS = 'auth/UPDATE_PERSONAL_PROFILE_SUCCESS';
const UPDATE_PERSONAL_PROFILE_FAIL = 'auth/UPDATE_PERSONAL_PROFILE_FAIL';

const UPDATE_PROFESSIONAL_PROFILE = 'auth/UPDATE_PROFESSIONAL_PROFILE';
const UPDATE_PROFESSIONAL_PROFILE_SUCCESS = 'auth/UPDATE_PROFESSIONAL_PROFILE_SUCCESS';
const UPDATE_PROFESSIONAL_PROFILE_FAIL = 'auth/UPDATE_PROFESSIONAL_PROFILE_FAIL';

const UPDATE_ANNUAL_PROFILE = 'auth/UPDATE_ANNUAL_PROFILE';
const UPDATE_ANNUAL_PROFILE_SUCCESS = 'auth/UPDATE_ANNUAL_PROFILE_SUCCESS';
const UPDATE_ANNUAL_PROFILE_FAIL = 'auth/UPDATE_ANNUAL_PROFILE_FAIL';

const UPDATE_REAL_DEBT = 'auth/UPDATE_REAL_DEBT';
const UPDATE_REAL_DEBT_SUCCESS = 'auth/UPDATE_REAL_DEBT_SUCCESS';
const UPDATE_REAL_DEBT_FAIL = 'auth/UPDATE_REAL_DEBT_FAIL';

const UPDATE_KNOWLEDGE_PROFILE = 'auth/UPDATE_KNOWLEDGE_PROFILE';
const UPDATE_KNOWLEDGE_PROFILE_SUCCESS = 'auth/UPDATE_KNOWLEDGE_PROFILE_SUCCESS';
const UPDATE_KNOWLEDGE_PROFILE_FAIL = 'auth/UPDATE_KNOWLEDGE_PROFILE_FAIL';

const UPDATE_PRACTICES_PROFILE = 'auth/UPDATE_PRACTICES_PROFILE';
const UPDATE_PRACTICES_PROFILE_SUCCESS = 'auth/UPDATE_PRACTICES_PROFILE_SUCCESS';
const UPDATE_PRACTICES_PROFILE_FAIL = 'auth/UPDATE_PRACTICES_PROFILE_FAIL';

const UPDATE_GOAL_PROFILE = 'auth/UPDATE_GOAL_PROFILE';
const UPDATE_GOAL_PROFILE_SUCCESS = 'auth/UPDATE_GOAL_PROFILE_SUCCESS';
const UPDATE_GOAL_PROFILE_FAIL = 'auth/UPDATE_GOAL_PROFILE_FAIL';

const USER_DATA = 'auth/USER_DATA';
const USER_DATA_SUCCESS = 'auth/USER_DATA_SUCCESS';
const USER_DATA_FAIL = 'auth/USER_DATA_FAIL';

const SIGNUP = 'auth/SIGNUP';
const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'auth/SIGNUP_FAIL';

const SIGNUPPAYMENT = 'auth/SIGNUPPAYMENT';
const SIGNUPPAYMENT_SUCCESS = 'auth/SIGNUPPAYMENT_SUCCESS';
const SIGNUPPAYMENT_FAIL = 'auth/SIGNUPPAYMENT_FAIL';

const PAYMENTDETAIL = 'auth/PAYMENTDETAIL';
const PAYMENTDETAIL_SUCCESS = 'auth/PAYMENTDETAIL_SUCCESS';
const PAYMENTDETAIL_FAIL = 'auth/PAYMENTDETAIL_FAIL';

const CHARITY = 'auth/CHARITY';
const CHARITY_SUCCESS = 'auth/CHARITY_SUCCESS';
const CHARITY_FAIL = 'auth/CHARITY_FAIL';

const PAYMENTAMOUNT = 'auth/PAYMENTAMOUNT';
const PAYMENTAMOUNT_SUCCESS = 'auth/PAYMENTAMOUNT_SUCCESS';
const PAYMENTAMOUNT_FAIL = 'auth/PAYMENTAMOUNT_FAIL';

const USERFUNDS = 'auth/USERFUNDS';
const USERFUNDS_SUCCESS = 'auth/USERFUNDS_SUCCESS';
const USERFUNDS_FAIL = 'auth/USERFUNDS_FAIL';


const ADDNEWFUNDS = 'auth/ADDNEWFUNDS';
const ADDNEWFUNDS_SUCCESS = 'auth/ADDNEWFUNDS_SUCCESS';
const ADDNEWFUNDS_FAIL = 'auth/ADDNEWFUNDS_FAIL';

const SWITCHFUNDS = 'auth/SWITCHFUNDS';
const SWITCHFUNDS_SUCCESS = 'auth/SWITCHFUNDS_SUCCESS';
const SWITCHFUNDS_FAIL = 'auth/SWITCHFUNDS_FAIL';

const USERFUNDSALLDETAIL = 'auth/USERFUNDSALLDETAIL';
const USERFUNDSALLDETAIL_SUCCESS = 'auth/USERFUNDSALLDETAIL_SUCCESS';
const USERFUNDSALLDETAIL_FAIL = 'auth/USERFUNDSALLDETAIL_SUCCESS';

const CHARITYLIST = 'auth/CHARITYLIST';
const CHARITYLIST_SUCCESS = 'auth/CHARITYLIST_SUCCESS';
const CHARITYLIST_FAIL = 'auth/CHARITYLIST_SUCCESS';

const UPDATE_PLAN_PAYMENT = 'auth/UPDATE_PLAN_PAYMENT';
const UPDATE_PLAN_PAYMENT_SUCCESS = 'auth/UPDATE_PLAN_PAYMENT_SUCCESS';
const UPDATE_PLAN_PAYMENT_FAIL = 'auth/UPDATE_PLAN_PAYMENT_SUCCESS';

const UPDATE_PLAN = 'auth/UPDATE_PLAN';
const UPDATE_PLAN_SUCCESS = 'auth/UPDATE_PLAN_SUCCESS';
const UPDATE_PLAN_FAIL = 'auth/UPDATE_PLAN_SUCCESS';

const PLAN_HISTORY = 'auth/PLAN_HISTORY';
const PLAN_HISTORY_SUCCESS = 'auth/PLAN_HISTORY_SUCCESS';
const PLAN_HISTORY_FAIL = 'auth/PLAN_HISTORY_SUCCESS';

const UPDATE_USER_INFO = 'auth/UPDATE_USER_INFO';
const UPDATE_USER_INFO_SUCCESS = 'auth/UPDATE_USER_INFO_SUCCESS';
const UPDATE_USER_INFO_FAIL = 'auth/UPDATE_USER_INFO_SUCCESS';

const SET_PORTFOLIO_ID = 'auth/SET_PORTFOLIO_ID';

const SET_SIGNUP_MULTI_FIELDS = 'auth/SET_SIGNUP_MULTI_FIELDS';


const LOGOUT = 'auth/LOGOUT';

const FLUSH = 'auth/FLUSH';

const signUpMultiFields = {
  beneficiary: 1,
  PEA: 1,
  wealth: 1
};

const initialState = {
  loadBusy: false,
  loadError: false,
  user: null,
  loginBusy: false,
  loginError: null,
  message: null,
  forgotPasswordBusy: false,
  forgotPasswordMessage: null,
  resetPasswordBusy: false,
  resetPasswordMessage: null,
  resetPasswordRedirect: false,
  personalProfileBusy: false,
  personalProfileError: false,
  userDataBusy: false,
  userDataBusyError: false,
  userData: null,
  changePasswordBusy: false,
  changePasswordError: null,
  changePassword: null,
  adminConfigDataError: null,
  adminConfigDataBusy: false,
  adminConfigData: null,
  adminLinkingError: null,
  adminLinkingBusy: false,
  adminLinking: null,
  usersAllError: null,
  usersAllBusy: false,
  usersAll: null,
  newPolices: null,
  newPolicesBusy: false,
  newPolicesError: null,
  signUp: null,
  signUpBusy: false,
  signUpError: null,
  charity: null,
  charityBusy: false,
  charityError: null,
  signUpPayment: null,
  signUpPaymentBusy: false,
  signUpPaymentError: null,
  paymentDetail: null,
  paymentDetailBusy: false,
  paymentDetailError: null,
  paymentAmount: null,
  paymentAmountBusy: false,
  paymentAmountError: null,
  userFunds: null,
  userFundsBusy: false,
  userFundsError: null,
  userFundsAllDetail: null,
  userFundsAllDetailBusy: false,
  userFundsAllDetailError: null,
  addNewFunds: null,
  addNewFundsBusy: false,
  addNewFundsError: null,
  switchFunds: null,
  switchFundsBusy: false,
  switchFundsError: null,
  professionalProfile: null,
  professionalProfileBusy: false,
  professionalProfileError: null,
  annualProfileBusy: false,
  annualProfileError: null,
  realDebtBusy: false,
  realDebtError: null,
  knowledgeProfileBusy: false,
  knowledgeProfileError: null,
  practicesProfileBusy: false,
  practicesProfileError: null,
  goalProfileBusy: false,
  goalProfileError: null,
  selectedPortfolioId: undefined,
  signUpMultiFields,
  charitylist: null,
  charitylistBusy: false,
  charitylistError: null,
  planHistory: [],
  planHistoryBusy: false,
  planHistoryError: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return { ...state, loadBusy: true, loadError: null };

    case LOAD_SUCCESS:
      return { ...state, loadBusy: false, user: action.user };

    case LOAD_FAIL:
      return { ...state, loadBusy: false, user: null, loadError: action.error };

    case LOGIN:
      return { ...state, loginBusy: true, loginError: null };

    case LOGIN_SUCCESS:
      return { ...state, loginBusy: false, user: action.user };

    case LOGIN_FAIL:
      return { ...state, loginBusy: false, loginError: action.error, user: null };

    case FORGOTPASSWORD:
      return { ...state, forgotPasswordBusy: true };

    case FORGOTPASSWORD_SUCCESS:
      return { ...state, forgotPasswordBusy: false, forgotPasswordMessage: action.message };

    case FORGOTPASSWORD_FAIL:
      return { ...state, forgotPasswordBusy: false, forgotPasswordMessage: action.error };

    case UPDATE_PERSONAL_PROFILE:
      return { ...state, personalProfileBusy: true, personalProfileError: false };

    case UPDATE_PERSONAL_PROFILE_SUCCESS:
      return { ...state, personalProfileBusy: false, personalProfileError: action.message };

    case UPDATE_PERSONAL_PROFILE_FAIL:
      return { ...state, personalProfileBusy: false, personalProfileError: action.error };

    case UPDATE_PROFESSIONAL_PROFILE:
      return { ...state, professionalProfileBusy: true, professionalProfileError: false };

    case UPDATE_PROFESSIONAL_PROFILE_SUCCESS:
      return { ...state, professionalProfileBusy: false, professionalProfileError: action.message };

    case UPDATE_PROFESSIONAL_PROFILE_FAIL:
      return { ...state, professionalProfileBusy: false, professionalProfileError: action.error };

    case UPDATE_ANNUAL_PROFILE:
      return { ...state, annualProfileBusy: true, annualProfileError: false };

    case UPDATE_ANNUAL_PROFILE_SUCCESS:
      return { ...state, annualProfileBusy: false, annualProfileError: action.message };

    case UPDATE_ANNUAL_PROFILE_FAIL:
      return { ...state, annualProfileBusy: false, annualProfileError: action.error };

    case UPDATE_REAL_DEBT:
      return { ...state, knowledgeProfileBusy: true, knowledgeProfileError: false };

    case UPDATE_REAL_DEBT_SUCCESS:
      return { ...state, knowledgeProfileBusy: false, knowledgeProfileError: action.message };

    case UPDATE_REAL_DEBT_FAIL:
      return { ...state, knowledgeProfileBusy: false, knowledgeProfileError: action.error };

    case UPDATE_KNOWLEDGE_PROFILE:
      return { ...state, realDebtBusy: true, realDebtError: false };

    case UPDATE_KNOWLEDGE_PROFILE_SUCCESS:
      return { ...state, realDebtBusy: false, realDebtError: action.message };

    case UPDATE_KNOWLEDGE_PROFILE_FAIL:
      return { ...state, realDebtBusy: false, realDebtError: action.error };

    case UPDATE_PRACTICES_PROFILE:
      return { ...state, practicesProfileBusy: true, practicesProfileError: false };

    case UPDATE_PRACTICES_PROFILE_SUCCESS:
      return { ...state, practicesProfileBusy: false, practicesProfileError: action.message };

    case UPDATE_PRACTICES_PROFILE_FAIL:
      return { ...state, practicesProfileBusy: false, practicesProfileError: action.error };

    case UPDATE_GOAL_PROFILE:
      return { ...state, goalProfileBusy: true, goalProfileError: false };

    case UPDATE_GOAL_PROFILE_SUCCESS:
      return { ...state, goalProfileBusy: false, goalProfileError: action.message };

    case UPDATE_GOAL_PROFILE_FAIL:
      return { ...state, goalProfileBusy: false, goalProfileError: action.error };

    case USER_DATA:
      return { ...state, userDataBusy: true, userDataError: false };

    case USER_DATA_SUCCESS:
      return { ...state, userDataBusy: false, userData: action.userData };

    case USER_DATA_FAIL:
      return { ...state, userDataBusy: false, userDataError: action.error };

    case SIGNUP:
      return { ...state, signUpBusy: true, signUpError: false };

    case SIGNUP_SUCCESS:
      return { ...state, signUpBusy: false, signUp: action.res };

    case SIGNUP_FAIL:
      console.log("reducer -> action.error", action.error);
      return { ...state, signUpBusy: false, signUpError: action.error };

    case SIGNUPPAYMENT:
      return { ...state, signUpPaymentBusy: true, signUpPaymentError: false };

    case SIGNUPPAYMENT_SUCCESS:
      return { ...state, signUpPaymentBusy: false, signUpPayment: action.res };

    case SIGNUPPAYMENT_FAIL:
      return { ...state, signUpPaymentBusy: true, signUpPaymentError: action.error };

    case PAYMENTAMOUNT:
      return { ...state, paymentAmountBusy: true, paymentAmountError: false };

    case PAYMENTAMOUNT_SUCCESS:
      return { ...state, paymentAmountBusy: false, paymentAmount: action.res };

    case PAYMENTAMOUNT_FAIL:
      return { ...state, paymentAmountBusy: true, paymentAmountError: action.error };


    case PAYMENTDETAIL:
      return { ...state, paymentDetailBusy: true, paymentDetailError: false };

    case PAYMENTDETAIL_SUCCESS:
      return { ...state, paymentDetailBusy: false, paymentDetail: action.res };

    case PAYMENTDETAIL_FAIL:
      return { ...state, paymentDetailBusy: true, paymentDetailError: action.error };

    case ADDNEWFUNDS:
      return { ...state, addNewFundsBusy: true, addNewFundsError: false };

    case ADDNEWFUNDS_SUCCESS:
      return { ...state, addNewFundsBusy: false, addNewFunds: action.res };

    case ADDNEWFUNDS_FAIL:
      return { ...state, addNewFundsBusy: true, addNewFundsError: false };

    case SWITCHFUNDS:
      return { ...state, switchFundsBusy: true, switchFundsError: false };

    case SWITCHFUNDS_SUCCESS:
      return { ...state, switchFundsBusy: false, switchFunds: action.res };

    case SWITCHFUNDS_FAIL:
      return { ...state, switchFundsBusy: true, switchFundsError: false };

    case USERFUNDS:
      return { ...state, userFundsBusy: true, userFundsError: false };

    case USERFUNDS_SUCCESS:
      return { ...state, userFundsBusy: false, userFunds: action.res };

    case USERFUNDSALLDETAIL:
      return { ...state, userFundsAllDetailBusy: true, userFundsAllDetailError: false };

    case USERFUNDSALLDETAIL_SUCCESS:
      return { ...state, userFundsAllDetailBusy: false, userFundsAllDetail: action.res };

    case USERFUNDSALLDETAIL_FAIL:
      return { ...state, userFundsAllDetailBusy: true, userFundsAllDetailError: action.res };

    case CHARITY:
      return { ...state, chairtyBusy: true, charityError: false };

    case CHARITY_SUCCESS:
      return { ...state, chairtyBusy: false, charity: action.res };

    case CHARITY_FAIL:
      return { ...state, chairtyBusy: false, charityError: action.res };

    case CHANGEPASSWORD:
      return { ...state, changePasswordBusy: true, changePasswordError: false };

    case CHANGEPASSWORD_SUCCESS:
      return { ...state, changePasswordBusy: false, changePassword: action.res };

    case CHANGEPASSWORD_FAIL:
      return { ...state, changePasswordBusy: false, changePasswordError: action.error };

    case ADMINCONFIG:
      return { ...state, adminConfigBusy: true, adminConfigError: false };

    case ADMINCONFIG_SUCCESS:
      return { ...state, adminConfigBusy: false, adminConfigRes: action.res };

    case ADMINCONFIG_FAIL:
      return { ...state, adminConfigBusy: false, adminConfigError: action.error };

    case ADMIN_CONFIG_DATA:
      return { ...state, adminConfigDataBusy: true, adminConfigDataError: false };

    case ADMIN_CONFIG_DATA_SUCCESS:
      return { ...state, adminConfigDataBusy: false, adminConfigData: action.res };

    case ADMIN_CONFIG_DATA_FAIL:
      return { ...state, adminLinkingBusy: false, adminConfigDataError: action.error };

    case ADMINLINKING:
      return { ...state, adminLinkingBusy: true, adminLinkingError: false };

    case ADMINLINKING_SUCCESS:
      return { ...state, adminLinkingBusy: false, adminLinkingRes: action.res };

    case ADMINLINKING_FAIL:
      return { ...state, adminLinkingBusy: false, adminLinkingError: action.error };

    case USERS_ALL:
      return { ...state, usersAllBusy: true, usersAllError: false };

    case USERS_ALL_SUCCESS:
      return { ...state, usersAllBusy: false, usersAll: action.res };

    case USERS_ALL_FAIL:
      return { ...state, usersAllBusy: false, usersAllError: action.error };

    case NEW_POLICIES:
      return { ...state, newPoliciesBusy: true, newPoliciesError: false };

    case NEW_POLICIES_SUCCESS:
      return { ...state, newPoliciesBusy: true, newPolices: action.res };

    case NEW_POLICIES_FAIL:
      return { ...state, newPoliciesBusy: false, newPolicesError: action.error };

    case CHARITYLIST:
      return { ...state, chairtyBusy: true, charityError: false };

    case CHARITYLIST_SUCCESS:
      return { ...state, chairtyBusy: true, charity: action.res };

    case CHARITYLIST_FAIL:
      return { ...state, chairtyBusy: false, charityError: action.error };

    case PLAN_HISTORY:
      return { ...state, planHistoryBusy: true, planHistoryError: false };

    case PLAN_HISTORY_SUCCESS:
      return { ...state, planHistoryBusy: true, planHistory: action.res };

    case PLAN_HISTORY_FAIL:
      return { ...state, planHistoryBusy: false, planHistoryError: action.error };

    case RESETPASSWORD:
      return { ...state, resetPasswordBusy: true };

    case SET_PORTFOLIO_ID:
      return { ...state, selectedPortfolioId: action.portfolioId };

    case SET_SIGNUP_MULTI_FIELDS:
      return { ...state, signUpMultiFields: action.res };

    case SETPASSWORD:
      return state
        .set('resetPasswordBusy', true);
    case RESETPASSWORD_SUCCESS:
      return { ...state, resetPasswordBusy: false, resetPasswordMessage: action.message };
    case SETPASSWORD_SUCCESS:
      return state
        .set('resetPasswordBusy', false)
        .set('resetPasswordRedirect', action.redirect)
        .set('resetPasswordMessage', action.message);
    case RESETPASSWORD_FAIL:
      return { ...state, resetPasswordBusy: false, resetPasswordMessage: action.error };
    case SETPASSWORD_FAIL:
      return state
        .set('resetPasswordBusy', false)
        .set('resetPasswordMessage', action.error);
    case UPDATE_TERMSOFSERVICE:
      return state
        .set('loginBusy', true)
        .set('loginError', null);

    case UPDATE_TERMSOFSERVICE_SUCCESS:
      return state
        .set('loginBusy', false)
        .set('user', action.user);

    case UPDATE_TERMSOFSERVICE_FAIL:
      return state
        .set('loginBusy', false)
        .set('loginError', action.error)
        .set('user', null);
    case LOGOUT:
    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}

export const load = () => async (dispatch, getState, api) => {
  // dont call api if user data is in state
  const user = getState().auth.user;
  if (user) {
    return user;
  }

  dispatch({ type: LOAD });

  try {
    const data = await api.get('/session');
    if (!data) {
      return user;
    }
    dispatch({ type: LOAD_SUCCESS, user: data });
  } catch (error) {
    dispatch({ type: LOAD_FAIL, error });
  }
};

export const login = (email, password, role = 'user') => async (dispatch, getState, api) => {
  dispatch({ type: LOGIN });

  try {
    const data = await api.post('/session', { data: { email, password } });
    if (!data || !data.authToken) {
      dispatch({ type: LOGIN_FAIL, error: 'Missing data from api' });
      return;
    }
    console.log('data here');
    console.log(data);
    console.log(data.role);
    console.log(role);

    if (data) {
      if (data.user && data.user.role === 2 && role !== 'user') {
        dispatch({ type: LOGIN_FAIL, error: 'Wrong Username or Password' });
        return;
      }
      if (data.user && data.user.role === 1 && role !== 'admin') {
        dispatch({ type: LOGIN_FAIL, error: 'Wrong Username or Password' });
        return;
      }
    }

    // set authToken to local storage
    store('authToken', data.authToken);

    dispatch({ type: LOGIN_SUCCESS, user: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, error });
  }
};

export const forgotPassword = (email, host) => async (dispatch, getState, api) => {
  dispatch({ type: FORGOTPASSWORD });

  try {
    await api.post('/account/forgotPassword', { data: { email, host } });
    dispatch({ type: FORGOTPASSWORD_SUCCESS, message: 'Reset password link sent at registered email' });
  } catch (err) {
    dispatch({ type: FORGOTPASSWORD_FAIL, error: err });
  }
};

export const resetPassword = (password, confirmPassword, token) => async (dispatch, getState, api) => {
  dispatch({ type: RESETPASSWORD });

  try {
    if (password !== confirmPassword) {
      dispatch({ type: RESETPASSWORD_FAIL, error: 'Confirm password do not match' });
      return false;
    }
    await api.post('/account/reset-forgotpassword', { data: { password, confirmPassword, token } });
    dispatch({ type: RESETPASSWORD_SUCCESS, message: 'Password reset successfully', redirect: false });
  } catch (err) {
    dispatch({ type: RESETPASSWORD_FAIL, error: err });
  }
};

export const changePassword = (oldPassword, password, confirmPassword) => async (dispatch, getState, api) => {
  dispatch({ type: CHANGEPASSWORD });

  try {
    if (password !== confirmPassword) {
      dispatch({ type: CHANGEPASSWORD_FAIL, error: 'Confirm password do not match' });
      return false;
    }
    await api.post('/account/change-password', { data: { oldPassword, password, confirmPassword } });
    dispatch({ type: CHANGEPASSWORD_SUCCESS, res: 'Password reset successfully' });
  } catch (err) {
    dispatch({ type: CHANGEPASSWORD_FAIL, error: err });
  }
};

export const adminConfig = (dateformat, timezone) => async (dispatch, getState, api) => {
  dispatch({ type: ADMINCONFIG });
  try {
    await api.post('/account/config', { data: { dateformat, timezone } });
    dispatch({ type: ADMINCONFIG_SUCCESS, res: 'Config saved successfully' });
  } catch (err) {
    dispatch({ type: ADMINCONFIG_FAIL, error: err });
  }
};

export const adminLinking = data => async (dispatch, getState, api) => {
  dispatch({ type: ADMINLINKING });
  try {
    await api.post('/account/link-policy', { data: { data } });
    dispatch({ type: ADMINLINKING_SUCCESS, res: 'Config saved successfully' });
  } catch (err) {
    dispatch({ type: ADMINLINKING_FAIL, error: err });
  }
};


export const getadminConfig = () => async (dispatch, getState, api) => {
  dispatch({ type: ADMIN_CONFIG_DATA });
  try {
    const configData = await api.get('/account/config');
    dispatch({ type: ADMIN_CONFIG_DATA_SUCCESS, res: configData });
  } catch (err) {
    dispatch({ type: ADMIN_CONFIG_DATA_FAIL, error: err });
  }
};

export const getAllUsers = () => async (dispatch, getState, api) => {
  dispatch({ type: USERS_ALL });
  try {
    const configData = await api.get('/account/get-users');
    dispatch({ type: USERS_ALL_SUCCESS, res: configData });
  } catch (err) {
    dispatch({ type: USERS_ALL_FAIL, error: err });
  }
};

export const getNewPolicy = () => async (dispatch, getState, api) => {
  dispatch({ type: NEW_POLICIES });
  try {
    const policyData = await api.get('/account/get-policies');
    dispatch({ type: NEW_POLICIES_SUCCESS, res: policyData });
  } catch (err) {
    dispatch({ type: NEW_POLICIES_FAIL, error: err });
  }
};

export const setPassword = (password, confirmPassword, token) => async (dispatch, getState, api) => {
  dispatch({ type: SETPASSWORD });

  try {
    if (password !== confirmPassword) {
      dispatch({ type: SETPASSWORD_FAIL, error: 'Confirm password do not match' });
      return false;
    }
    await api.post('/account/set-password', { data: { password, confirmPassword, token } });
    dispatch({ type: SETPASSWORD_SUCCESS, message: 'Password set successfully', redirect: true });
  } catch (err) {
    dispatch({ type: SETPASSWORD_FAIL, error: err });
  }
};

export const updateTermsofService = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_TERMSOFSERVICE });

  try {
    const res = await api.put('/account/termsofservice', { data });
    dispatch({ type: UPDATE_TERMSOFSERVICE_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_TERMSOFSERVICE_FAIL, error: (err.message) ? err.message : err });
    return false;
  }
};

export const logout = () => (dispatch, getState, api) => {
  store.remove('authToken');
  dispatch({ type: LOGOUT });
  dispatch({ type: 'FLUSH' });
  dispatch(push('/'));
};

export const updatePersonalProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_PERSONAL_PROFILE });

  try {
    const res = await api.put('/account/personal-profile', { data });
    dispatch({ type: UPDATE_PERSONAL_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_PERSONAL_PROFILE_FAIL, error: err });
  }
};

export const updateProfessionalProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_PROFESSIONAL_PROFILE });

  try {
    const res = await api.put('/account/professional-profile', { data });
    dispatch({ type: UPDATE_PROFESSIONAL_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_PROFESSIONAL_PROFILE_FAIL, error: err });
  }
};

export const updateUserInfo = (data, userId) => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_USER_INFO });

  try {
    const res = await api.put(`/account/user-info/${userId}`, { data });
    dispatch({ type: UPDATE_USER_INFO_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_USER_INFO_FAIL, error: err });
  }
};

export const updateAnnualProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_ANNUAL_PROFILE });

  try {
    const res = await api.put('/account/annual-profile', { data });
    dispatch({ type: UPDATE_ANNUAL_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_ANNUAL_PROFILE_FAIL, error: err });
  }
};

export const updateRealDebtProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_REAL_DEBT });

  try {
    const res = await api.put('/account/realdebt-profile', { data });
    dispatch({ type: UPDATE_REAL_DEBT_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_REAL_DEBT_FAIL, error: err });
  }
};

export const updateKnowledgeProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_KNOWLEDGE_PROFILE });

  try {
    const res = await api.put('/account/knowledge-profile', { data });
    dispatch({ type: UPDATE_KNOWLEDGE_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_KNOWLEDGE_PROFILE_FAIL, error: err });
  }
};

export const updatePracticesProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_PRACTICES_PROFILE });

  try {
    const res = await api.put('/account/practices-profile', { data });
    dispatch({ type: UPDATE_PRACTICES_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_PRACTICES_PROFILE_FAIL, error: err });
  }
};

export const updateGoalProfile = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_GOAL_PROFILE });

  try {
    const res = await api.put('/account/goal-profile', { data });
    dispatch({ type: UPDATE_GOAL_PROFILE_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_GOAL_PROFILE_FAIL, error: err });
  }
};

export const signUp = data => async (dispatch, getState, api) => {
  dispatch({ type: SIGNUP });
  try {
    console.log('data  here comes');
    console.log(data);
    const res = await api.post('/account', { data });
    dispatch({ type: SIGNUP_SUCCESS, res });
    const redirectLink = '/thankyou';
    window.location.replace(redirectLink);
    return 'success'; // redirectLink; 
  } catch (err) {
    // console.log('error catch here');
    console.log(err);
    dispatch({ type: SIGNUP_FAIL, error: err });
  }
};

export const signUpPayment = data => async (dispatch, getState, api) => {
  dispatch({ type: SIGNUPPAYMENT });//

  try {
    console.log('take u to payment screen0');
    console.log(data);
    const res = await api.post('/zoho', { data });
    dispatch({ type: SIGNUPPAYMENT_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: SIGNUPPAYMENT_FAIL, error: err });
  }
};

export const paymentDetail = data => async (dispatch, getState, api) => {
  dispatch({ type: PAYMENTDETAIL });

  try {
    const res = await api.post('/zoho/payment', { data });
    dispatch({ type: PAYMENTDETAIL_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: PAYMENTDETAIL_FAIL, error: err });
  }
};

export const userFundsDetail = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: USERFUNDS });
  try {
    const res = await api.get(`/account/userfunds-fee/${id}`);
    dispatch({ type: USERFUNDS_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: USERFUNDS_FAIL, error: err });
  }
};

export const addFunds = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: ADDNEWFUNDS });
  try {
    const res = await api.get(`/account/add-funds/${id}`);
    dispatch({ type: ADDNEWFUNDS_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: ADDNEWFUNDS_FAIL, error: err });
  }
};

export const switchFunds = data => async (dispatch, getState, api) => {
  dispatch({ type: SWITCHFUNDS });

  try {
    const res = await api.post('/account/switch-funds', { data });
    dispatch({ type: SWITCHFUNDS_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: SWITCHFUNDS_FAIL, error: err });
  }
};

export const getUserFundsAllDetail = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: USERFUNDSALLDETAIL });
  try {
    const res = await api.get(`/account/userfunds-detail/${id}`);
    dispatch({ type: USERFUNDSALLDETAIL_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: USERFUNDSALLDETAIL_FAIL, error: err });
  }
};

export const getAllCharity = () => async (dispatch, getState, api) => {
  dispatch({ type: CHARITY });
  try {
    const res = await api.get('/account/charity-list');
    if (res) {
      dispatch({ type: CHARITY_SUCCESS, res });
    }
    return res;
  } catch (err) {
    dispatch({ type: CHARITY_FAIL, error: err });
  }
};

export const getPaymentAmount = () => async (dispatch, getState, api) => {
  dispatch({ type: PAYMENTAMOUNT });
  try {
    const res = await api.get('/account/payment-amount');
    if (res) {
      dispatch({ type: PAYMENTAMOUNT_SUCCESS, res });
    }
    return res;
  } catch (err) {
    dispatch({ type: PAYMENTAMOUNT_FAIL, error: err });
  }
};

export const getUserData = () => async (dispatch, getState, api) => {
  dispatch({ type: USER_DATA });

  try {
    const res = await api.get('/account/info');
    const user = getState().auth.user;
    if (user) {
      user.avatar = res.avatar;
      user.firstName = res.firstName;
      user.lastName = res.lastName;
      await dispatch({ type: LOAD_SUCCESS, user });
    }
    dispatch({ type: USER_DATA_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: USER_DATA_FAIL, error: err });
  }
};

export const setPortfolioId = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: SET_PORTFOLIO_ID, portfolioId });
  return true;
};

export const setSignupMultiFields = obj => async (dispatch, getState, api) => {
  dispatch({ type: SET_SIGNUP_MULTI_FIELDS, res: obj });
  return true;
};

export const updatePlanPayment = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_PLAN_PAYMENT });

  try {
    const res = await api.post('/account/update-plan-payment', { data });
    dispatch({ type: UPDATE_PLAN_PAYMENT_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_PLAN_PAYMENT_FAIL, error: err });
  }
};

export const updatePlan = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_PLAN });

  try {
    const res = await api.post('/account/update-plan', { data });
    dispatch({ type: UPDATE_PLAN_SUCCESS, userData: res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_PLAN_FAIL, error: err });
  }
};

export const getPlanHistory = data => async (dispatch, getState, api) => {
  dispatch({ type: PLAN_HISTORY });

  try {
    const res = await api.get('/account/plan-history');
    dispatch({ type: PLAN_HISTORY_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: PLAN_HISTORY_FAIL, error: err });
  }
};
