import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Row, Col, Label } from 'react-bootstrap';
import config from '../../clientConfig';

import { AuthenticatedAdmin, Block, Button } from './../../components';
import { DashboardHeader } from '../../components/Layout';
import { AdminLeftMenu } from '../../components/AdminLayout';
import {
  PersonalProfile,
  ProfessionalInformation,
  // FinancialInformation,
  AnnualIncome,
  RealDebtInformation,
  KnowlegeProfile,
  DigitalPlan,
  GoalsProfile,
  // TrustedContact,
  CoSubscriber,
  PracticesInformation,
  CharityInformation,
  ManageFees
} from '../../components/AdminUserProfile';
import { getUserData } from '../../redux/modules/admin';

class AdminUserProfile extends Component {
  constructor(props) {
    super(props);
    const pathArr = this.props.location.pathname.split('/');
    let userId;
    if (pathArr[3]) {
      userId = pathArr[3];
    }
    this.state = {
      showProfileModal: false,
      showProfessionalModal: false,
      showAnnualIncomeModal: false,
      showRealDebitModal: false,
      showKnowlegeModal: false,
      showPracticesModal: false,
      showGoalsModal: false,
      showPlanModal: false,
      userId
    };
    console.log('user profile', userId);
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getUserData(this.state.userId));
  }

  render() {
    const { userData, translation } = this.props;
    return (

      <AuthenticatedAdmin>
        <DashboardHeader />
        <AdminLeftMenu />
        <Block className="app-content">
          <Row>
            <Col className="text-right" md={12}>
              <Link to="/admin/manage-users"><Button className="tb-btn-com">Go Back</Button></Link>
            </Col>
          </Row>
          <br />
          <Row>
            <PersonalProfile showProfileModal={this.showProfileModal} />
            <Col sm={12} md={8}>
              <Row className="h-100">
                {/* <FinancialInformation /> */}
                <DigitalPlan showPlanModal={this.showPlanModal} />
                <ManageFees userId={this.state.userId} />


                {/* <TrustedContact /> */}

                <RealDebtInformation showRealDebitModal={this.showRealDebitModal} />
              </Row>
            </Col>
          </Row>
          <Row>
            <PracticesInformation showPracticesModal={this.showPracticesModal} />
          </Row>
          <Row>
            <CoSubscriber />
            <GoalsProfile showGoalsModal={this.showGoalsModal} />
          </Row>

          <Row>
            <ProfessionalInformation showProfessionalModal={this.showProfessionalModal} />
            <AnnualIncome showAnnualIncomeModal={this.showAnnualIncomeModal} />
          </Row>
          <Row>
            <KnowlegeProfile showKnowlegeModal={this.showKnowlegeModal} />
            <CharityInformation />

          </Row>

          <Row>
            <Col sm={12} md={12} lg={6} className="stretch-card">
              <Block className="tile">
                <Block className="d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.WEALTHSAVINGSPRODUCTS1}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.lifeinsurancepolicy}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isLifeInsurance || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.totalLifeinsurance}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.totalLifeInsurance || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.fortunesecurities}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.fortuneSecurities || 'N/A'}</span>
                    }
                  </p>
                </Block>
                <Block className="d-block financial-info">
                  {userData && userData.userinfo && userData.userinfo.wealth &&
                    JSON.parse(userData.userinfo.wealth).map(b => (
                      <Row>
                        <Col sm={3} md={3} lg={3}>
                          <div><Label><strong>{translation.InsuranceCompany}</strong></Label></div>
                          <div>{b.compnayLifeInsurance}</div>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <div><Label><strong>{translation.Amount}</strong></Label></div>
                          <div>{b.amountLifeInsurance}</div>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <div><Label><strong>{translation.Funds}</strong></Label></div>
                          <div>{b.fundsLifeInsurance}</div>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <div><Label><strong>{translation.OpeningYear}</strong></Label></div>
                          <div>{b.yearLifeInsurance}</div>
                        </Col>
                      </Row>
                    ))
                  }
                </Block>


                <Block className="d-flex justify-content-between align-items-center mt-4">
                  <h3 className="tile-title">{translation.WEALTHSAVINGSPRODUCTS2}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.havePEA}DDDD</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isPEA || 'N/A'}</span>
                    }
                  </p>
                </Block>
                <Block className="d-block financial-info">
                  {userData && userData.userinfo && userData.userinfo.PEA &&
                    JSON.parse(userData.userinfo.PEA).map(b => (
                      <Row>
                        <Col sm={6}>
                          <div><Label><strong>{translation.TotalPayment}</strong></Label></div>
                          <div>{b.totalPayment}</div>
                        </Col>
                        <Col sm={6}>
                          <div><Label><strong>{translation.OpeningYear}</strong></Label></div>
                          <div>{b.OpeningYear}</div>
                        </Col>
                      </Row>
                    ))
                  }
                </Block>

                <Block className="d-flex justify-content-between align-items-center mt-4">
                  <h3 className="tile-title">{translation.SAVINGPRODUCTOTHER}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.EquityAmount}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.equityAmountPEA || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.ShorttermAmount}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.shortTermPEA || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Cashcome}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.cashToComePEA || 'N/A'}</span>
                    }
                  </p>
                </Block>

              </Block>

            </Col>

            <Col sm={12} md={12} lg={6} className="stretch-card">
              <Block className="tile">
                <Block className="d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.POLITICALMANDATE} </h3>
                </Block>

                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Doesanunderwriter}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isPoliticalMandate || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.FirstName}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalName || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.LastName}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalFirstName || 'N/A'}</span>
                    }
                  </p>

                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Nationality}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalNationality || 'N/A'}</span>
                    }
                  </p>

                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Birthdate}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalDateOfBirth || 'N/A'}</span>
                    }
                  </p>

                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Typeofmandate}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalMandateType || 'N/A'}</span>
                    }
                  </p>

                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Relationship}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalRelationship || 'N/A'}</span>
                    }
                  </p>

                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Countryconcerned}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.politicalCountryConcerned || 'N/A'}</span>
                    }
                  </p>

                </Block>

              </Block>
            </Col>

          </Row>

          <Row>
            <Col sm={12} md={12} lg={6} className="stretch-card">
              <Block className="tile">
                <Block className="d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.TAXATIONPLACE}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Countryoftaxresidence}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.countryOfTax || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Taxidentificationnumber}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.taxtIdNumber || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.DoyouhaveanUSgreencard}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isUSGreenCard || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.IsthesubscriberUSPersonorUStaxresident}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isUSPersonOrTaxRes || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.IsthepersonpayingthepremiumsMoreInformation}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isPayingPremiumUS || 'N/A'}</span>
                    }
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.IsoneofthenamedbeneficiariesUStaxresident}</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.isBeneficiariesUS || 'N/A'}</span>
                    }
                  </p>

                </Block>
              </Block>
            </Col>

            <Col sm={12} md={12} lg={6} className="stretch-card">
              <Block className="tile">
                <Block className="d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.BENEFICIARIES}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.Willthebeneficiaryincaseofdeathbe}?</Label>
                    {userData && userData.userinfo &&
                      <span>{userData.userinfo.beneficiary || 'N/A'}</span>
                    }
                  </p>
                </Block>
                {userData && userData.userinfo && userData.userinfo.beneficiaries &&
                  JSON.parse(userData.userinfo.beneficiaries).map(b => (
                    <Row>
                      <Col sm={3} md={3} lg={3}>
                        <div><Label><strong>{translation.FirstName}</strong></Label></div>
                        <div>{b.firstName}</div>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <div><Label><strong>{translation.LastName}</strong></Label></div>
                        <div>{b.lastName}</div>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <div><Label><strong>{translation.DateofBirth}</strong></Label></div>
                        <div>{b.dateOfBirth}</div>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <div><Label><strong>{translation.Percentage}</strong></Label></div>
                        <div>{b.percentage}</div>
                      </Col>
                    </Row>
                  ))
                }
              </Block>
            </Col>

          </Row>

          <Row>
            <Col sm={12} md={12} lg={6} className="stretch-card">
              <Block className="tile">
                <Block className="d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.YOURDOCUMENTS}</h3>
                </Block>
                <Block className="d-block financial-info">
                  <p className="d-flex justify-content-between align-items-center">
                    <Label>{translation.iagreeSignUp}</Label>
                    {userData && userData.userinfo &&
                      <span>{(userData.userinfo.iAgree === '1') ? 'True' : false || 'N/A'}</span>
                    }
                  </p>
                  {userData && userData.userinfo && userData.userinfo.attachment &&
                    <p className="d-flex justify-content-between align-items-center">
                      <Label>Document Télécharge</Label>
                      <a target="_blank" href={`${config.apiUrl}/download/attachments/${userData.userinfo.attachment}`}>{userData.userinfo.attachment}</a>
                    </p>
                  }
                </Block>
              </Block>
            </Col>

            {userData && userData.selectfundmaster &&
              <Col sm={12} md={12} lg={6} className="stretch-card">
                <Block className="tile">
                  <Block className="d-flex justify-content-between align-items-center">
                    <h3 className="tile-title">{translation.SELECTFUNDS}</h3>
                  </Block>
                  <Block className="d-block financial-info">

                    {userData.selectfundmaster.map((fund, index) => (
                      <p className="d-flex justify-content-between align-items-center">
                        <Label>{fund.name}<br />{fund.ISIN}</Label>
                        <span>{fund.select_funds?.weight}</span>
                      </p>
                    ))}
                  </Block>
                </Block>
              </Col>
            }
          </Row>


        </Block>
      </AuthenticatedAdmin>
    );
  }
}


export default connect(
  state => ({
    me: state.auth.user,
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(AdminUserProfile);
