import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import createStore from './redux/configureStore';


// Create a history of your choosing (we're using a browser history in this case)
// const history = createHistory();
// ========================================================
// Browser History Setup
// ========================================================
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});
const store = createStore(history, {});

ReactDOM.render(
  <Provider store={store} >
    <HttpsRedirect>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </HttpsRedirect>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
