import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Table } from 'react-bootstrap';
import { Block } from './../../components';
import {
  getPortfolioWorldRegionDetails
} from '../../redux/modules/fund';

class Breakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      headData: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioWorldRegionDetails(portfolioId));
      this.updateData();
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioWorldRegionDetails(nextProps.portfolioId));
      this.updateData();
    }
  }

  updateData = () => {
    const { portfolioWorldRegionDetails } = this.props;
    const headData = [];
    let data;
    if (portfolioWorldRegionDetails) {
      portfolioWorldRegionDetails.map((item) => {
        for (const key in item) {
          if (!headData.includes(key) && key !== 'fundId' && key !== 'fundDetail' && key !== 'other') {
            headData.push(key);
          }
        }
        return true;
      });
      data = portfolioWorldRegionDetails.map((item) => {
        const r = {
          holding: item.fundDetail.name
        };
        headData.map((c) => {
          r[c] = item[c] || undefined;
          return true;
        });
        r.other = item.other;
        return (r);
      });
    }

    data = _.sortBy(data, (o) => {
      return (o.other) ? o.other : '';
    });
    data = data.reverse();

    this.setState({
      data,
      headData
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      data,
      headData,
      column,
      direction
    } = this.state;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        const tdData = headData.map(c => (
          <td key={c}>{item[c] ? `${item[c]}%` : '-'}</td>
        ));
        return (
          <tr key={item.holding}>
            <td>{item.holding}</td>
            {tdData}
            <td>{item.other ? `${item.other}%` : '-'}</td>
          </tr>
        );
      });
    }
    let thData;
    if (headData) {
      thData = headData.map(c => (
        <th
          className={column === c ? `sorting ${direction}` : 'sorting'}
          onClick={this.handleSort(c)}
          key={c}
        >
          {c}
        </th>
      ));
    }
    return (
      <Row>
        <Col sm={12} md={12}>
          <Block className="d-block mt-5">
            <h2 className="heading-2">
              <u>Breakdown per holding (%)</u>
            </h2>
            <Block className="table-responsive custom-table table-heading-bg">
              <Table className="table">
                <thead>
                  <tr>
                    <th
                      className={column === 'holding' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('holding')}
                    >
                      Holding
                    </th>
                    {thData}
                    <th
                      className={column === 'other' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('other')}
                    >
                      Other
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData}
                </tbody>
              </Table>
            </Block>
          </Block>
        </Col>
      </Row>
    );
  }
}

Breakdown.propTypes = {
  dispatch: PropTypes.func,
  portfolioWorldRegionDetails: PropTypes.any,
  portfolioId: PropTypes.any
};

Breakdown.defaultProps = {
  dispatch: null,
  portfolioWorldRegionDetails: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    portfolioWorldRegionDetails: state.fund.portfolioWorldRegionDetails,
  }))(Breakdown);

