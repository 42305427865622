import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import DropDown from '../../formInputs/DropDown';
import TextBox from '../../formInputs/TextBox';
// import TextArea from '../../formInputs/TextArea';
import { Block } from './../../components';

class RealEstateStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    return (
      <Form className="annualIncome-information-form" onSubmit={handleSubmit}>
        <h4>{translation.WEALTHREALESTATEASSETS}</h4>
        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.Realestatevaluation} </ControlLabel>
              <Field
                name="realEstateValuation"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="valuation"
                component={DropDown}                 
                options={[
                  { key: 'one', text: `${translation.Between} 100k€ et 250k€`, value: `${translation.Between} 100k€ et 250k€` },
                  { key: 'two', text: `${translation.Between} 250k€ et 500k€`, value: `${translation.Between} 250k€ et 500k€` },
                  { key: 'three', text: `${translation.Between} 500k€ et 750k€`, value: `${translation.Between} 500k€ et 750k€` },
                  { key: 'four', text: `${translation.Between} 750k€ - 1,2Mk€`, value: `${translation.Between} 750k€ - 1,2Mk€` },
                  { key: 'five', text: `${translation.GreaterThan} 1,2Mk€`, value: `${translation.GreaterThan} 1,2Mk€` }
                ]}
              />
            </Block>
          </Col>
        </Row>

        <h4>{translation.Otherassets}</h4>

        <Row>
          <Col xs={12} md={6}>
            <Field
              name="otherAssetsNature"
              component={TextBox}
              label={translation.Otherassetsnature}
              placeholder=""

            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="otherAssetsValuation"
              label={translation.assetstotalvaluation}
              search
              fluid
              multiple={false}
              selection
              selectOnBlur={false}
              noResultsMessage="valuation"
              component={DropDown}                
              options={[
                { key: 'one', text: `${translation.Between} 100k€ et 250k€`, value: `${translation.Between} 100k€ et 250k€` },
                { key: 'two', text: `${translation.Between} 250k€ et 500k€`, value: `${translation.Between} 250k€ et 500k€` },
                { key: 'three', text: `${translation.Between} 500k€ et 750k€`, value: `${translation.Between} 500k€ et 750k€` },
                { key: 'four', text: `${translation.Between} 750k€ - 1,2Mk€`, value: `${translation.Between} 750k€ - 1,2Mk€` },
                { key: 'five', text: `${translation.GreaterThan} 1,2Mk€`, value: `${translation.GreaterThan} 1,2Mk€` }
              ]}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} md={12}>
            <Field
              name="economicOrigin"
              component={TextArea}
              label="Describe the economic origin of the heritage (inheritance and name of the deceased, activity ...)"
              placeholder=""
            />
          </Col>
        </Row> */}

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

RealEstateStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired  
};

export default connect(
  state => ({
    translation: state.translation.keys
    // initialValues: state.companies.resendInviteItem,
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(RealEstateStep));
