import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactChartkick, { ColumnChart } from 'react-chartkick';
import Highcharts from 'highcharts';

ReactChartkick.addAdapter(Highcharts);

export default class Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, colors, suffix } = this.props;

    return (
      <ColumnChart suffix={suffix} colors={colors} data={data} />
    );
  }
}

Bar.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array,
  suffix: PropTypes.string,
};

Bar.defaultProps = {
  colors: ['#029ae5', '#42bd40'],
  data: [
    {
      name: 'Deposits',
      data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
    },
    {
      name: 'Withdrawls',
      data: [[0, 2], [1, 0], [2, 6], [3, 3], [4, 5]]
    }
  ],
  suffix: ''
};
