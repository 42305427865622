import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import TextBox from '../../formInputs/TextBox';
import RadioGroup from '../../formInputs/RadioGroup';
import DropDown from '../../formInputs/DropDown';
import { Block } from './../../components';

class ProfessionalInformationStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;

    return (
      <Form className="professional-information-form" onSubmit={handleSubmit}>

        <h4>{translation.PROFESSIONALINFORMATIONS}</h4>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.Areyouretired}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isRetired"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group">
              <ControlLabel>{translation.Professionalactivityformerlyexercised}</ControlLabel>
              <Field
                name="professionalActivity"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.ProfessionalActivity}
                component={DropDown}
                options={[
                  { key: 'Liberal', text: `${translation.Liberal}`, value: 'Liberal / independent / trader profession' },
                  { key: 'Official', text: `${translation.Official}`, value: 'Official' },
                  { key: 'Frame', text: `${translation.Frame}`, value: 'Frame' },
                  { key: 'Employee', text: `${translation.Employee}`, value: 'Employee' },
                  { key: 'Business Manager (s)', text: `${translation.BusinessManager}`, value: 'Business Manager (s)' },
                  { key: 'No-occupation', text: `${translation.Nooccupation}`, value: 'No occupation' },
                  { key: 'Other', text: `${translation.Other}`, value: 'Other' }
                ]}
              />
            </Block>
          </Col>
        </Row>

        {formValues.isRetired === 'no' &&
          <React.Fragment>
            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group">
                  <ControlLabel>{translation.Descriptionoftheprofession}</ControlLabel>
                  <Field
                    name="professionDescription"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage={translation.ProfessionDescription}
                    component={DropDown}
                    options={[
                      { key: 'Liberal', text: `${translation.JudiciaryAdministrator}`, value: 'Judiciary Administrator' },
                      { key: 'Official', text: `${translation.GeneralInsuranceAgent}`, value: 'General Insurance Agent' },
                      { key: 'Frame', text: `${translation.Architect}`, value: 'Architect' },
                      { key: 'Employee', text: `${translation.AttorneyattheCouncilofState}`, value: 'Attorney at the Council of State and at the Court of Cassation' },
                      { key: 'Worker', text: `${translation.Lawyer}`, value: 'Lawyer' },
                      { key: 'Business Manager (s)', text: `${translation.Admittedcourtsappeal}`, value: 'Admitted to the courts of appeal' },
                      { key: 'No-occupation', text: `${translation.Chiropractor}`, value: 'Chiropractor' },
                      { key: '1', text: `${translation.Dentist}`, value: 'Dentist' },
                      { key: '2', text: `${translation.Auditor}`, value: 'Auditor' },
                      { key: '3', text: `${translation.Auctioneer}`, value: 'Auctioneer' },
                      { key: '4', text: `${translation.Financialinvestmentadvice}`, value: 'Financial investment advice' },
                      { key: '5', text: `${translation.IndustrialPropertyAttorney}`, value: 'Industrial Property Attorney' },
                      { key: '6', text: `${translation.Coordinatorworks}`, value: 'Coordinator of works' },
                      { key: '7', text: `${translation.Copyist}`, value: 'Copyist ' },
                      { key: '8', text: `${translation.Readercorrector}`, value: 'Reader corrector' },
                      { key: '9', text: `${translation.LocalPressCorrespondent}`, value: 'Local Press Correspondent' },
                      { key: '10', text: `${translation.Racingdriver}`, value: 'Racing driver' },
                      { key: '11', text: `${translation.Creatorwebsites}`, value: 'Creator of websites' },
                      { key: '12', text: `${translation.Industrialdesigner}`, value: 'Industrial designer' },
                      { key: '13', text: `${translation.Decoratorconsultant}`, value: 'Decorator consultant (not registered in the trade directory)' },
                      { key: '14', text: `${translation.Designate}`, value: 'Designate' },
                      { key: '15', text: `${translation.Designer}`, value: 'Designer' },
                      { key: '16', text: `${translation.Detective}`, value: 'Detective' },
                      { key: '17', text: `${translation.SoftwareDeveloper}`, value: 'Software Developer' },
                      { key: '18', text: `${translation.Dietitian}`, value: 'Dietitian ' },
                      { key: '19', text: `${translation.Directorofmedicalanalysislaboratory}`, value: 'Director of medical analysis laboratory' },
                      { key: '20', text: `${translation.librarian}`, value: 'librarian' },
                      { key: '21', text: 'of the Social Security Code) ', value: 'of the Social Security Code) ' },
                      { key: '22', text: `${translation.ConstructionEconomist}`, value: 'Construction Economist' },
                      { key: '23', text: `${translation.EconomistBoard}`, value: 'Economist Board' },
                      { key: '24', text: `${translation.PublicWriter}`, value: 'Public Writer' },
                      { key: '25', text: `${translation.Sportstrainer}`, value: 'Sports trainer' },
                      { key: '26', text: `${translation.Investigator}`, value: 'Investigator' },
                      { key: '27', text: `${translation.Teacher}`, value: 'Teacher' },
                      { key: '28', text: `${translation.Ergonomist}`, value: 'Ergonomist' },
                      { key: '29', text: `${translation.Occupationaltherapist}`, value: ' Occupational therapist' },
                      { key: '30', text: `${translation.Studiesetc}`, value: 'Studies (environment, markets, statistics, techniques, etc.)' },
                      { key: '31', text: `${translation.Expertetc}`, value: 'Expert (automobile, insurance, building, marine, etc.) ' },
                      { key: '32', text: `${translation.Agriculturalexpert}`, value: 'Agricultural expert, landowner and forestry expert' },
                      { key: '33', text: `${translation.PatentOperatorrademark}`, value: 'Patent Operator, License or Trademark' },
                      { key: '34', text: `${translation.Former}`, value: 'Former' },
                      { key: '35', text: `${translation.Genealogist}`, value: 'Genealogist' },
                      { key: '36', text: `${translation.Geographer}`, value: 'Geographer ' },
                      { key: '37', text: `${translation.Geologist}`, value: 'Geologist ' },
                      { key: '38', text: `${translation.Expertsurveyor}`, value: 'Expert surveyor' },
                      { key: '39', text: `${translation.Geophysicist}`, value: 'Geophysicist' },
                      { key: '40', text: `${translation.Graphicartist}`, value: 'Graphic artist (not creator of original work)' },
                      { key: '41', text: `${translation.Graphologist}`, value: 'Graphologist' },
                      { key: '42', text: `${translation.Clerkcommercialcourts}`, value: 'Clerk at the commercial courts' },
                      { key: '43', text: `${translation.Conferralguide}`, value: 'Conferral guide' },
                      { key: '44', text: `${translation.Highmountainguide}`, value: 'High mountain guide' },
                      { key: '45', text: `${translation.InterludeGuide}`, value: 'Interlude Guide' },
                      { key: 'Historian', text: `${translation.Historian}`, value: ' Historian ' },
                      { key: 'Bailiff', text: `${translation.Bailiff}`, value: 'Bailiff ' },
                      { key: 'Hydrogeologist', text: `${translation.Hydrogeologist}`, value: 'Hydrogeologist' },
                      { key: 'Iconographer', text: `${translation.Iconographer}`, value: 'Iconographer' },
                      { key: 'Illustrator', text: `${translation.Illustrator}`, value: 'Illustrator' },
                      { key: 'Liberal nurse ', text: `${translation.Liberalnurse}`, value: 'Liberal nurse' },
                      { key: 'Graphic Designer', text: `${translation.GraphicDesigner}`, value: 'Graphic Designer' },
                      { key: 'Computerscientist ', text: `${translation.ComputerScientist}`, value: 'Computer Scientist' },
                      { key: 'Consulting engineer', text: `${translation.Consultingengineer}`, value: 'Consulting engineer ' },
                      { key: 'Business engineer', text: `${translation.Businessengineer}`, value: 'Business engineer' },
                      { key: 'Sound engineer', text: `${translation.Soundengineer}`, value: 'Sound engineer' },
                      { key: 'Interpreter (speaker, guide, translator) ', text: `${translation.Interpreter}`, value: 'Interpreter (speaker, guide, translator)' },
                      { key: 'Accountant', text: `${translation.Professionalplayer}`, value: 'Professional player (individual sport, bridge, etc.)' },
                      { key: 'Reader  ', text: `${translation.Reader}`, value: 'Reader ' },
                      { key: 'Project manager ', text: `${translation.Projectmanager}`, value: 'Project manager' },
                      { key: 'Legalrepresentative ', text: `${translation.Legalrepresentative}`, value: 'Legal representative' },
                      { key: 'Physiotherapist  ', text: `${translation.Physiotherapist}`, value: 'Physiotherapist' },
                      { key: 'Doctor', text: `${translation.Doctor}`, value: 'Doctor ' },
                      { key: 'Criminal', text: `${translation.CriminalOmbudsman}`, value: 'Criminal Ombudsman (in case of non-occasional activity)' },
                      { key: 'Medievalist', text: `${translation.Medievalist}`, value: 'Medievalist ' },
                      { key: 'Quantity surveyor', text: `${translation.Quantitysurveyor}`, value: 'Quantity surveyor' },
                      { key: 'Measurer in painting', text: `${translation.Measurerpainting}`, value: ' Measurer in painting ' },
                      { key: 'Sports Monitor ', text: `${translation.SportsMonitor}`, value: 'Sports Monitor' },
                      { key: 'Crosswords (author) ', text: `${translation.Crosswords}`, value: 'Crosswords (author)' },
                      { key: 'Music therapist ', text: `${translation.Musictherapist}`, value: 'Music therapist' },
                      { key: 'Naturalist ', text: `${translation.Naturalist}`, value: 'Naturalist ' },
                      { key: 'Naturopath', text: `${translation.Naturopath}`, value: 'Naturopath' },
                      { key: 'Notary', text: `${translation.Notary}`, value: 'Notary ' },
                      { key: 'Rater', text: `${translation.Rater}`, value: 'Rater' },
                      { key: 'Oceanographer', text: `${translation.Oceanographer}`, value: 'Oceanographer ' },
                      { key: 'Winemaker', text: `${translation.Winemaker}`, value: 'Winemaker ' },
                      { key: 'Fair organizer', text: `${translation.Fairorganizer}`, value: 'Fair organizer' },
                      { key: 'Orientating', text: `${translation.Orientating}`, value: 'Orientating' },
                      { key: 'Speech Therapist', text: `${translation.SpeechTherapist}`, value: 'Speech Therapist' },
                      { key: 'Orthoptist', text: `${translation.Orthoptist}`, value: 'Orthoptist' },
                      { key: 'Osteopath', text: `${translation.Osteopath}`, value: 'Osteopath' },
                      { key: 'Landscaper', text: `${translation.Landscaper}`, value: 'Landscaper (activity in design office,' },
                      { key: 'Chiropodist', text: `${translation.Chiropodist}`, value: 'Chiropodist ' },
                      { key: 'Pilot', text: `${translation.Pilot}`, value: 'Pilot ' },
                      { key: 'Consulting plasticien  ', text: `${translation.Consultingplasticien}`, value: 'Consulting plasticien' },
                      { key: 'Home fitness trainer  ', text: `${translation.Homefitnesstrainer}`, value: 'Home fitness trainer' },
                      { key: 'Teacher (music, sports, languages, etc.)', text: `${translation.Teacheretc}`, value: 'Teacher (music, sports, languages, etc.) ' },
                      { key: 'Programmer  ', text: `${translation.Programmer}`, value: 'Programmer' },
                      { key: 'Psychoanalyst  ', text: `${translation.Psychoanalyst}`, value: 'Psychoanalyst' },
                      { key: 'Psychologist   ', text: `${translation.Psychologist}`, value: 'Psychologist ' },
                      { key: 'Psychomotricien', text: `${translation.Psychomotricien}`, value: 'Psychomotricien' },
                      { key: 'Psychosociologist   ', text: `${translation.Psychosociologist}`, value: 'Psychosociologist' },
                      { key: 'Psychotherapist', text: `${translation.Psychotherapist}`, value: 'Psychotherapist' },
                      { key: 'Editor', text: `${translation.Editor}`, value: 'Editor' },
                      { key: 'Documentary writer', text: `${translation.Documentarywriter}`, value: 'Documentary writer' },
                      { key: 'Scientific writer', text: `${translation.Scientificwriter}`, value: 'Scientific writer' },
                      { key: 'Press relations', text: `${translation.Pressrelations}`, value: 'Press relations' },
                      { key: 'Public relations', text: `${translation.Publicrelations}`, value: 'Public relations' },
                      { key: 'Repeater', text: `${translation.Repeater}`, value: 'Repeater ' },
                      { key: 'Midwife ', text: `${translation.Midwife}`, value: 'Midwife ' },
                      { key: 'unrelated to the cycle of crop production)', text: `${translation.unrelatedcyclecropproduction}`, value: 'unrelated to the cycle of crop production)' },
                      { key: 'Sapiteur (expert)', text: `${translation.Sapiteur}`, value: 'Sapiteur (expert)' },
                      { key: 'Sociologist ', text: `${translation.Sociologist}`, value: 'Sociologist ' },
                      { key: 'Speaker ', text: `${translation.Speaker}`, value: 'Speaker ' },
                      { key: 'Speleologist ', text: `${translation.Speleologist}`, value: 'Speleologist ' },
                      { key: 'Professional athlete', text: `${translation.Professionalathlete}`, value: 'Professional athlete' },
                      { key: 'Statistician ', text: `${translation.Statistician}`, value: 'Statistician ' },
                      { key: 'Conference stenotypist', text: `${translation.Conferencestenotypist}`, value: 'Conference stenotypist' },
                      { key: 'Stylist', text: `${translation.Stylist}`, value: 'Stylist ' },
                      { key: 'Consulting Technician', text: `${translation.ConsultingTechnician}`, value: 'Consulting Technician' },
                      { key: 'Thermicien ', text: `${translation.Thermicien}`, value: 'Thermicien' },
                      { key: 'Topographer ', text: `${translation.Topographer}`, value: 'Topographer ' },
                      { key: 'Translator ', text: `${translation.Translator}`, value: 'Translator ' },
                      { key: 'Transcriber ', text: `${translation.Transcriber}`, value: 'Transcriber ' },
                      { key: 'Town Planner', text: `${translation.TownPlanner}`, value: 'Town Planner' },
                      { key: 'Urban planner-developer', text: `${translation.Urbanplanner}`, value: 'Urban planner-developer' },
                      { key: 'Planning consultant', text: `${translation.Planningconsultant}`, value: 'Planning consultant' },
                      { key: 'Checker', text: `${translation.Checker}`, value: 'Checker' },
                      { key: 'Auditor', text: `${translation.Auditorhistoricalmonuments}`, value: 'Auditor of historical monuments' },
                      { key: 'Veterinary', text: `${translation.Veterinary}`, value: 'Veterinary ' },
                    ]}
                  />
                </Block>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Block className="form-group">
                  <ControlLabel>{translation.Exerciseprofessionperson}dddd</ControlLabel>
                  <Field
                    name="professionExercise"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage={translation.ProfessionPerson}
                    component={DropDown}
                    options={[
                      { key: 'Physical', text: `${translation.Physical}`, value: 'Physical' },
                      { key: 'Owner', text: `${translation.Owner}`, value: 'Owner' },
                      { key: 'Morals', text: `${translation.Morals}`, value: 'Morals' }
                    ]}
                  />
                </Block>
              </Col>
              {formValues && formValues.professionExercise !== 'Physical' &&
                <Col xs={12} md={6}>
                  <Field
                    name="companyName"
                    component={TextBox}
                    label={translation.Nameofcompany}
                    placeholder=""

                  />
                </Col>
              }
              {formValues && formValues.professionExercise === 'Physical' &&
                <Col xs={12} md={4}>
                  <Field
                    name="employerName"
                    component={TextBox}
                    label={translation.NameofEmployer}
                    placeholder=""
                  />
                </Col>
              }
            </Row>

            <Row>
              <Col xs={12} md={4}>
                <Block className="form-group">
                  <ControlLabel>{translation.Activityarea}</ControlLabel>
                  <Field
                    name="activityArea"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage="Activity area"
                    component={DropDown}
                    options={[
                      { key: '1', text: `${translation.select}`, value: '' },
                      { key: '2', text: `${translation.Accounting}`, value: 'Accounting' },
                      { key: '3', text: `${translation.AirlinesAviation}`, value: 'Airlines/Aviation' },
                      { key: '4', text: `${translation.AlternativeDisputeResolution}`, value: 'Alternative Dispute Resolution' },
                      { key: '5', text: `${translation.AlternativeMedicine}`, value: 'Alternative Medicine' },
                      { key: '6', text: `${translation.Animation}`, value: 'Animation' },
                      { key: '7', text: `${translation.ApparelFashion}`, value: 'Apparel & Fashion' },
                      { key: '8', text: `${translation.ArchitecturePlanning}`, value: 'Architecture & Planning' },
                      { key: '9', text: `${translation.ArtsandCrafts}`, value: 'Arts and Crafts' },
                      { key: '10', text: `${translation.Automotive}`, value: 'Automotive' },
                      { key: '11', text: `${translation.AviationAerospace}`, value: 'Aviation & Aerospace' },
                      { key: '12', text: `${translation.Banking}`, value: 'Banking' },
                      { key: '13', text: `${translation.Biotechnology}`, value: 'Biotechnology' },
                      { key: '14', text: `${translation.BroadcastMedia}`, value: 'Broadcast Media' },
                      { key: '15', text: `${translation.BuildingMaterials}`, value: 'Building Materials' },
                      { key: '16', text: `${translation.BusinessSuppliesandEquipment}`, value: 'Business Supplies and Equipment' },
                      { key: '17', text: `${translation.CapitalMarkets}`, value: 'Capital Markets' },
                      { key: '18', text: `${translation.Chemicals}`, value: 'Chemicals' },
                      { key: '19', text: `${translation.CivicSocialOrganization}`, value: 'Civic & Social Organization' },
                      { key: '20', text: `${translation.CivilEngineering}`, value: 'Civil Engineering' },
                      { key: '21', text: `${translation.CommercialRealEstate}`, value: 'Commercial Real Estate' },
                      { key: '22', text: `${translation.ComputerNetworkSecurity}`, value: 'Computer & Network Security' },
                      { key: '23', text: `${translation.ComputerGames}`, value: 'Computer Games' },
                      { key: '24', text: `${translation.ComputerHardware}`, value: 'Computer Hardware' },
                      { key: '25', text: `${translation.ComputerNetworking}`, value: 'Computer Networking' },
                      { key: '26', text: `${translation.ComputerSoftware}`, value: 'Computer Software' },
                      { key: '27', text: `${translation.Construction}`, value: 'Construction' },
                      { key: '28', text: `${translation.ConsumerElectronics}`, value: 'Consumer Electronics' },
                      { key: '29', text: `${translation.ConsumerGoods}`, value: 'Consumer Goods' },
                      { key: '30', text: `${translation.ConsumerServices}`, value: 'Consumer Services' },
                      { key: '31', text: `${translation.Cosmetics}`, value: 'Cosmetics' },
                      { key: '32', text: `${translation.Dairy}`, value: 'Dairy' },
                      { key: '33', text: `${translation.DefenseSpace}`, value: 'Defense & Space' },
                      { key: '34', text: `${translation.Design}`, value: 'Design' },
                      { key: '35', text: `${translation.EducationManagement}`, value: 'Education Management' },
                      { key: '36', text: `${translation.ELearning}`, value: 'E-Learning' },
                      { key: '37', text: `${translation.ElectricalManufacturing}`, value: 'Electrical/Electronic Manufacturing' },
                      { key: '38', text: `${translation.Entertainment}`, value: 'Entertainment' },
                      { key: '39', text: `${translation.EnvironmentalServices}`, value: 'Environmental Services' },
                      { key: '40', text: `${translation.EventsServices}`, value: 'Events Services' },
                      { key: '41', text: `${translation.ExecutiveOffice}`, value: 'Executive Office' },
                      { key: '42', text: `${translation.FacilitiesServices}`, value: 'Facilities Services' },
                      { key: '43', text: `${translation.Farming}`, value: 'Farming' },
                      { key: '44', text: `${translation.FinancialServices}`, value: 'Financial Services' },
                      { key: '45', text: `${translation.FineArt}`, value: 'Fine Art' },
                      { key: '46', text: `${translation.Fishery}`, value: 'Fishery' },
                      { key: '47', text: `${translation.FoodBeverages}`, value: 'Food & Beverages' },
                      { key: '48', text: `${translation.FoodProduction}`, value: 'Food Production' },
                      { key: '49', text: `${translation.FundRaising}`, value: 'Fund-Raising' },
                      { key: '50', text: `${translation.Furniture}`, value: 'Furniture' },
                      { key: '51', text: `${translation.GamblingCasinos}`, value: 'Gambling & Casinos' },
                      { key: '52', text: `${translation.GlassCeramicsConcrete}`, value: 'Glass, Ceramics & Concrete' },
                      { key: '53', text: `${translation.GovernmentAdministration}`, value: 'Government Administration' },
                      { key: '54', text: `${translation.GovernmentRelations}`, value: 'Government Relations' },
                      { key: '55', text: `${translation.GraphicDesign}`, value: 'Graphic Design' },
                      { key: '56', text: `${translation.HealthWellnessFitness}`, value: 'Health, Wellness and Fitness' },
                      { key: '57', text: `${translation.HigherEducation}`, value: 'Higher Education' },
                      { key: '58', text: `${translation.HospitalHealthCare}`, value: 'Hospital & Health Care' },
                      { key: '59', text: `${translation.Hospitality}`, value: 'Hospitality' },
                      { key: '60', text: `${translation.HumanResources}`, value: 'Human Resources' },
                      { key: '61', text: `${translation.ImportExport}`, value: 'Import and Export' },
                      { key: '62', text: `${translation.IndividualFamilyServices}`, value: 'Individual & Family Services' },
                      { key: '63', text: `${translation.IndustrialAutomation}`, value: 'Industrial Automation' },
                      { key: '64', text: `${translation.InformationServices}`, value: 'Information Services' },
                      { key: '65', text: `${translation.InformationTechnologyServices}`, value: 'Information Technology and Services' },
                      { key: '66', text: `${translation.Insurance}`, value: 'Insurance' },
                      { key: '67', text: `${translation.InternationalAffairs}`, value: 'International Affairs' },
                      { key: '68', text: `${translation.InternationalTradeDevelopment}`, value: 'International Trade and Development' },
                      { key: '69', text: `${translation.Internet}`, value: 'Internet' },
                      { key: '70', text: `${translation.InvestmentBanking}`, value: 'Investment Banking' },
                      { key: '71', text: `${translation.InvestmentManagement}`, value: 'Investment Management' },
                      { key: '72', text: `${translation.Judiciary}`, value: 'Judiciary' },
                      { key: '73', text: `${translation.LawEnforcement}`, value: 'Law Enforcement' },
                      { key: '74', text: `${translation.LawPractice}`, value: 'Law Practice' },
                      { key: '75', text: `${translation.LegalServices}`, value: 'Legal Services' },
                      { key: '76', text: `${translation.LegislativeOffice}`, value: 'Legislative Office' },
                      { key: '77', text: `${translation.LeisureTravelTourism}`, value: 'Leisure, Travel & Tourism' },
                      { key: '78', text: `${translation.Libraries}`, value: 'Libraries' },
                      { key: '79', text: `${translation.LogisticsandSupplyChain}`, value: 'Logistics and Supply Chain' },
                      { key: '80', text: `${translation.LuxuryGoodsJewelry}`, value: 'Luxury Goods & Jewelry' },
                      { key: '81', text: `${translation.Machinery}`, value: 'Machinery' },
                      { key: '82', text: `${translation.ManagementConsulting}`, value: 'Management Consulting' },
                      { key: '83', text: `${translation.Maritime}`, value: 'Maritime' },
                      { key: '84', text: `${translation.MarketingandAdvertising}`, value: 'Marketing and Advertising' },
                      { key: '85', text: `${translation.MarketResearch}`, value: 'Market Research' },
                      { key: '86', text: `${translation.MechanicalIndustrialEngineering}`, value: 'Mechanical or Industrial Engineering' },
                      { key: '87', text: `${translation.MediaProduction}`, value: 'Media Production' },
                      { key: '88', text: `${translation.MedicalDevices}`, value: 'Medical Devices' },
                      { key: '89', text: `${translation.MedicalPractice}`, value: 'Medical Practice' },
                      { key: '90', text: `${translation.MentalHealthCare}`, value: 'Mental Health Care' },
                      { key: '91', text: `${translation.Military}`, value: 'Military' },
                      { key: '92', text: `${translation.MiningMetals}`, value: 'Mining & Metals' },
                      { key: '93', text: `${translation.MotionPicturesandFilm}`, value: 'Motion Pictures and Film' },
                      { key: '94', text: `${translation.MuseumsandInstitutions}`, value: 'Museums and Institutions' },
                      { key: '95', text: `${translation.Music}`, value: 'Music' },
                      { key: '96', text: `${translation.Nanotechnology}`, value: 'Nanotechnology' },
                      { key: '97', text: `${translation.Newspapers}`, value: 'Newspapers' },
                      { key: '98', text: `${translation.NonprofitOrganizationManagement}`, value: 'Nonprofit Organization Management' },
                      { key: '99', text: `${translation.OilEnergy}`, value: 'Oil & Energy' },
                      { key: '100', text: `${translation.OnlineMedia}`, value: 'Online Media' },
                      { key: '101', text: `${translation.OutsourcingOffshoring}`, value: 'Outsourcing/Offshoring' },
                      { key: '102', text: `${translation.PackageFreightDelivery}`, value: 'Package/Freight Delivery' },
                      { key: '103', text: `${translation.PackagingContainers}`, value: 'Packaging and Containers' },
                      { key: '104', text: `${translation.PaperForestProducts}`, value: 'Paper & Forest Products' },
                      { key: '105', text: `${translation.PerformingArts}`, value: 'Performing Arts' },
                      { key: '106', text: `${translation.Pharmaceuticals}`, value: 'Pharmaceuticals' },
                      { key: '107', text: `${translation.Philanthropy}`, value: 'Philanthropy' },
                      { key: '108', text: `${translation.Photography}`, value: 'Photography' },
                      { key: '109', text: `${translation.Plastics}`, value: 'Plastics' },
                      { key: '110', text: `${translation.PoliticalOrganization}`, value: 'Political Organization' },
                      { key: '111', text: `${translation.PrimarySecondaryEducation}`, value: 'Primary/Secondary Education' },
                      { key: '112', text: `${translation.Printing}`, value: 'Printing' },
                      { key: '113', text: `${translation.ProfessionalTrainingCoaching}`, value: 'Professional Training & Coaching' },
                      { key: '114', text: `${translation.ProgramDevelopment}`, value: 'Program Development' },
                      { key: '115', text: `${translation.PublicPolicy}`, value: 'Public Policy' },
                      { key: '116', text: `${translation.PublicRelationsCommunications}`, value: 'Public Relations and Communications' },
                      { key: '117', text: `${translation.PublicSafety}`, value: 'Public Safety' },
                      { key: '118', text: `${translation.Publishing}`, value: 'Publishing' },
                      { key: '119', text: `${translation.RailroadManufacture}`, value: 'Railroad Manufacture' },
                      { key: '120', text: `${translation.Ranching}`, value: 'Ranching' },
                      { key: '121', text: `${translation.RealEstate}`, value: 'Real Estate' },
                      { key: '122', text: `${translation.RecreationalFacilitiesServices}`, value: 'Recreational Facilities and Services' },
                      { key: '123', text: `${translation.ReligiousInstitutions}`, value: 'Religious Institutions' },
                      { key: '124', text: `${translation.RenewablesEnvironment}`, value: 'Renewables & Environment' },
                      { key: '125', text: `${translation.Research}`, value: 'Research' },
                      { key: '126', text: `${translation.Restaurants}`, value: 'Restaurants' },
                      { key: '127', text: `${translation.Retail}`, value: 'Retail' },
                      { key: '128', text: `${translation.SecurityInvestigations}`, value: 'Security and Investigations' },
                      { key: '129', text: `${translation.Semiconductors}`, value: 'Semiconductors' },
                      { key: '130', text: `${translation.Shipbuilding}`, value: 'Shipbuilding' },
                      { key: '131', text: `${translation.SportingGoods}`, value: 'Sporting Goods' },
                      { key: '132', text: `${translation.Sports}`, value: 'Sports' },
                      { key: '133', text: `${translation.StaffingRecruiting}`, value: 'Staffing and Recruiting' },
                      { key: '134', text: `${translation.Supermarkets}`, value: 'Supermarkets' },
                      { key: '135', text: `${translation.Telecommunications}`, value: 'Telecommunications' },
                      { key: '136', text: `${translation.Textiles}`, value: 'Textiles' },
                      { key: '137', text: `${translation.ThinkTanks}`, value: 'Think Tanks' },
                      { key: '138', text: `${translation.Tobacco}`, value: 'Tobacco' },
                      { key: '139', text: `${translation.TranslationLocalization}`, value: 'Translation and Localization' },
                      { key: '140', text: `${translation.TransportationTruckingRailroad}`, value: 'Transportation/Trucking/Railroad' },
                      { key: '141', text: `${translation.Utilities}`, value: 'Utilities' },
                      { key: '142', text: `${translation.VentureCapitalPrivateEquity}`, value: 'Venture Capital & Private Equity' },
                      { key: '143', text: `${translation.Veterinary}`, value: 'Veterinary' },
                      { key: '144', text: `${translation.Warehousing}`, value: 'Warehousing' },
                      { key: '145', text: `${translation.Wholesale}`, value: 'Wholesale' },
                      { key: '146', text: `${translation.WineSpirits}`, value: 'Wine and Spirits' },
                      { key: '147', text: `${translation.Wireless}`, value: 'Wireless' },
                      { key: '148', text: `${translation.WritingEditing}`, value: 'Writing and Editing' }
                    ]}
                  />
                </Block>
              </Col>
              {formValues && formValues.professionExercise !== 'Physical' &&
                <React.Fragment>
                  <Col xs={12} md={4}>
                    <Block className="form-group">
                      <ControlLabel>{translation.Turnover}</ControlLabel>
                      <Field
                        name="turnover"
                        search
                        fluid
                        multiple={false}
                        selection
                        selectOnBlur={false}
                        noResultsMessage={translation.Turnover}
                        component={DropDown}
                        options={[
                          { key: '1', text: '50K - 250K', value: '50K - 250K' },
                          { key: '2 ', text: '250K - 500K', value: '250K - 500K' },
                          { key: '3 ', text: '500K - 1M', value: '500K - 1M' },
                          { key: '4 ', text: '1M - 5M', value: '1M - 5M' },
                          { key: '5 ', text: '>5M', value: '>5M' }
                        ]}
                      />
                    </Block>
                  </Col>
                  <Col xs={12} md={4}>
                    <Block className="form-group">
                      <ControlLabel>{translation.Numberemployees}</ControlLabel>
                      <Field
                        name="numberOfEmployees"
                        search
                        fluid
                        multiple={false}
                        selection
                        selectOnBlur={false}
                        noResultsMessage={translation.Numberemployees}
                        component={DropDown}
                        options={[
                          { key: '1', text: '1 - 20', value: '1 - 20' },
                          { key: '2 ', text: '20 - 100', value: '20 - 100' },
                          { key: '3 ', text: '100 - 500', value: '100 - 500' },
                          { key: '4 ', text: '500 - 5000', value: '500 - 5000' },
                          { key: '5 ', text: '5000 +', value: '5000 +' }
                        ]}
                      />
                    </Block>
                  </Col>
                </React.Fragment>
              }
            </Row>
          </React.Fragment>
        }
        {formValues && (formValues.civilStatus === 'Married' || formValues.civilStatus === 'Partner') &&
          <Row>
            <Col xs={12} md={12}>
              <Field
                name="spouseOccupation"
                component={TextBox}
                label={translation.Occupationofspouse}
                placeholder=""
              />
            </Col>
          </Row>
        }

        <Row>
          <Col xs={12} md={12}>

            <Block className="form-group">
              <ControlLabel>{translation.practicingyourprofessionalactivity}</ControlLabel>

              <Field
                name="yearsOfPracticing"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.Practicing}
                component={DropDown}
                options={[
                  { key: 'one', text: `1 - 5 ${translation.years}`, value: '1 - 5 years' },
                  { key: 'two', text: `5 - 10 ${translation.years}`, value: '5 - 10 Years' },
                  { key: 'three', text: `10 - 15 ${translation.years}`, value: '10 - 15 years' },
                  { key: 'four', text: `15 - 20 ${translation.years}`, value: '15 - 20 years' },
                  { key: 'five', text: `${translation.morethan} 20 ${translation.years}`, value: 'more than 20 years' }
                ]}
              />
            </Block>

          </Col>
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

ProfessionalInformationStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

ProfessionalInformationStep.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(ProfessionalInformationStep));
