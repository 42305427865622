const FUND_LIST = 'fund/FUND_LIST';
const FUND_LIST_SUCCESS = 'fund/FUND_LIST_SUCCESS';
const FUND_LIST_FAIL = 'fund/FUND_LIST_FAIL';

const FUND_DETAILS = 'fund/FUND_DETAILS';
const FUND_DETAILS_SUCCESS = 'fund/FUND_DETAILS_SUCCESS';
const FUND_DETAILS_FAIL = 'fund/FUND_DETAILS_FAIL';

const PORTFOLIO_LIST = 'fund/PORTFOLIO_LIST';
const PORTFOLIO_LIST_SUCCESS = 'fund/PORTFOLIO_LIST_SUCCESS';
const PORTFOLIO_LIST_FAIL = 'fund/PORTFOLIO_LIST_FAIL';

const BENCHMARK_LIST = 'fund/BENCHMARK_LIST';
const BENCHMARK_LIST_SUCCESS = 'fund/BENCHMARK_LIST_SUCCESS';
const BENCHMARK_LIST_FAIL = 'fund/BENCHMARK_LIST_FAIL';

const PORTFOLIO_DETAILS = 'fund/PORTFOLIO_DETAILS';
const PORTFOLIO_DETAILS_SUCCESS = 'fund/PORTFOLIO_DETAILS_SUCCESS';
const PORTFOLIO_DETAILS_FAIL = 'fund/PORTFOLIO_DETAILS_FAIL';

const PORTFOLIO_ASSET_DETAILS = 'fund/PORTFOLIO_ASSET_DETAILS';
const PORTFOLIO_ASSET_DETAILS_SUCCESS = 'fund/PORTFOLIO_ASSET_DETAILS_SUCCESS';
const PORTFOLIO_ASSET_DETAILS_FAIL = 'fund/PORTFOLIO_ASSET_DETAILS_FAIL';

const PORTFOLIO_SECTOR_DETAILS = 'fund/PORTFOLIO_SECTOR_DETAILS';
const PORTFOLIO_SECTOR_DETAILS_SUCCESS = 'fund/PORTFOLIO_SECTOR_DETAILS_SUCCESS';
const PORTFOLIO_SECTOR_DETAILS_FAIL = 'fund/PORTFOLIO_SECTOR_DETAILS_FAIL';

const PORTFOLIO_WORLD_REGION_DETAILS = 'fund/PORTFOLIO_WORLD_REGION_DETAILS';
const PORTFOLIO_WORLD_REGION_DETAILS_SUCCESS = 'fund/PORTFOLIO_WORLD_REGION_DETAILS_SUCCESS';
const PORTFOLIO_WORLD_REGION_DETAILS_FAIL = 'fund/PORTFOLIO_WORLD_REGION_DETAILS_FAIL';

const PORTFOLIO_HOLDINGS_DETAILS = 'fund/PORTFOLIO_HOLDINGS_DETAILS';
const PORTFOLIO_HOLDINGS_DETAILS_SUCCESS = 'fund/PORTFOLIO_HOLDINGS_DETAILS_SUCCESS';
const PORTFOLIO_HOLDINGS_DETAILS_FAIL = 'fund/PORTFOLIO_HOLDINGS_DETAILS_FAIL';

const BENCHMARK_PER_FUND_DETAIL = 'fund/BENCHMARK_PER_FUND_DETAIL';
const BENCHMARK_PER_FUND_DETAIL_SUCCESS = 'fund/BENCHMARK_PER_FUND_DETAIL_SUCCESS';
const BENCHMARK_PER_FUND_DETAIL_FAIL = 'fund/BENCHMARK_PER_FUND_DETAIL_FAIL';

const FUND_DETAIL_PERFORMANCE = 'fund/FUND_DETAIL_PERFORMANCE';
const FUND_DETAIL_PERFORMANCE_SUCCESS = 'fund/FUND_DETAIL_PERFORMANCE_SUCCESS';
const FUND_DETAIL_PERFORMANCE_FAIL = 'fund/FUND_DETAIL_PERFORMANCE_FAIL';

const CREATE_SWITCH_PDF = 'fund/CREATE_SWITCH_PDF';
const CREATE_SWITCH_PDF_SUCCESS = 'fund/CREATE_SWITCH_PDF_SUCCESS';
const CREATE_SWITCH_PDF_FAIL = 'fund/CREATE_SWITCH_PDF_FAIL';

const UPDATE_FUNDS = 'fund/UPDATE_FUNDS';
const UPDATE_FUNDS_SUCCESS = 'fund/UPDATE_FUNDS_SUCCESS';
const UPDATE_FUNDS_FAIL = 'fund/UPDATE_FUNDS_FAIL';

const SELECT_FUNDS = 'fund/SELECT_FUNDS';
const SELECT_FUNDS_SUCCESS = 'fund/SELECT_FUNDS_SUCCESS';
const SELECT_FUNDS_FAIL = 'fund/SELECT_FUNDS_FAIL';

const GET_PORTFOLIO_INFO = 'fund/GET_PORTFOLIO_INFO';
const GET_PORTFOLIO_INFO_SUCCESS = 'fund/GET_PORTFOLIO_INFO_SUCCESS';
const GET_PORTFOLIO_INFO_FAIL = 'fund/GET_PORTFOLIO_INFO_FAIL';

const SET_EDIT_FUND = 'fund/SET_EDIT_FUND';

const SET_SELECT_FUNDS = 'fund/SET_SELECT_FUNDS';

const FLUSH = 'fund/FLUSH';

const initialState = {
  fundListBusy: false,
  fundListError: false,
  fundList: null,
  funddetailsBusy: false,
  fundDetailsError: false,
  fundDetails: null,
  portfolioListBusy: false,
  portfolioListError: false,
  portfolioList: null,
  benchmarkListBusy: false,
  benchmarkListError: false,
  benchmarkList: null,
  portfoliodetailsBusy: false,
  portfolioDetailsError: false,
  portfolioDetails: null,
  portfolioId: '38b5e1f72492ce4619',
  currentPortfolio: { id: '38b5e1f72492ce4619', name: 'My First Portfolio' },
  portfolioAssetDetailsBusy: false,
  portfolioAssetDetailsError: false,
  portfolioAssetDetails: null,
  portfolioSectorDetailsBusy: false,
  portfolioSectorDetailsError: false,
  portfolioSectorDetails: null,
  portfolioWorldRegionDetailsBusy: false,
  portfolioWorldRegionDetailsError: false,
  portfolioWorldRegionDetails: null,
  portfolioHoldingsDetailsBusy: false,
  portfolioHoldingsDetailsError: false,
  portfolioHoldingsDetails: null,
  benchmarkPerFundDetailBusy: false,
  benchmarkPerFundDetailError: false,
  benchmarkPerFundDetail: null,
  fundDetailPerformanceBusy: false,
  fundDetailPerformanceError: false,
  fundDetailPerformance: null,
  createSwitchPdfBusy: false,
  createSwitchPdfError: false,
  createSwitchPdf: null,
  editFund: {},
  updateFundBusy: false,
  updateFundError: false,
  updateFund: null,
  selectFundBusy: false,
  selectFundError: false,
  selectFund: null,
  portfolioInfoBusy: false,
  portfolioInfoError: false,
  portfolioInfo: null,
  signupSelectedFunds: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FUND_LIST:
      return { ...state, fundListBusy: true, fundListError: null };

    case FUND_LIST_SUCCESS:
      return { ...state, fundListBusy: false, fundList: action.fundList };

    case FUND_LIST_FAIL:
      return { ...state, fundListBusy: false, fundListError: action.error };

    case FUND_DETAILS:
      return { ...state, funddetailsBusy: true, fundDetailsError: null };

    case FUND_DETAILS_SUCCESS:
      return { ...state, funddetailsBusy: false, fundDetails: action.res };

    case FUND_DETAILS_FAIL:
      return { ...state, funddetailsBusy: false, fundDetailsError: action.error };

    case PORTFOLIO_LIST:
      return { ...state, portfolioListBusy: true, portfolioListError: null };

    case PORTFOLIO_LIST_SUCCESS:
      return { ...state, portfolioListBusy: false, portfolioList: action.res };

    case PORTFOLIO_LIST_FAIL:
      return { ...state, portfolioListBusy: false, portfolioListError: action.error };

    case BENCHMARK_LIST:
      return { ...state, benchmarkListBusy: true, benchmarkListError: null };

    case BENCHMARK_LIST_SUCCESS:
      return { ...state, benchmarkListBusy: false, benchmarkList: action.res };

    case BENCHMARK_LIST_FAIL:
      return { ...state, benchmarkListBusy: false, benchmarkListError: action.error };

    case PORTFOLIO_DETAILS:
      return { ...state, portfoliodetailsBusy: true, portfolioDetailsError: null };

    case PORTFOLIO_DETAILS_SUCCESS:
      return { ...state, portfoliodetailsBusy: false, portfolioDetails: action.res };

    case PORTFOLIO_DETAILS_FAIL:
      return { ...state, portfoliodetailsBusy: false, portfolioDetailsError: action.error };

    case PORTFOLIO_ASSET_DETAILS:
      return { ...state, portfolioAssetDetailsBusy: true, portfolioAssetDetailsError: null };

    case PORTFOLIO_ASSET_DETAILS_SUCCESS:
      return { ...state, portfolioAssetDetailsBusy: false, portfolioAssetDetails: action.res };

    case PORTFOLIO_ASSET_DETAILS_FAIL:
      return { ...state, portfolioAssetDetailsBusy: false, portfolioAssetDetailsError: action.error };

    case PORTFOLIO_SECTOR_DETAILS:
      return { ...state, portfolioSectorDetailsBusy: true, portfolioSectorDetailsError: null };

    case PORTFOLIO_SECTOR_DETAILS_SUCCESS:
      return { ...state, portfolioSectorDetailsBusy: false, portfolioSectorDetails: action.res };

    case PORTFOLIO_SECTOR_DETAILS_FAIL:
      return { ...state, portfolioSectorDetailsBusy: false, portfolioSectorDetailsError: action.error };

    case PORTFOLIO_WORLD_REGION_DETAILS:
      return { ...state, portfolioWorldRegionDetailsBusy: true, portfolioWorldRegionDetailsError: null };

    case PORTFOLIO_WORLD_REGION_DETAILS_SUCCESS:
      return { ...state, portfolioWorldRegionDetailsBusy: false, portfolioWorldRegionDetails: action.res };

    case PORTFOLIO_WORLD_REGION_DETAILS_FAIL:
      return { ...state, portfolioWorldRegionDetailsBusy: false, portfolioWorldRegionDetailsError: action.error };

    case PORTFOLIO_HOLDINGS_DETAILS:
      return { ...state, portfolioHoldingsDetailsBusy: true, portfolioHoldingsDetailsError: null };

    case PORTFOLIO_HOLDINGS_DETAILS_SUCCESS:
      return { ...state, portfolioHoldingsDetailsBusy: false, portfolioHoldingsDetails: action.res };

    case PORTFOLIO_HOLDINGS_DETAILS_FAIL:
      return { ...state, portfolioHoldingsDetailsBusy: false, portfolioHoldingsDetailsError: action.error };

    case BENCHMARK_PER_FUND_DETAIL:
      return { ...state, benchmarkPerFundDetailBusy: true, benchmarkPerFundDetailError: null };

    case BENCHMARK_PER_FUND_DETAIL_SUCCESS:
      return { ...state, benchmarkPerFundDetailBusy: false, benchmarkPerFundDetail: action.res };

    case BENCHMARK_PER_FUND_DETAIL_FAIL:
      return { ...state, benchmarkPerFundDetailBusy: false, benchmarkPerFundDetailError: action.error };

    case FUND_DETAIL_PERFORMANCE:
      return { ...state, fundDetailPerformanceBusy: true, fundDetailPerformanceError: null };

    case FUND_DETAIL_PERFORMANCE_SUCCESS:
      return { ...state, fundDetailPerformanceBusy: false, fundDetailPerformance: action.res };

    case FUND_DETAIL_PERFORMANCE_FAIL:
      return { ...state, fundDetailPerformanceBusy: false, fundDetailPerformanceError: action.error };

    case CREATE_SWITCH_PDF:
      return { ...state, createSwitchPdfBusy: true, createSwitchPdfError: null };

    case CREATE_SWITCH_PDF_SUCCESS:
      return { ...state, createSwitchPdfBusy: false, createSwitchPdf: action.res };

    case CREATE_SWITCH_PDF_FAIL:
      return { ...state, createSwitchPdfBusy: false, createSwitchPdfError: action.error };

    case UPDATE_FUNDS:
      return { ...state, updateFundBusy: true, updateFundError: null };

    case UPDATE_FUNDS_SUCCESS:
      return { ...state, updateFundBusy: false, updateFund: action.res };

    case UPDATE_FUNDS_FAIL:
      return { ...state, updateFundBusy: false, updateFundError: action.error };

    case SELECT_FUNDS:
      return { ...state, selectFundBusy: true, selectFundError: null };

    case SELECT_FUNDS_SUCCESS:
      return { ...state, selectFundBusy: false, selectFund: action.res };

    case SELECT_FUNDS_FAIL:
      return { ...state, selectFundBusy: false, selectFundError: action.error };

    case GET_PORTFOLIO_INFO:
      return { ...state, portfolioInfoBusy: true, portfolioInfoError: null };

    case GET_PORTFOLIO_INFO_SUCCESS:
      return { ...state, portfolioInfoBusy: false, portfolioInfo: action.res };

    case GET_PORTFOLIO_INFO_FAIL:
      return { ...state, portfolioInfoBusy: false, portfolioInfoError: action.error };

    case SET_EDIT_FUND:
      return { ...state, editFund: action.fund };
    
    case SET_SELECT_FUNDS:
      return { ...state, signupSelectedFunds: action.data };

    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}


export const getFundList = () => async (dispatch, getState, api) => {
  dispatch({ type: FUND_LIST });

  try {
    const fundList = await api.get('/fund');
    dispatch({ type: FUND_LIST_SUCCESS, fundList });
  } catch (error) {
    dispatch({ type: FUND_LIST_FAIL, error });
  }
};

export const getFundDetail = id => async (dispatch, getState, api) => {
  dispatch({ type: FUND_DETAILS });

  try {
    const res = await api.get(`/fund/${id}`);
    dispatch({ type: FUND_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: FUND_DETAILS_FAIL, error });
  }
};

export const flushFundDetail = () => async (dispatch, getState, api) => {
  dispatch({ type: FUND_DETAILS_SUCCESS, res: null });
  dispatch({ type: FUND_DETAIL_PERFORMANCE_SUCCESS, res: null });
  dispatch({ type: BENCHMARK_PER_FUND_DETAIL_SUCCESS, res: null });
};

export const getPortfolioList = () => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_LIST });

  try {
    const res = await api.get('/portfolio');
    dispatch({ type: PORTFOLIO_LIST_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_LIST_FAIL, error });
  }
};

export const getBenchmarkList = () => async (dispatch, getState, api) => {
  dispatch({ type: BENCHMARK_LIST });

  try {
    const res = await api.get('/benchmark');
    dispatch({ type: BENCHMARK_LIST_SUCCESS, res });
  } catch (error) {
    dispatch({ type: BENCHMARK_LIST_FAIL, error });
  }
};

export const getPortfolioDetail = id => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_DETAILS });

  try {
    const res = await api.get(`/portfolio/${id}`);
    dispatch({ type: PORTFOLIO_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_DETAILS_FAIL, error });
  }
};

export const getPortfolioAssetDetails = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_ASSET_DETAILS });

  try {
    const res = await api.get(`/portfolio/${portfolioId}/asset-allocation`);
    dispatch({ type: PORTFOLIO_ASSET_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_ASSET_DETAILS_FAIL, error });
  }
};

export const getPortfolioSectorDetails = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_SECTOR_DETAILS });

  try {
    const res = await api.get(`/portfolio/${portfolioId}/sector-weights`);
    dispatch({ type: PORTFOLIO_SECTOR_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_SECTOR_DETAILS_FAIL, error });
  }
};

export const getPortfolioWorldRegionDetails = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_WORLD_REGION_DETAILS });

  try {
    const res = await api.get(`/portfolio/${portfolioId}/world-regions`);
    dispatch({ type: PORTFOLIO_WORLD_REGION_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_WORLD_REGION_DETAILS_FAIL, error });
  }
};

export const getPortfolioHoldingsDetails = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_HOLDINGS_DETAILS });

  try {
    const res = await api.get(`/portfolio/${portfolioId}/holdings`);
    dispatch({ type: PORTFOLIO_HOLDINGS_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: PORTFOLIO_HOLDINGS_DETAILS_FAIL, error });
  }
};

export const getBenchmarkPerFundDetail = id => async (dispatch, getState, api) => {
  dispatch({ type: BENCHMARK_PER_FUND_DETAIL });

  try {
    const res = await api.get(`/benchmark/${id}/fund-detail-performance`);
    dispatch({ type: BENCHMARK_PER_FUND_DETAIL_SUCCESS, res });
  } catch (error) {
    dispatch({ type: BENCHMARK_PER_FUND_DETAIL_FAIL, error });
  }
};

export const getFundDetailPerformance = id => async (dispatch, getState, api) => {
  dispatch({ type: FUND_DETAIL_PERFORMANCE });

  try {
    const res = await api.get(`/fund/${id}/detail-performance`);
    dispatch({ type: FUND_DETAIL_PERFORMANCE_SUCCESS, res });
  } catch (error) {
    dispatch({ type: FUND_DETAIL_PERFORMANCE_FAIL, error });
  }
};

export const switchPdf = data => async (dispatch, getState, api) => {
  dispatch({ type: CREATE_SWITCH_PDF });

  try {
    const resHere = await api.post('/switch-fund-pdf', { data });
    dispatch({ type: CREATE_SWITCH_PDF_SUCCESS, resHere });
    return resHere;
  } catch (error) {
    console.log(error, 'error here print');
    dispatch({ type: CREATE_SWITCH_PDF_FAIL, error });
  }
};

export const setEditFundValues = fund => async (dispatch, getState, api) => {
  dispatch({ type: SET_EDIT_FUND, fund });
};

export const updateFunds = data => async (dispatch, getState, api) => {
  dispatch({ type: UPDATE_FUNDS });
  try {
    const res = await api.post('/fund/update-funds', { data });
    dispatch({ type: UPDATE_FUNDS_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: UPDATE_FUNDS_FAIL, error: err });
  }
};

export const selectFunds = data => async (dispatch, getState, api) => {
  dispatch({ type: SELECT_FUNDS });
  try {
    const res = await api.post('/fund/select-funds', { data });
    dispatch({ type: SELECT_FUNDS_SUCCESS, res });
  } catch (err) {
    dispatch({ type: SELECT_FUNDS_FAIL, error: err });
  }
};

export const getPortfolioInfo = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: GET_PORTFOLIO_INFO });
  try {
    const res = await api.get(`portfolio/${portfolioId}/info`);
    dispatch({ type: GET_PORTFOLIO_INFO_SUCCESS, res });
  } catch (err) {
    dispatch({ type: GET_PORTFOLIO_INFO_FAIL, error: err });
  }
};

export const setSelectFunds = data => async (dispatch, getState, api) => {
  dispatch({ type: SET_SELECT_FUNDS, data });
};

export const depositWithdrawl = data => async (dispatch, getState, api) => {
  try {
    const res = await api.post('portfolio/deposit-withdrawl', { data });
    return res;
  } catch (err) {
    return err;
  }
};

