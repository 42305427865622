import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form, Tooltip } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import CustomRadioGroup from '../../formInputs/RadioGroup/CustomRadioGroup';

const tooltip = (
  <Tooltip id="tooltip">
   Votre priorité est la conservation de votre capital. Vous ne voulez pas d'investissements en actions.
  </Tooltip>
);

const tooltip2 = (
  <Tooltip id="tooltip2">
   Lorsque vous prenez des risques, vous visez un rendement supérieur au fonds euro tout en protégeant votre capital. Vous acceptez qu'une minorité de vos actifs soit investie en actions (25% environ selon les cycles)
  </Tooltip>
);

const tooltip3 = (
  <Tooltip id="tooltip3">
   Votre profil est caractérisé par un équilibre entre performance et risque. Vous acceptez de prendre un risque calculé sur votre capital pour tenter d'obtenir des rendements plus élevés. (50% d'actions en moyenne selon les cycles)
  </Tooltip>
);

const tooltip4 = (
  <Tooltip id="tooltip4">
   Vous recherchez un bon potentiel de croissance à long terme. Vous avez une bonne connaissance des marchés financiers et des risques associés. Le portefeuille dynamique investit à l’international dans différentes classes d’actifs, avec un pourcentage relativement élevé d’actions (80% d'actions en moyenne selon les cycles)
  </Tooltip>
);

const tooltip5 = (
  <Tooltip id="tooltip5">
 Vous donnez la priorité au potentiel de performance. Vous êtes conscient de la prise de risque élevée et que vos investissements vont fluctuer. Répartition d'actifs typique : moyenne de 100% en actions
  </Tooltip>
);

const tooltip6 = (
  <Tooltip id="tooltip6">
 Votre profil est caractérisé par une approche opportuniste entre la recherche de performance et la prise de risque. (0-100% d'actions selon les cycles)
  </Tooltip>
);

class AssetAllocationStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: undefined
    };
  }

  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4>{translation.ChoiceOfAssetAllocation}</h4>

        {/* <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>The following curves show the price movements of the mortgage hypothetical over 8 years. With which investment would you be most comfortable?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isMortgageInvestment"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'A',
                      text: 'I choose placement A'
                    }, {
                      value: 'B',
                      text: 'I choose placement B'
                    }, {
                      value: 'Between 5 and 8 years',
                      text: 'Between 5 and 8 years'
                    }, {
                      value: 'C',
                      text: 'I choose placement C'
                    }, {
                      value: 'D',
                      text: 'I choose placement D'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>You invest € 100,000, are you willing to accept that your investment can fall or progress from:</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isInvestmentProgress"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'A',
                      text: '- 5000 € or + 7000 €'
                    }, {
                      value: 'B',
                      text: '- € 10,000 or € 15,000'
                    }, {
                      value: 'C',
                      text: '- € 20,000 or + € 25,000'
                    }, {
                      value: 'D',
                      text: '- € 35,000 or + € 45,000'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>In summary, for this investment?</ControlLabel>
              <Block className="d-flex">
                <Field
                  name="isSummaryInvestment"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'A',
                      text: 'You want to have a safe investment, even if you benefit from a lower performance'
                    }, {
                      value: 'B',
                      text: 'You agree to take a minimum risk to improve the profitability of your investment'
                    }, {
                      value: 'C',
                      text: 'You seek above all surplus value, even taking risks'
                    }, {
                      value: 'D',
                      text: 'Above all, you want flexibility, even if you want independent performance of the indices'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>Are you planning to make a significant investment in the next eight years? Acquisition of your main residence or residence, donation, financing of studies of your children ...?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isInvestmentProgress"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Yes',
                      text: 'Yes'
                    }, {
                      value: 'No',
                      text: 'No'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row> */}

        {/* <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>Choice of asset allocation</ControlLabel>

              <Block className="d-flex flex-wrap">

                <OverlayTrigger placement="top" overlay={tooltip}>
                  <Block className="custome-radio-box">
                    <Svg type="conservative" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Conservative</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={tooltip2}>
                  <Block className="custome-radio-box">
                    <Svg type="defensive" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Defensive</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={tooltip3}>
                  <Block className="custome-radio-box">
                    <Svg type="balance" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Balanced</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={tooltip4}>
                  <Block className="custome-radio-box">
                    <Svg type="dynamic" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Dynamic</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={tooltip5}>
                  <Block className="custome-radio-box">
                    <Svg type="offensive" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Offensive</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={tooltip6}>
                  <Block className="custome-radio-box">
                    <Svg type="flexible" />
                    <Block className="radio-input">
                      <input type="radio" name="asset-allocation" />
                      <ControlLabel className="label-text">Flexible</ControlLabel>
                    </Block>
                  </Block>
                </OverlayTrigger>

              </Block>
            </Block>
          </Col>
        </Row> */}


        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.AllocationsETFinances}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation">
                <Field
                  name="assetAllocationChoice"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'conservative',
                      text: `${translation.Conservative}`,
                      className: 'asset-allocation-conservative',
                      tooltip
                    }, {
                      value: 'defensive',
                      text: `${translation.Defensive}`,
                      className: 'asset-allocation-defensive',
                      tooltip: tooltip2
                    }, {
                      value: 'balanced',
                      text: `${translation.Balanced}`,
                      className: 'asset-allocation-balanced',
                      tooltip: tooltip3
                    }, {
                      value: 'dynamic',
                      text: `${translation.Dynamic}`,
                      className: 'asset-allocation-dynamic',
                      tooltip: tooltip4
                    }, {
                      value: 'offensive',
                      text: `${translation.Offensive}`,
                      className: 'asset-allocation-offensive',
                      tooltip: tooltip5
                    }, {
                      value: 'flexible',
                      text: `${translation.Flexible}`,
                      className: 'asset-allocation-flexible',
                      tooltip: tooltip6
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>Allocations ETFinances / Jinvestismoinscher</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isInvestmentProgress"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Winner-Loser',
                      text: 'Winner-Loser'
                    }, {
                      value: 'Contrarian',
                      text: 'Contrarian'
                    }, {
                      value: 'Momentum',
                      text: 'Momentum'
                    }, {
                      value: 'War Protection',
                      text: 'War Protection'
                    }, {
                      value: 'Inflation Protection',
                      text: 'Inflation Protection'
                    }, {
                      value: 'Emerging',
                      text: 'Emerging'
                    }, {
                      value: 'Future',
                      text: 'Future'
                    }, {
                    }, {
                      value: 'Future',
                      text: 'Future'
                    }, {
                      value: 'Value',
                      text: 'Value'
                    }, {
                      value: 'Small mid cap',
                      text: 'Small mid cap'
                    }, {
                      value: 'Political Trends',
                      text: 'Political Trends'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
                      value: 'Value',
                      text: 'Value'
                    }, {
                      value: 'Small mid cap',
                      text: 'Small mid cap'
                    }, {
                      value: 'Political Trends',
                      text: 'Political Trends'
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Field
              name="WinnerLose"
              component={TextArea}
              label="WINNER-LOSER - Description of the management"
              placeholder=""
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Field
              name="CONTRARIAN"
              component={TextArea}
              label="CONTRARIAN - Description of the management"
              placeholder=""
            />
          </Col>
        </Row> */}

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="submit-btn">Submit</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>
      </Form>
    );
  }
}

AssetAllocationStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired
};

export default connect(
  state => ({
    // formValues: getFormValues('signupform')(state)
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    assetAllocationChoice: {
      required: true,
    }
  }),
})(AssetAllocationStep));
