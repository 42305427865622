import React, { PureComponent } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { reduxFormValidator } from 'valirator';
import { Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import TextBox from '../../formInputs/TextBox';
import { login, load } from '../../redux/modules/auth';


class AdminLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.type = 'password';
  }


  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(load());
  }

  handleLogin = (formData) => {
    const { dispatch } = this.props;
    const { email, password } = formData;
    dispatch(login(email, password, 'admin'));
  }
  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  render() {
    const { handleSubmit, me, location, loginError } = this.props;

    if (me && me.role === 2) {
      return (
        <Redirect to={{
          pathname: '/dashboard',
          state: { from: location, },
        }}
        />
      );
    }
    if (me && me.role === 1) {
      return (
        <Redirect to={{
          pathname: '/admin/dashboard',
          state: { from: location, },
        }}
        />
      );
    }
    return (
      <div className="login-content">
        <div className="logo">
          <h1 className="login-head">Let’s get started now!</h1>
          <h3>Or create an account if not registered yet</h3>
        </div>
        <div className="login-box">
          <form className="login-form" onSubmit={handleSubmit(this.handleLogin)}>
            <Field
              name="email"
              component={TextBox}
              label="Email :"
              placeholder="Email"
            />

            <div className="full-wrapper password-icon">
              <Field
                name="password"
                component={TextBox}
                label="Password :"
                placeholder="Password"
                type={this.state.type} 
              />             
              <span className="eye_ic" onClick = {this.showHide }> {this.state.type === 'input' ? <i className="material-icons">remove_red_eye</i> : <i className="material-icons">visibility_off</i> }</span>
            </div>

            <div className="form-group btn-container d-flex justify-content-center">
              <Button type="submit" className="btn btn-primary btn-block submit-btn">Sign In</Button>
            </div>

            {loginError &&
              <Alert bsStyle="danger" className="mt-3 mb-1">{loginError}</Alert>
            }
            <p className="forget-password text-center mt-3">
              <Link to="/forgot-password">Forgot Password?</Link>
            </p>
          </form>
        </div>
      </div>
    );
  }
}

AdminLogin.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.object,
  me: PropTypes.object,
  loginError: PropTypes.string,
};

AdminLogin.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  me: {},
  location: {},
  loginError: undefined
};

export default connect(
  state => ({
    loginError: state.auth.loginError,
    me: state.auth.user,
    // translations: getTranslations(state),
  }))(reduxForm({
  form: 'adminloginform',
  enableReinitialize: true,
  validate: reduxFormValidator({
    password: {
      rules: {
        required: true
      }
    },
    email: {
      rules: {
        required: true,
        format: 'email',
      }
    },
  }),
})(AdminLogin));
