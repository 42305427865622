import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from './../../style/images/main_logo.png';
import { logout } from '../../redux/modules/auth';
import { Svg, Block } from './../../components';

class DashboardHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleNavToggle = () => {
    const bodyClasses = document.getElementsByClassName('sidenav-toggled');
    if (bodyClasses.length > 0) {
      document.body.classList.remove('sidenav-toggled');
    } else {
      document.body.classList.add('sidenav-toggled');
    }
  }


  logout = () => {
    const { dispatch } = this.props;
    dispatch(logout());
  }

  render() {
    return (
      <header className="app-header">

        <Block className="app-sidebar__toggle" to="">
          <Svg type="menu-toggle" onClick={this.handleNavToggle} />
        </Block>

        <div className="app-header-text">
          <div className="logo-area">
            <Link to="/" className="login-header-logo">
              <img alt="" src={logo} />
            </Link>
          </div>
        </div>

        {/* <div className="app-search">
          <input className="app-search__input" type="search" placeholder="Type in to search..." />
          <button className="app-search__button">Button</button>
        </div> */}

        <ul className="app-nav">

          <li className="setting-menu dropdown">
            <Link to="/change-password" className="app-nav__item">
              <Svg type="setting-menu" />
            </Link>
          </li>

          <li className="sign-out">
            <button className="app-nav__item" onClick={this.logout}>
              <Svg type="signout-menu" />
            </button>
          </li>
        </ul>
      </header>
    );
  }
}

DashboardHeader.propTypes = {
  dispatch: PropTypes.func,
  me: PropTypes.object
};

DashboardHeader.defaultProps = {
  dispatch: null,
  me: {}
};

export default connect(
  state => ({
    // initialValues: state.companies.resendInviteItem,
    // me: state.auth.user,
    // translations: getTranslations(state),
  }))(DashboardHeader);
