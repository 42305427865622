// import store from 'store2';
// import { push } from 'react-router-redux';

const RISK_ALL = 'risk/RISK_ALL';
const RISK_SUCCESS = 'risk/RISK_SUCCESS';
const RISK_FAIL = 'risk/RISK_FAIL';

const LINK_RISK_FUND = 'risk/LINK_RISK_FUND';
const LINK_RISK_FUND_SUCCESS = 'risk/LINK_RISK_FUND_SUCCESS';
const LINK_RISK_FUND_FAIL = 'risk/LINK_RISK_FUND_FAIL';

const RISK_DETAIL = 'risk/RISK_DETAIL';
const RISK_DETAIL_SUCCESS = 'risk/RISK_DETAIL_SUCCESS';
const RISK_DETAIL_FAIL = 'risk/RISK_DETAIL_FAIL';

const initialState = {
  riskprofileBusy: false,
  riskProfileError: null,
  riskprofile: null,
  linkriskBusy: false,
  linkriskError: null,
  linkrisk: null,
  riskDetailBusy: false,
  riskDetailError: null,
  riskDetail: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RISK_ALL:
      return { ...state, riskprofileBusy: true, riskProfileError: false };

    case RISK_SUCCESS:
      return { ...state, riskprofileBusy: false, riskprofile: action.riskprofile };

    case RISK_FAIL:
      return { ...state, riskprofileBusy: false, riskProfileError: action.error };

    case LINK_RISK_FUND:
      return { ...state, linkriskBusy: true, linkriskError: false };

    case LINK_RISK_FUND_SUCCESS:
      return { ...state, linkriskBusy: false, linkrisk: action.linkrisk };

    case LINK_RISK_FUND_FAIL:
      return { ...state, linkriskBusy: false, linkriskError: action.error };

    case RISK_DETAIL:
      return { ...state, riskDetailBusy: true, riskDetailError: false };

    case RISK_DETAIL_SUCCESS:
      return { ...state, riskDetailBusy: false, riskDetail: action.riskDetail };

    case RISK_DETAIL_FAIL:
      return { ...state, riskDetailBusy: false, riskDetailError: action.error };
    default:
      return state;
  }
}


export const getRiskDetail = () => async (dispatch, getState, api) => {
  dispatch({ type: RISK_DETAIL });
  try {
    const riskDetail = await api.get('/risk/risk-detail');
    dispatch({ type: RISK_DETAIL_SUCCESS, riskDetail });
  } catch (err) {
    dispatch({ type: RISK_DETAIL_FAIL, error: err });
  }
};

export const RiskList = () => async (dispatch, getState, api) => {
  dispatch({ type: RISK_ALL });
  try {
    const riskprofile = await api.get('/risk');
    dispatch({ type: RISK_SUCCESS, riskprofile });
    return riskprofile;
  } catch (err) {
    dispatch({ type: RISK_FAIL, error: err });
  }
};

export const linkFundsRisk = data => async (dispatch, getState, api) => {
  dispatch({ type: LINK_RISK_FUND });

  try {
    const res = await api.post('/risk/link-funds-risk', { data });
    dispatch({ type: LINK_RISK_FUND_SUCCESS, res });
    return res;
  } catch (err) {
    dispatch({ type: LINK_RISK_FUND_FAIL, error: err });
  }
};

