import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';
import { createNumberMask } from 'redux-form-input-masks';

import DropDown from '../../formInputs/DropDown';
import TextBox from '../../formInputs/TextBox';
import TextArea from '../../formInputs/TextArea';
import { Block } from './../../components';

const currencyMask = createNumberMask({
  // decimalPlaces: 2
});

class AnnualIncomeStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perError: null
    };
  }

  handleSubmit = () => {
    const { moveNext, formValues, translation } = this.props;
    let perError = null;
    if (formValues.professionalIncomePer || formValues.savingIncomePer || formValues.otherIncomePer) {
      const professionalIncomePer = Number(formValues.professionalIncomePer) || 0;
      const savingIncomePer = Number(formValues.savingIncomePer) || 0;
      const otherIncomePer = Number(formValues.otherIncomePer) || 0;
      const per = professionalIncomePer + savingIncomePer + otherIncomePer;
      if (per !== 100) {
        perError = `${translation.PercentageError}`;
      }
    }

    if (perError === null) {
      moveNext();
    }
    this.setState({
      perError
    });
  }
  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    const { perError } = this.state;
    return (
      <Form className="annualIncome-information-form" onSubmit={handleSubmit(this.handleSubmit)}>
        <h4>{translation.ANNUALHOUSEHOLDINCOME}</h4>
        <Row>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.ProfessionalIncome}</ControlLabel>
              <Field
                name="professionalIncome"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.ProfessionalIncome}
                component={DropDown}
                options={[
                  { key: 'one', text: '< 60K', value: '< 60K' },
                  { key: 'two', text: '60K - 100K', value: '60K - 100K' },
                  { key: 'three', text: '100K - 150K', value: '100K - 150K' },
                  { key: 'four', text: '> 150K', value: '> 150K' }
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.SavingsIncome}</ControlLabel>
              <Field
                name="savingIncome"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.SavingsIncome}
                component={DropDown}
                options={[
                  { key: 'one', text: '< 60K', value: '< 60K' },
                  { key: 'two', text: '60K - 100K', value: '60K - 100K' },
                  { key: 'three', text: '100K - 150K', value: '100K - 150K' },
                  { key: 'four', text: '> 150K', value: '> 150K' }
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.OtherIncome}</ControlLabel>
              <Field
                name="otherIncome"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.OtherIncome}
                component={DropDown}
                options={[
                  { key: 'one', text: '< 60K', value: '< 60K' },
                  { key: 'two', text: '60K - 100K', value: '60K - 100K' },
                  { key: 'three', text: '100K - 150K', value: '100K - 150K' },
                  { key: 'four', text: '> 150K', value: '> 150K' }
                ]}
              />
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Field
              name="otherIncomeNature"
              component={TextBox}
              label={translation.OtherIncomeNature}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="totalIncome"
              // type="number"
              component={TextBox}
              label={translation.TotalIncome}
              placeholder=""
              {...currencyMask}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} md={6} lg={4}>
            <span className="input-right-ic">%</span>
            <Field
              name="professionalIncomePer"
              component={TextBox}
              label={`${translation.professionalincomepercentage} (%)`}
              placeholder=""
              type="number"
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <span className="input-right-ic">%</span>
            <Field
              name="savingIncomePer"
              component={TextBox}
              label={`${translation.Savingincomepercentage} (%)`}
              placeholder=""
              type="number"
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <span className="input-right-ic">%</span>
            <Field
              name="otherIncomePer"
              component={TextBox}
              label={`${translation.Otherincomepercentage} (%)`}
              placeholder=""
              type="number"
            />
          </Col>
        </Row>

        {perError !== null &&
          <Row>
            <Col xs={12} md={12}>
              <Block className="alert alert-danger text-center">
                {perError}
              </Block>
            </Col>
          </Row>
        }

        <Row>
          <Col xs={12} md={12}>
            <Field
              name="predictableDevelopments"
              component={TextArea}
              label={translation.Predictabledevelopments}
              placeholder=""
              labelBottom={translation.predictabledateretirement}
              maxLength="240"
            />
          </Col>
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

AnnualIncomeStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  moveNext: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.any
};

AnnualIncomeStep.defaultProps = {
  formValues: null
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(AnnualIncomeStep));
