import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { RiskList } from '../../redux/modules/risk';
import { getUserData } from '../../redux/modules/auth';
import diy from '../../style/images/risk-0.png';
import conservative from '../../style/images/risk-1.png';
import defensive from '../../style/images/risk-2.png';
import balanced from '../../style/images/risk-3.png';
import dynamic from '../../style/images/risk-4.png';
import offensive from '../../style/images/risk-5.png';
import flexible from '../../style/images/risk-6.png';

import { Block } from './../../components';

class DigitalPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.userData();
  }

  userData = async () => {
    const { dispatch } = this.props;
    const res = await dispatch(getUserData());
    await dispatch(RiskList());
    return res;
  }

  render() {
    const { userData, digitalplan, translation } = this.props;
    let riskImg;
    let text;
    if (userData && userData.userinfo.managementType === 'DIY') {

      if (digitalplan) {

        text = digitalplan.filter(r => r.riskName === 'DIY');
      }
      riskImg = diy;
    } else {
      switch (userData && userData.userinfo.assetAllocationChoice) {
        case 'conservative':
          riskImg = conservative;
          break;
        case 'defensive':
          riskImg = defensive;
          break;
        case 'balanced':
          riskImg = balanced;
          break;
        case 'dynamic':
          riskImg = dynamic;
          break;
        case 'offensive':
          riskImg = offensive;
          break;
        case 'flexible':
          riskImg = flexible;
          break;
        default:
      }
      if (digitalplan) {
        text = digitalplan.filter(r => r.riskName === userData.userinfo.assetAllocationChoice);
      }
    }

    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.DigitalPlan}</h3>
          </Block>
          <Block className="d-flex align-items-center justify-content-between risk-p cs-risk-p">
            <Block className="img">
              <img src={riskImg} alt="" />
            </Block>
            <Block className="text-box">
              {userData && userData.userinfo && userData.userinfo.managementType === 'DIY' &&
                <React.Fragment>
                  <h4 className="green-text">{translation.DoItYourself}</h4>
                  <p>
                    {text && text[0].descriptions}
                  </p>
                </React.Fragment>
              }
              {userData && userData.userinfo && userData.userinfo.managementType !== 'DIY' &&
                <React.Fragment>
                  <h4 className="green-text">{userData && userData.userinfo && (userData.userinfo.assetAllocationChoice).toUpperCase()}</h4>
                  <p>
                    {text && text[0].descriptions}
                  </p>
                </React.Fragment>
              }
            </Block>
          </Block>
        </Block>
      </Col>
    );
  }
}

export default connect(
  state => ({
    userData: state.admin.userData,
    digitalplan: state.risk.riskprofile,
    translation: state.translation.keys
  }))(DigitalPlan);

