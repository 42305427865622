import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Label, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { Block } from './../../components';

class Identity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { fund } = this.props;
    const tooltip = (
      <Tooltip id="tooltip">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
      </Tooltip>
    );

    return (

      <Block className="tile">
        <Block className="d-flex justify-content-start align-items-center">
          <h3 className="tile-title">
            <span>Identity</span>
          </h3>

          <OverlayTrigger placement="top" overlay={tooltip}>
            <i className="fa fa-info-circle info-ic" aria-hidden="true" />
          </OverlayTrigger>


        </Block>
        <Block className="d-block idtentity-list">
          <Row>
            <Col sm={12} md={12} lg={6}>
              <Label>ISIN</Label>
              {fund &&
                <p>{fund.ISIN}</p>
              }
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Label>Currency</Label>
              {fund &&
                <p>{fund.currency}</p>
              }
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <Label>Asset Class</Label>
              {fund &&
                <p>{fund.assetClass}</p>
              }
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Label>Replication Node</Label>
              {fund &&
                <p>{fund.replicationStrategy} {fund.physicalReplication}</p>
              }
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <Label>Lending</Label>
              {fund &&
                <p>{fund.lending === 'Y' ? 'Yes' : 'No'}</p>
              }
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Label>Fee</Label>
              {fund &&
                <p>{fund.fee}%</p>
              }
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <Label>Website</Label>
              {fund &&
                <p>{fund.companyUrl}</p>
              }
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Label>Dividend</Label>
              {fund &&
                <p>{fund.dividend}</p>
              }
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <Label>UCITS Compliant</Label>
              {fund &&
                <p>{fund.UCITS === 'Y' ? 'Yes' : 'No'}</p>
              }
            </Col>
          </Row>

        </Block>
      </Block>

    );
  }
}

Identity.propTypes = {
  fund: PropTypes.any
};

Identity.defaultProps = {
  fund: null
};

export default connect(
  state => ({
    fund: state.fund.fundDetails
  }))(Identity);

