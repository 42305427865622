import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import { Geo } from './../../components/Charts';

class DetailGeoChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  render() {
    const {
      portfolioWorldRegions,
      translation
    } = this.props;

    const geoData = portfolioWorldRegions || [];

    return (
      <Block>
        <p>{translation.PortfoliobreakdownbySector}</p>
        <Block className="d-block mt-4">
          <Geo data={geoData} />
        </Block>
      </Block>
    );
  }
}


DetailGeoChart.propTypes = {
  portfolioWorldRegions: PropTypes.any,    
  translation: PropTypes.any.isRequired
};

DetailGeoChart.defaultProps = {
  portfolioWorldRegions: null,
};

export default connect(
  state => ({
    portfolioWorldRegions: state.chart.portfolioWorldRegions,
    translation: state.translation.keys
  })
)(DetailGeoChart);
