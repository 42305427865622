import Loadable from 'react-loadable';
import Loading from './components/Loading';
import {
  Login
} from './containers';

export default [
  {
    path: '/',
    exact: true,
    component: Login
  },
  {
    path: '/login',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login'), loading: Loading })
  },
  {
    path: '/signup',
    exact: true,
    component: Loadable({ loader: () => import('./containers/SignUp'), loading: Loading })
  },
  {
    path: '/signup-soon',
    exact: true,
    component: Loadable({ loader: () => import('./containers/SignUpSoon'), loading: Loading })
  },
  
  {
    path: '/forgot-password',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ForgetPassword'), loading: Loading })
  },
  {
    path: '/reset-password',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ResetPassword'), loading: Loading })
  },
  {
    path: '/dashboard',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Dashboard'), loading: Loading })
  },
  {
    path: '/access-to-profile',
    exact: true,
    component: Loadable({ loader: () => import('./containers/MyProfile'), loading: Loading })
  },
  {
    path: '/fund-list',
    exact: true,
    component: Loadable({ loader: () => import('./containers/FundList'), loading: Loading })
  },
  {
    path: '/fund-list/:fundId',
    exact: true,
    component: Loadable({ loader: () => import('./containers/FundDetail'), loading: Loading })
  },
  {
    path: '/historical-flow',
    exact: true,
    component: Loadable({ loader: () => import('./containers/TransactionHistory'), loading: Loading })
  },
  {
    path: '/past-operations',
    exact: true,
    component: Loadable({ loader: () => import('./containers/PastOperations'), loading: Loading })
  },
  {
    path: '/detail-holding',
    exact: true,
    component: Loadable({ loader: () => import('./containers/DetailHolding'), loading: Loading })
  },
  {
    path: '/detail-asset',
    exact: true,
    component: Loadable({ loader: () => import('./containers/DetailAssetClass'), loading: Loading })
  },
  {
    path: '/detail-geographic-countries',
    exact: true,
    component: Loadable({ loader: () => import('./containers/DetailGeographicCountries'), loading: Loading })
  },
  {
    path: '/detail-sector',
    exact: true,
    component: Loadable({ loader: () => import('./containers/DetailSector'), loading: Loading })
  },
  {
    path: '/thankyou',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ThankyouPage'), loading: Loading })
  },
  {
    path: '/switchfunds',
    exact: true,
    component: Loadable({ loader: () => import('./containers/SwitchFunds'), loading: Loading })
  },
  {
    path: '/change-password',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ChangePassword'), loading: Loading })
  },
  {
    path: '/plan-history',
    exact: true,
    component: Loadable({ loader: () => import('./containers/PlanHistory'), loading: Loading })
  },
  {
    path: '/us-citizen-info',
    exact: true,
    component: Loadable({ loader: () => import('./containers/UsCitizenInfo'), loading: Loading })
  },
  {
    path: '/policy-payment/:userId',
    exact: true,
    component: Loadable({ loader: () => import('./containers/PolicyPayment'), loading: Loading })
  },
  // ADMIN ROUTES BEGIN HERE
  {
    path: '/admin',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminLogin'), loading: Loading })
  },
  {
    path: '/admin/dashboard',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminLinking'), loading: Loading })
  },
  {
    path: '/admin/config',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminConfig'), loading: Loading })
  },
  {
    path: '/admin/change-password',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminChangePassword'), loading: Loading })
  },
  {
    path: '/admin/admin-linking',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminLinking'), loading: Loading })
  },
  {
    path: '/admin/funds',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminFunds'), loading: Loading })
  },
  {
    path: '/admin/risk-profile',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminLinkRiskprofile'), loading: Loading })
  },
  {
    path: '/admin/risk-profile/:riskName',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ManageRiskFunds'), loading: Loading })
  },
  {
    path: '/admin/manage-users',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ManageUsers'), loading: Loading })
  },
  {
    path: '/admin/manage-users/:userId',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminUserProfile'), loading: Loading })
  },
  {
    path: '/admin/manage-user-funds',
    exact: true,
    component: Loadable({ loader: () => import('./containers/ManageUserFunds'), loading: Loading })
  },
  {
    path: '/admin/manage-user-funds/:userId',
    exact: true,
    component: Loadable({ loader: () => import('./containers/AdminSwitchFunds'), loading: Loading })
  },
  {
    path: '*',
    component: Login
  }
];
