import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form, Alert } from 'react-bootstrap';
import { Field, reduxForm, change, getFormValues, isValid } from 'redux-form';
import { combineValidators, isRequired, composeValidators, createValidator } from 'revalidate';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { Block } from './../../components';
import ChkBox from '../../formInputs/ChkBox';
import TextBox from '../../formInputs/TextBox/TextBox';

class YourDocumentsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: undefined,
      isInvalideFile: false,
      reCaptcha: false
    };
  }

  componentWillReceiveProps(nextProps){
    this.props.isValid(nextProps.valid)
  }

  onImageChange = (e) => {

    const file = e.target.files[0];
    const fileTypes = [
      'doc',
      'docx',
      'jpg',
      'jpeg',
      'png',
      'pdf',
      'xls',
      'xlsx'
    ];

    if (file) {
      const { dispatch } = this.props;
      if (file.name) {
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();
        if (!fileTypes.includes(fileExtension) && !fileTypes.includes(fileExtension.toUpperCase())) {
          this.setState({
            isInvalideFile: true
          });
          dispatch(change('signupform', 'attachment', ''));
          dispatch(change('signupform', 'attachmentName', ''));
          return false;
        }
        this.setState({
          isInvalideFile: false
        });
      }

      const reader = new FileReader();
      reader.onload = (upload) => {
        if (file.name) {
          dispatch(change('signupform', 'attachment', upload.target.result));
          dispatch(change('signupform', 'attachmentName', file.name));
        }
      };

      reader.readAsDataURL(file);

      this.setState({
        file,
        fileName: file.name,
        isFile: true
      });
    }
  }

  handleSubmit = (formData) => {

    const { onSubmit } = this.props;

    onSubmit(formData)
  }

  onChange = (value) => {
    let reCaptcha = false;
    if (value) {
      reCaptcha = true;
    }
    this.setState({
      reCaptcha
    });
  };

  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    const { isInvalideFile } = this.state;
    const file = (formValues && formValues.attachmentName ? formValues.attachmentName : false)
      || `${translation.Draganddropafile}`;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit(this.handleSubmit)}>
      
          <h4 style={{ textTransform: "inherit" }}>Pièces à communiquer par email (format pdf) à l’adresse <a href="mailto:bienvenue@luxavie.fr">bienvenue@luxavie.fr</a>  </h4>
          <ul style={{lineHeight:"2em",fontSize:"1.2em"}}>
            <li>Document d'identité valide (Carte Nationale d'identité, Passeport...)</li>
            <li>Attestation de domicile de moins de 3 mois</li>
            <li>Tout document attestant de l’origine des fonds</li>
            <li>Tout autre document que vous jugeriez utile</li>
          </ul>
        <p style={{ fontSize: "1.2em" }}>
          Nous restons à votre écoute pour répondre à vos questions : <a href="tel:+33.(0)1.85.08.49.94">+33.(0)1.85.08.49.94</a>
          </p>

        <Row>
          <Col xs={12} md={12}>
            {/* <ControlLabel>{translation.iagreeSignUp}<span className="require red-text">*</span></ControlLabel> */}

            <span className="require red-text">*</span>
            <Block className="form-group">
              <Field
                name="iAgree"
                component={ChkBox}
                className="animated-checkbox mr-4 mb-0"
                label={translation.iagreeSignUp}
                required
              />
            </Block>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} md={12}>
            <Block className="form-group inputDnD">
              <ControlLabel>
                {translation.AttachmentsTextSignUp}
              </ControlLabel>
              <Block className="input-file-outer">
                <input
                  type="file"
                  className="form-control-file text-primary font-weight-bold"
                  id="mi-files"
                  data-title={file}
                  onChange={this.onImageChange}
                />
                <span className="file_name">{file || translation.Draganddropafile}</span>
              </Block>
              {isInvalideFile &&
              <Alert bsStyle="danger" className="mt-3 mb-1">{translation.InvalidFileType}</Alert>
              }
            </Block>
          </Col>
        </Row> */}
        <Field
          name="attachment"
          component={TextBox}
          type="hidden"
        />
        <Field
          name="attachmentName"
          component={TextBox}
          type="hidden"
        />
        {formValues.isAgree}
        {(formValues && (formValues.managementType === 'DIY' || (formValues.managementType === 'delegate' && formValues.delegateType === 'Multisupports'))) ?
          (
            <Block className="form-group btn-container d-flex justify-content-center mt-5">
              <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
              <Button type="submit" className="next">{translation.Next}</Button>
            </Block>
          ) : (
            <React.Fragment>
              <Block className="captcha-wrapper">
                <ReCAPTCHA
                  sitekey="6LdHpZ8UAAAAAP9vnlOZqYLMbN8HEazuYXbHP8T7"
                  onChange={this.onChange}
                  theme="light"
                />
              </Block>
              <Block className="form-group btn-container d-flex justify-content-center mt-5">

                <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
                <Button loading={this.props.loader} disabled={this.props.loader || !this.state.reCaptcha} type="submit" className="next btn btn-default">{translation.Sendyourrequest}</Button>
              </Block>
            </React.Fragment>
          )
        }
      </Form>
    );
  }
}

YourDocumentsStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  isvalid: PropTypes.func,
};

const isAgree = createValidator(
  message => (value) => {
    if (!value) {
      return 'est requis';
    }
  },
  'Invalid Email ID'
);

const validate = combineValidators({
  iAgree: composeValidators(
    isRequired({ message: 'est requis' }),
    isAgree
  )(),
});

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys,
    valid: isValid('signupform')(state),
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(YourDocumentsStep));
