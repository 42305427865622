import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';

import { Block } from './../../components';

class KnowlegeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.KnowlegeInformation}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.legalknowledge}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.legalKnowledge || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.financialknowledge}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.financialKnowledge || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.legalknowledgespouse}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.legalKnowledgeSpouse || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Financialknowledgespouse}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.financialKnowledgeSpouse || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.appetiterisktakingperformance}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.appetite || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.partnerappetiteriskperformance}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.appetiteSpouse || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.professionalfinance}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.isProfessionalFinance || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.spousefinanceprofessional}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.isProfessionalFinanceSpouse || 'N/A'}</span>
              }
            </p>

          </Block>
        </Block>
      </Col>

    );
  }
}

KnowlegeProfile.propTypes = {
  showKnowlegeModal: PropTypes.func,
  userData: PropTypes.any
};

KnowlegeProfile.defaultProps = {
  showKnowlegeModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(KnowlegeProfile);

