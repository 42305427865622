import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Table, Row } from 'react-bootstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

import { AuthenticatedAdmin, Block, Button, Pagination } from '../../components';
import { DashboardHeader, PageTitle } from '../../components/Layout';
import { AdminLeftMenu } from '../../components/AdminLayout';
import { getUserList, sendUserPaymentLink } from './../../redux/modules/admin';
import { cryptData } from '../../utils/common';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: [],
      mainData: [],
      direction: null,
      showProfileModal: false,
      totalPages: 0, // for pagination
      activePage: 1, // for pagination
      pageLimit: 10, // for pagination
      pageData: [] // for pagination
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    await dispatch(getUserList());
    this.updateData(this.props.userList);
  }
  componentWillReceiveProps = (nextProps) => {
    if (this.props.userList !== nextProps.userList) {
      this.updateData(nextProps.userList);
    }
  }

  updateData = (userList) => {
    let data;
    if (userList) {
      data = userList.filter((item) => {
        return item.userinfo !== null;
      }
      ).map((item) => {

        return ({
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.userinfo && item.userinfo.phone ? item.userinfo.phone : '',
          createdAt: item.createdAt
        });
      });
    }
    this.setState({
      data,
      mainData: data
    }, () => {
      this.updatePageData();
    });
  }


  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn].toLowerCase() : '';
        }),
        direction: 'ascending'
      }, () => {
        this.updatePageData();
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    }, () => {
      this.updatePageData();
    });
  }

  onPageChange = (e, { activePage }) => {
    this.setState({
      activePage
    }, () => {
      this.updatePageData();
    });
  }

  sendPaymentLink = async (userId) => {
    this.setState({ loading: true });
    const res = await this.props.dispatch(sendUserPaymentLink(userId));
    console.log(res);
    if (res) {
      toastr.success('Success', 'Link has been sent to user');
    } else {
      toastr.error('Oops', 'Something went wrong');
    }
    this.setState({ loading: false });
  }

  updatePageData = () => {
    const { activePage, pageLimit, data } = this.state;
    const startIndex = (activePage - 1) * pageLimit;
    const endIndex = (activePage * pageLimit) - 1;
    const pageData = [];
    const totalPages = Math.ceil(data.length / pageLimit);
    data.map((i, key) => {
      if (key >= startIndex && key <= endIndex) {
        pageData.push(i);
      }
      return true;
    });

    this.setState({
      totalPages,
      pageData
    });
  }

  render() {
    const { translation } = this.props;
    const {
      pageData,
      column,
      direction
    } = this.state;
    let userListData;
    if (pageData) {
      userListData = pageData.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.email}</td>
            <td>{item.phone ? item.phone : 'N/A'}</td>
            <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
            {/* <td>
              <Button onClick={() => { this.showPracticesModal(item); }} content="Edit" />
              <Button onClick={() => { this.fundDelete(item.ISIN, item.id); }} content="Delete" />
            </td> */}
            <td>
              <Link to={`/admin/manage-users/${cryptData(item.id)}`} >
                <Button className="tb-btn-com">View Details</Button>
              </Link>
              <Button className="tb-btn-com" loading={this.state.loading} onClick={() => this.sendPaymentLink(cryptData(item.id))}>
                Send Payment Link
              </Button>
            </td>
          </tr>
        );
      });
    }
    return (
      <AuthenticatedAdmin>
        <PageTitle title="Manage Users" />
        <DashboardHeader />
        <AdminLeftMenu />
        <div className="app-content">
          <Row>
            <Col sm={12} md={12}>
              <Block className="tile fund-list-area">
                <h3 className="tile-title">Manage Users</h3>
                <Block className="table-responsive custom-table ">
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          className={column === 'firstName' ? `sorting ${direction}` : 'sorting'}
                          onClick={this.handleSort('firstName')}
                        >
                          {/* {translation.Name} */}
                          First Name
                        </th>
                        <th
                          className={column === 'lastName' ? `sorting ${direction}` : 'sorting'}
                          onClick={this.handleSort('lastName')}
                        >
                          {/* {translation.ISIN} */}
                          Last Name
                        </th>
                        <th
                          className={column === 'email' ? `sorting ${direction}` : 'sorting'}
                          onClick={this.handleSort('email')}
                        >
                          {/* {translation.Currency} */}
                          Email
                        </th>
                        <th
                          className={column === 'phone' ? `sorting ${direction}` : 'sorting'}
                          onClick={this.handleSort('phone')}
                        >
                          {/* {translation.AssetClass} */}
                          Phone
                        </th>
                        <th
                          className={column === 'createdAt' ? `sorting ${direction}` : 'sorting'}
                          onClick={this.handleSort('createdAt')}
                        >
                          {/* {translation.AssetClass} */}
                          Created At
                        </th>
                        <th>{translation.Action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userListData}
                    </tbody>
                  </Table>
                  <Pagination
                    activePage={this.state.activePage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                  />
                </Block>
              </Block>
            </Col>
          </Row>
          {/* <Modal
            title="Add/Edit Fund"
            show={this.state.showProfileModal}
            close={this.closeProfileModal}
          >
            <AddFundForm
              close={this.closeProfileModal}
              ISIN={this.state.editISIN}
            />
          </Modal> */}
        </div>
      </AuthenticatedAdmin>
    );
  }
}

ManageUsers.propTypes = {
  dispatch: PropTypes.func,
  // handleSubmit: PropTypes.func,
  userList: PropTypes.array,
  translation: PropTypes.any.isRequired,
  // reset: PropTypes.func
};

ManageUsers.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  userList: null,
  reset: null,
};

export default connect(
  state => ({
    userList: state.admin.userList,
    translation: state.translation.keys
  })
)(ManageUsers);
