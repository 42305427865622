const FUND_PERFORMACE = 'chart/FUND_PERFORMACE';
const FUND_PERFORMACE_SUCCESS = 'chart/FUND_PERFORMACE_SUCCESS';
const FUND_PERFORMACE_FAIL = 'chart/FUND_PERFORMACE_FAIL';

const BENCHMARK_PERFORMACE = 'chart/BENCHMARK_PERFORMACE';
const BENCHMARK_PERFORMACE_SUCCESS = 'chart/BENCHMARK_PERFORMACE_SUCCESS';
const BENCHMARK_PERFORMACE_FAIL = 'chart/BENCHMARK_PERFORMACE_FAIL';

const PORTFOLIO_PERFORMACE = 'chart/PORTFOLIO_PERFORMACE';
const PORTFOLIO_PERFORMACE_SUCCESS = 'chart/PORTFOLIO_PERFORMACE_SUCCESS';
const PORTFOLIO_PERFORMACE_FAIL = 'chart/PORTFOLIO_PERFORMACE_FAIL';

const PORTFOLIO_ASSET_ALLOCATION = 'chart/PORTFOLIO_ASSET_ALLOCATION';
const PORTFOLIO_ASSET_ALLOCATION_SUCCESS = 'chart/PORTFOLIO_ASSET_ALLOCATION_SUCCESS';
const PORTFOLIO_ASSET_ALLOCATION_FAIL = 'chart/PORTFOLIO_ASSET_ALLOCATION_FAIL';

const PORTFOLIO_SECTOR_WEIGHT = 'chart/PORTFOLIO_SECTOR_WEIGHT';
const PORTFOLIO_SECTOR_WEIGHT_SUCCESS = 'chart/PORTFOLIO_SECTOR_WEIGHT_SUCCESS';
const PORTFOLIO_SECTOR_WEIGHT_FAIL = 'chart/PORTFOLIO_SECTOR_WEIGHT_FAIL';

const PORTFOLIO_WORLD_REGION = 'chart/PORTFOLIO_WORLD_REGION';
const PORTFOLIO_WORLD_REGION_SUCCESS = 'chart/PORTFOLIO_WORLD_REGION_SUCCESS';
const PORTFOLIO_WORLD_REGION_FAIL = 'chart/PORTFOLIO_WORLD_REGION_FAIL';

const FLUSH = 'chart/FLUSH';

const initialState = {
  fundPermoanceBusy: false,
  fundPermoanceError: false,
  fundPermoance: null,
  benchmarkPermoanceBusy: false,
  benchmarkPermoanceError: false,
  benchmarkPermoance: null,
  portfolioPermoanceBusy: false,
  portfolioPermoanceError: false,
  portfolioPermoance: null,
  portfolioAssetAllocationBusy: false,
  portfolioAssetAllocationError: false,
  portfolioAssetAllocation: null,
  portfolioSectorWeightBusy: false,
  portfolioSectorWeightError: false,
  portfolioSectorWeight: null,
  portfolioWorldRegionsBusy: false,
  portfolioWorldRegionsError: false,
  portfolioWorldRegions: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FUND_PERFORMACE:
      return { ...state, fundPermoanceBusy: true, fundPermoanceError: null };

    case FUND_PERFORMACE_SUCCESS:
      return { ...state, fundPermoanceBusy: false, fundPermoance: action.fundPermoance };

    case FUND_PERFORMACE_FAIL:
      return { ...state, fundPermoanceBusy: false, fundPermoanceError: action.error };

    case BENCHMARK_PERFORMACE:
      return { ...state, benchmarkPermoanceBusy: true, benchmarkPermoanceError: null };

    case BENCHMARK_PERFORMACE_SUCCESS:
      return { ...state, benchmarkPermoanceBusy: false, benchmarkPermoance: action.benchmarkPermoance };

    case BENCHMARK_PERFORMACE_FAIL:
      return { ...state, benchmarkPermoanceBusy: false, benchmarkPermoanceError: action.error };

    case PORTFOLIO_PERFORMACE:
      return { ...state, portfolioPermoanceBusy: true, portfolioPermoanceError: null };

    case PORTFOLIO_PERFORMACE_SUCCESS:
      return { ...state, portfolioPermoanceBusy: false, portfolioPermoance: action.portfolioPermoance };

    case PORTFOLIO_PERFORMACE_FAIL:
      return { ...state, portfolioPermoanceBusy: false, portfolioPermoanceError: action.error };

    case PORTFOLIO_ASSET_ALLOCATION:
      return { ...state, portfolioAssetAllocationBusy: true, portfolioAssetAllocationError: null };

    case PORTFOLIO_ASSET_ALLOCATION_SUCCESS:
      return { ...state, portfolioAssetAllocationBusy: false, portfolioAssetAllocation: action.portfolioAssetAllocation };

    case PORTFOLIO_ASSET_ALLOCATION_FAIL:
      return { ...state, portfolioAssetAllocationBusy: false, portfolioAssetAllocationError: action.error };

    case PORTFOLIO_SECTOR_WEIGHT:
      return { ...state, portfolioSectorWeightBusy: true, portfolioSectorWeightError: null };

    case PORTFOLIO_SECTOR_WEIGHT_SUCCESS:
      return { ...state, portfolioSectorWeightBusy: false, portfolioSectorWeight: action.portfolioSectorWeight };

    case PORTFOLIO_SECTOR_WEIGHT_FAIL:
      return { ...state, portfolioSectorWeightBusy: false, portfolioSectorWeightError: action.error };

    case PORTFOLIO_WORLD_REGION:
      return { ...state, portfolioWorldRegionsBusy: true, portfolioWorldRegionsError: null };

    case PORTFOLIO_WORLD_REGION_SUCCESS:
      return { ...state, portfolioWorldRegionsBusy: false, portfolioWorldRegions: action.portfolioWorldRegions };

    case PORTFOLIO_WORLD_REGION_FAIL:
      return { ...state, portfolioWorldRegionsBusy: false, portfolioWorldRegionsError: action.error };

    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}


export const getFundPerformaceChartData = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: FUND_PERFORMACE });

  try {
    const fundPermoance = await api.get(`/chart/fund-perfomance/${id}/${period}`);
    dispatch({ type: FUND_PERFORMACE_SUCCESS, fundPermoance });
  } catch (error) {
    dispatch({ type: FUND_PERFORMACE_FAIL, error });
  }
};

export const getBenchmarkPerformaceChartData = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: BENCHMARK_PERFORMACE });

  try {
    const benchmarkPermoance = await api.get(`/chart/benchmark-perfomance/${id}/${period}`);
    dispatch({ type: BENCHMARK_PERFORMACE_SUCCESS, benchmarkPermoance });
  } catch (error) {
    dispatch({ type: BENCHMARK_PERFORMACE_FAIL, error });
  }
};

export const getPortfolioPerformaceChartData = (id, period) => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_PERFORMACE });

  try {
    const portfolioPermoance = await api.get(`/chart/portfolio-perfomance/${id}/${period}`);
    dispatch({ type: PORTFOLIO_PERFORMACE_SUCCESS, portfolioPermoance });
  } catch (error) {
    dispatch({ type: PORTFOLIO_PERFORMACE_FAIL, error });
  }
};

export const getPortfolioAssetAllocationData = id => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_ASSET_ALLOCATION });

  try {
    const portfolioAssetAllocation = await api.get(`/chart/portfolio-asset-allocation/${id}`);
    dispatch({ type: PORTFOLIO_ASSET_ALLOCATION_SUCCESS, portfolioAssetAllocation });
  } catch (error) {
    dispatch({ type: PORTFOLIO_ASSET_ALLOCATION_FAIL, error });
  }
};

export const getPortfolioSectorWeights = id => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_SECTOR_WEIGHT });

  try {
    const portfolioSectorWeight = await api.get(`/chart/portfolio-sector-weight/${id}`);
    dispatch({ type: PORTFOLIO_SECTOR_WEIGHT_SUCCESS, portfolioSectorWeight });
  } catch (error) {
    dispatch({ type: PORTFOLIO_SECTOR_WEIGHT_FAIL, error });
  }
};

export const getPortfolioWorldRegions = id => async (dispatch, getState, api) => {
  dispatch({ type: PORTFOLIO_WORLD_REGION });

  try {
    const portfolioWorldRegions = await api.get(`/chart/portfolio-world-regions/${id}`);
    dispatch({ type: PORTFOLIO_WORLD_REGION_SUCCESS, portfolioWorldRegions });
  } catch (error) {
    dispatch({ type: PORTFOLIO_WORLD_REGION_FAIL, error });
  }
};

export const flushFundDetailChart = () => async (dispatch, getState, api) => {
  dispatch({ type: BENCHMARK_PERFORMACE_SUCCESS, benchmarkPermoance: null });
  dispatch({ type: FUND_PERFORMACE_SUCCESS, fundPermoance: null });
};
