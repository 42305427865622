import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
// import { Form, TextArea as Area } from 'semantic-ui-react'; // eslint-disable-line
import { FormControl, FormGroup } from 'react-bootstrap';

const TextArea = ({ input, label, meta, labelBottom, ...custom }) => {
  const { touched, error } = meta || {};
  const hasError = (touched && !!error);

  if (custom && custom.disabled && custom.placeholder) {
    delete custom.placeholder;
  }

  return (
    <FormGroup error={hasError || ''}>
      {label && <label>{label}</label>}
      <FormControl
        className={hasError ? 'error' : ''}
        componentClass="textarea"
        {...input}
        {...custom}
        autoComplete="poo"
      />
      {labelBottom && <span className="input-msg-info">{labelBottom}</span>}
    </FormGroup>
  );
};

TextArea.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  meta: PropTypes.object,
  custom: PropTypes.object,
  labelBottom: PropTypes.string,
};

export default TextArea;
