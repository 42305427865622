import React, { PureComponent } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { reduxFormValidator } from 'valirator';
import { Button } from 'react-bootstrap';

import TextBox from '../../formInputs/TextBox';
import { forgotPassword, load } from '../../redux/modules/auth';

import './ForgetPassword.css';


class ForgetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'demo'
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(load());
  }

  handleGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleForgetPassword = (formData) => {
    const { dispatch } = this.props;
    const host = `${window.location.protocol}//${window.location.host}`;
    dispatch(forgotPassword(formData.email, host));
  }

  render() {
    const { handleSubmit, forgotPasswordMessage, me, location } = this.props;

    if (me) {
      return (
        <Redirect to={{
          pathname: '/dashboard',
          state: { from: location, },
        }}
        />
      );
    }

    return (
      <div className="login-content">
        <div className="logo">
          <h1 className="login-head">Forget you password?</h1>
          <h3>
            If you forgot your password,&nbps;
            please enter the email address associated with your account to reset your password.
          </h3>
        </div>
        <div className="login-box">
          <form className="login-form" onSubmit={handleSubmit(this.handleForgetPassword)} >
            <Field
              name="email"
              component={TextBox}
              label="Email :"
              placeholder="Email"
            />

            <div className="form-group btn-container d-flex justify-content-center">
              <Button type="submit" className="btn btn-primary btn-block submit-btn">Send Reset Email</Button>
            </div>
            {forgotPasswordMessage &&
              <div className="alert alert-primary mt-3 text-align">{forgotPasswordMessage}</div>
            }


            <p className="forget-password text-center mt-3">
              <button type="button" onClick={this.handleGoBack} className="btn-link-back d-inline-flex align-items-center">
                <i className="material-icons">
                  keyboard_backspace
                </i> Back
              </button>
            </p>
          </form>
        </div>
      </div>
    );
  }
}

ForgetPassword.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  forgotPasswordMessage: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  me: PropTypes.object
};

ForgetPassword.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  forgotPasswordMessage: undefined,
  location: {},
  history: {},
  me: null
};

export default connect(
  state => ({
    forgotPasswordMessage: state.auth.forgotPasswordMessage,
    me: state.auth.user
  }))(reduxForm({
  form: 'forgetpasswordform',
  enableReinitialize: true,
  validate: reduxFormValidator({
    email: {
      rules: {
        required: true,
        format: 'email',
      }
    }
  }),
})(ForgetPassword));
