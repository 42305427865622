import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import TextBox from '../../formInputs/TextBox';
import DatePicker from '../../formInputs/DatePicker';
import RadioGroup from '../../formInputs/RadioGroup';
import { Block } from './../../components';
import DropDown from '../../formInputs/DropDown';

class PoliticalMandateStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;

    return (
      <Form className="political-mandate-form" onSubmit={handleSubmit}>
        <h4>{translation.POLITICALMANDATE}</h4>
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.Doesanunderwriter}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isPoliticalMandate"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        {formValues.isPoliticalMandate === 'yes' &&
          <React.Fragment>
            <Block id="political-mandate" className="w-100">

              <Row>
                <Col xs={12} md={6}>
                  <Field
                    name="politicalName"
                    component={TextBox}
                    label={translation.Name}
                    placeholder=""
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    name="politicalFirstName"
                    component={TextBox}
                    label={translation.LastName}
                    placeholder=""
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4}>
                  <Field
                    name="politicalNationality"
                    component={TextBox}
                    label={translation.Nationality}
                    placeholder=""
                  />
                </Col>
                <Col xs={12} md={4}>
                  <Block className="calender-div">
                    <Field
                      name="politicalDateOfBirth"
                      component={DatePicker}
                      label={translation.Birthdate}
                      placeholder=""
                      className="form-control"
                    />
                    <i className="material-icons">date_range</i>
                  </Block>
                </Col>
                <Col xs={12} md={4}>
                  <Field
                    name="politicalMandateType"
                    component={TextBox}
                    label={translation.Typeofmandate}
                    placeholder=""
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <Field
                    name="politicalRelationship"
                    component={TextBox}
                    label={translation.Relationship}
                    placeholder=""
                  />
                </Col>
                <Col xs={12} md={6}>

                  <Block className="form-group">
                    <ControlLabel>{translation.Countryconcerned}</ControlLabel>
                    <Field
                      name="politicalCountryConcerned"
                      search
                      fluid
                      multiple={false}
                      selection
                      selectOnBlur={false}
                      noResultsMessage="Passport Issuing Country"
                      component={DropDown}
                      options={[
                        { key: 'France', text: 'France', value: 'France' },
                        { key: 'Angleterre', text: 'Angleterre', value: 'Angleterre' },
                        { key: 'Allemagne', text: 'Allemagne', value: 'Allemagne' },
                        { key: 'Belgique', text: 'Belgique', value: 'Belgique' },
                        { key: 'Espagne', text: 'Espagne', value: 'Espagne' },
                        { key: 'Italie', text: 'Italie', value: 'Italie' },
                        { key: 'Autriche', text: 'Autriche', value: 'Autriche' },
                        { key: 'Luxembourg', text: 'Luxembourg', value: 'Luxembourg' }
                      ]}
                    />
                  </Block>

                </Col>
              </Row>

            </Block>
          </React.Fragment>
        }
        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

PoliticalMandateStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

PoliticalMandateStep.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(PoliticalMandateStep));
