import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form, Tooltip } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import { Block } from './../../components';
// import ChkBox from '../../formInputs/ChkBox';
import RadioGroup from '../../formInputs/RadioGroup';
import DropDown from '../../formInputs/DropDown';
import TextBox from '../../formInputs/TextBox';

import CustomRadioGroup from '../../formInputs/RadioGroup/CustomRadioGroup';

const prioritytooltip = (
  <Tooltip id="prioritytooltip">
    Priority
  </Tooltip>
);
const secondarytooltip = (
  <Tooltip id="secondarytooltip">
    Secondary
  </Tooltip>
);
const notimportanttooltip = (
  <Tooltip id="notimportanttooltip">
    Not Important
  </Tooltip>
);

class GoalStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4>{translation.OBJECTIVE}</h4>

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.Searchcapitalgainslongmedium}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="capitalGains"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Not important',
                      text: `${translation.Notimportant}`,
                      tooltip: notimportanttooltip
                    }, {
                      value: 'Secondary',
                      text: `${translation.Secondary}`,
                      tooltip: secondarytooltip
                    },
                    {
                      value: 'Priority',
                      text: `${translation.Priority}`,
                      tooltip: prioritytooltip
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.Creatingprecautionarysavings}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="precautionarySavings"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [
                      {
                        value: 'Not important',
                        text: `${translation.Notimportant}`,
                        tooltip: notimportanttooltip
                      }, {
                        value: 'Secondary',
                        text: `${translation.Secondary}`,
                        tooltip: secondarytooltip
                      },
                      {
                        value: 'Priority',
                        text: `${translation.Priority}`,
                        tooltip: prioritytooltip
                      }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.Searchforincome}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="searchForIncome"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [
                      {
                        value: 'Not important',
                        text: `${translation.Notimportant}`,
                        tooltip: notimportanttooltip
                      }, {
                        value: 'Secondary',
                        text: `${translation.Secondary}`,
                        tooltip: secondarytooltip
                      },
                      {
                        value: 'Priority',
                        text: `${translation.Priority}`,
                        tooltip: prioritytooltip
                      }]
                  }
                />
              </Block>
            </Block>
          </Col>

          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.Preparationforretirement}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="forRetirement"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [
                      {
                        value: 'Not important',
                        text: `${translation.Notimportant}`,
                        tooltip: notimportanttooltip
                      }, {
                        value: 'Secondary',
                        text: `${translation.Secondary}`,
                        tooltip: secondarytooltip
                      },
                      {
                        value: 'Priority',
                        text: `${translation.Priority}`,
                        tooltip: prioritytooltip
                      }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.TransferOfCapital} </ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="transferAtDeath"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Not important',
                      text: `${translation.Notimportant}`,
                      tooltip: notimportanttooltip
                    }, {
                      value: 'Secondary',
                      text: `${translation.Secondary}`,
                      tooltip: secondarytooltip
                    },
                    {
                      value: 'Priority',
                      text: `${translation.Priority}`,
                      tooltip: prioritytooltip
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.Wyouexpectyourinvestment}?</ControlLabel>
              <Field
                name="expectationType"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Number employees"
                component={DropDown}
                options={[
                  { key: '1', text: `${translation.Preserveyourcapital}`, value: `${translation.Preserveyourcapital}` },
                  { key: '2', text: `${translation.Firstandforemost}`, value: `${translation.Firstandforemost}` },
                  { key: '3', text: `${translation.Moderatereturnwithmediumrisk}`, value: `${translation.Moderatereturnwithmediumrisk}` },
                  { key: '4', text: `${translation.Seekperformancewithsignficantrisklevel}`, value: `${translation.Seekperformancewithsignficantrisklevel}`}
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="expectation"
              component={TextBox}
              label={translation.Pleasespecifyyourexpectations}
              placeholder=""
              required
            />
          </Col>
        </Row>


        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.expecteddurationinvestment}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="durationOfInvestment"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: translation.Lessthan2years,
                      text: translation.Lessthan2years
                    }, {
                      value: translation.Between2and5years,
                      text: translation.Between2and5years
                    }, {
                      value: translation.Between5and8years,
                      text: translation.Between5and8years
                    }, {
                      value: translation.Morethan10years,
                      text: translation.Morethan10years
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.PercentageTotalAssetsDoesThisInvestmentRepresent}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="percentageOfAssets"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: translation.Lessthan25,
                      text: translation.Lessthan25
                    }, {
                      value: translation.Between25and50,
                      text: translation.Between25and50
                    }, {
                      value: translation.Between50and75,
                      text: translation.Between50and75
                    }, {
                      value: translation.Morethan75,
                      text: translation.Morethan75
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>
      </Form>
    );
  }
}

GoalStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  previousPage: PropTypes.func.isRequired
};

export default connect(
  state => ({
    translation: state.translation.keys
    // formValues: getFormValues('signupform')(state)
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(GoalStep));
