import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Label } from 'react-bootstrap';

import { Block } from './../../components';

class CharityInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { userData } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">Charity Information</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>Charity Company</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.charityCompany || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Charity Amount</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.charityFee || 'N/A'}</span>
              }
            </p>
          </Block>
        </Block>
      </Col>

    );
  }
}

CharityInformation.propTypes = {
  userData: PropTypes.any
};

CharityInformation.defaultProps = {
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
  }))(CharityInformation);
