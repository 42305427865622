import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Table } from 'react-bootstrap';
import { Block } from './../../components';
import {
  getPortfolioAssetDetails
} from '../../redux/modules/fund';

class Breakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null,
      assetClassNames: []
    };
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioAssetDetails(portfolioId));
      this.updateData(this.props.portfolioAssetDetails);
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const { dispatch } = this.props;
    if (this.props.portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioAssetDetails(nextProps.portfolioId));
      this.updateData(nextProps.portfolioAssetDetails);
    }
  }

  updateData = () => {
    const { portfolioAssetDetails } = this.props;
    let data;
    const assetClassNames = [];
    if (portfolioAssetDetails) {
      portfolioAssetDetails.map((item) => {
        for (const key in item) {
          if (!assetClassNames.includes(key) && key !== 'fundId' && key !== 'fundDetail') {
            assetClassNames.push(key);
          }
        }
        return true;
      });
      data = portfolioAssetDetails.map((item) => {
        const r = {
          holding: item.fundDetail.name,
          Stock: item.Stock,
          Bond: item.Bond,
          Property: item.Property,
          Cash: item.Cash,
          Other: item.Other
        };
        assetClassNames.map((c) => {
          r[c] = item[c] || undefined;
          r[c] = r[c] ? r[c].replace(',', '.') : r[c];
          return true;
        });
        return (r);
      });
    }
    this.setState({
      assetClassNames,
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      data,
      column,
      direction,
      assetClassNames
    } = this.state;
    
    const { translation } = this.props;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        const tdData = assetClassNames.map(c => (
          <td>{item[c] ? `${item[c]}%` : '-'}</td>
        ));
        return (
          <tr>
            <td>{item.holding}</td>
            {tdData}
          </tr>
        );
      });
    }
    let thData;
    if (assetClassNames) {
      thData = assetClassNames.map(c => (
        <th
          className={column === c ? `sorting ${direction}` : 'sorting'}
          onClick={this.handleSort(c)}
        >
          {c}
        </th>
      ));
    }

    return (
      <Row>
        <Col sm={12} md={12}>
          <Block className="d-block mt-5">
            <h2 className="heading-2">
              <u>{translation.Breakdownperholding} (%)</u>
            </h2>
            <Block className="table-responsive custom-table table-heading-bg">
              <Table className="table">
                <thead>
                  <tr>
                    <th
                      className={column === 'holding' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('holding')}
                    >
                      {translation.Holding}
                    </th>
                    {thData}
                  </tr>
                </thead>
                <tbody>
                  {tableData}
                </tbody>
              </Table>
            </Block>
          </Block>
        </Col>
      </Row>
    );
  }
}


Breakdown.propTypes = {
  dispatch: PropTypes.func,
  portfolioAssetDetails: PropTypes.any,
  portfolioId: PropTypes.any,
  translation: PropTypes.any.isRequired
};

Breakdown.defaultProps = {
  dispatch: null,
  portfolioAssetDetails: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    portfolioAssetDetails: state.fund.portfolioAssetDetails,
    translation: state.translation.keys
  }))(Breakdown);

