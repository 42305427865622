import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Table, Row } from 'react-bootstrap';

import { Block, SelectPortfolio } from '../';
import { userFundsDetail } from '../../redux/modules/auth';
import { frenchDate } from '../../utils/common';

class TransactionHistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0, // for pagination
      activePage: 1, // for pagination
      pageLimit: 12, // for pagination
      pageData: [] // for pagination
    };
  }

  componentDidMount = () => {
    const { portfolioId } = this.props;
    if (portfolioId) {
      this.userFunds(portfolioId);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioId !== nextProps.portfolioId) {
      this.userFunds(nextProps.portfolioId);
    }
  }

  userFunds = async (portfolioId) => {
    const { dispatch } = this.props;
    const res = await dispatch(userFundsDetail(portfolioId, '1M'));
    this.updateData(this.props.userFunds);
    return res;
  }

  updateData = (userFunds) => {
    let data = [];
    userFunds.map((item) => {
      const type = item.transactionType;
      let typeCheck = false;
      if (type === 'SWITCH' || type === 'SWITCH_COOLING_OFF') {
        typeCheck = true;
      }
      let transactionType = item.transactionType;
      if (typeCheck) {
        if (type === 'SWITCH' && Number(item.fundUnits) > 0) {
          transactionType = 'BUY';
        } else if (type === 'SWITCH' && Number(item.fundUnits) < 0) {
          transactionType = 'SELL';
        } else {
          transactionType = type.toString().replace(new RegExp('_', 'g'), ' ');
        }
        data.push(
          <tr key={item.transId}>
            <td>{frenchDate(item.fundDate)}</td>
            <td>{transactionType}</td>
            <td>{item.fundISIN}</td>
            <td>{item.fundName}</td>
            <td>{item.fundUnits}</td>
            <td>{item.fundPriceEUR}€</td>
            <td>{item.fundAmountEUR}€</td>
          </tr>
        );
      } else {
        transactionType = transactionType.toString().replace(new RegExp('_', 'g'), ' ');
        data.push(
          // <tr key={item.transId}>
          //   <td>{frenchDate(item.fundDate)}</td>
          //   <td>{transactionType}</td>
          //   <td>-</td>
          //   <td>-</td>
          //   <td>-</td>
          //   <td>-</td>
          //   <td>{item.netPremium}€</td>
          // </tr>
        );
      }
      return true;
    });
    data = data.slice(0, 6);
    this.setState({
      data,
      mainData: data
    }, () => {
      this.updatePageData();
    });
  }

  onPageChange = (e, { activePage }) => {
    this.setState({
      activePage
    }, () => {
      this.updatePageData();
    });
  }

  updatePageData = () => {
    const { activePage, pageLimit, data } = this.state;
    const startIndex = (activePage - 1) * pageLimit;
    const endIndex = (activePage * pageLimit) - 1;
    const pageData = [];
    const totalPages = Math.ceil(data.length / pageLimit);
    data.map((i, key) => {
      if (key >= startIndex && key <= endIndex) {
        pageData.push(i);
      }
      return true;
    });

    this.setState({
      totalPages,
      pageData
    });
  }

  render() {
    const {
      pageData
    } = this.state;
    const { translation } = this.props;

    return (
      <Col sm={12} md={12}>
        <Block className="tile min-height-cover">
          <Block className="d-flex flex-wrap justify-content-between align-items-center tile-header-part">
            <h3 className="tile-title">{translation.PastOperations}</h3>
            <Block className="d-block">
              <SelectPortfolio />
            </Block>
          </Block>
          <Row>
            <Col sm={12} md={12} className="">
              <Block className="table-responsive custom-table">
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>{translation.Date}</th>
                      <th>{translation.TransactionType}</th>
                      <th>{translation.FundISIN}</th>
                      <th>{translation.Name}</th>
                      <th>{translation.FundUnits}</th>
                      <th>{translation.FundAmount}</th>
                      <th>{translation.TOTAL}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData}
                  </tbody>
                </Table>
              </Block>
            </Col>
          </Row>
        </Block>
      </Col >
    );
  }
}


TransactionHistoryDetail.propTypes = {
  dispatch: PropTypes.func,
  portfolioId: PropTypes.any,
  translation: PropTypes.any.isRequired,
  userFunds: PropTypes.array
};

TransactionHistoryDetail.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  portfolioId: null,
  userFunds: null,
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    userFunds: state.auth.userFunds,
    translation: state.translation.keys
  })
)(TransactionHistoryDetail);
