import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form, Tooltip } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import RadioGroup from '../../formInputs/RadioGroup';
import CustomRadioGroup from '../../formInputs/RadioGroup/CustomRadioGroup';

const badtooltip = (
  <Tooltip id="badtooltip">
    Very Bad
  </Tooltip>
);
const averagetooltip = (
  <Tooltip id="averagetooltip">
    Average
  </Tooltip>
);
const goodtooltip = (
  <Tooltip id="goodtooltip">
    Very Good
  </Tooltip>
);

class Knowledge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4>{translation.KNOWLEDGE}</h4>

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.legalknowledge}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
             
                <Field
                  name="legalKnowledge"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Bad',
                      text: `${translation.Bad}`,
                      className: 'bad',
                      tooltip: badtooltip
                    }, {
                      value: 'Average',
                      text: `${translation.Average}`,
                      className: 'average',
                      tooltip: averagetooltip
                    },
                    {
                      value: 'Good',
                      text: `${translation.Good}`,
                      className: 'good',
                      tooltip: goodtooltip
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.financialknowledge}</ControlLabel>
              <Block className="d-flex flex-wrap choice-allcation rating-one">
                <Field
                  name="financialKnowledge"
                  component={CustomRadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Bad',
                      text: `${translation.Bad}`,
                      className: 'bad',
                      tooltip: badtooltip
                    }, {
                      value: 'Average',
                      text: `${translation.Average}`,
                      className: 'average',
                      tooltip: averagetooltip
                    },
                    {
                      value: 'Good',
                      text: `${translation.Good}`,
                      className: 'good',
                      tooltip: goodtooltip
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        {formValues && (formValues.civilStatus === 'Married' || formValues.civilStatus === 'Partner') &&
          <Row>
            <Col xs={12} md={6}>
              <Block className="form-group mb-0">
                <ControlLabel>{translation.legalknowledgespouse}</ControlLabel>
                <Block className="d-flex flex-wrap choice-allcation rating-one">
                  <Field
                    name="legalKnowledgeSpouse"
                    component={CustomRadioGroup}
                    className="radio-input mr-4 mb-0"
                    options={
                      [{
                        value: 'Bad',
                        text: `${translation.Bad}`,
                        className: 'bad',
                        tooltip: badtooltip
                      }, {
                        value: 'Average',
                        text: `${translation.Average}`,
                        className: 'average',
                        tooltip: averagetooltip
                      },
                      {
                        value: 'Good',
                        text: `${translation.Good}`,
                        className: 'good',
                        tooltip: goodtooltip
                      }]
                    }
                  />
                </Block>
              </Block>
            </Col>
            <Col xs={12} md={6}>
              <Block className="form-group mb-0">
                <ControlLabel>{translation.Financialknowledgespouse}</ControlLabel>
                <Block className="d-flex flex-wrap choice-allcation rating-one">
                  <Field
                    name="financialKnowledgeSpouse"
                    component={CustomRadioGroup}
                    className="radio-input mr-4 mb-0"
                    options={
                      [{
                        value: 'Bad',
                        text: `${translation.Bad}`,
                        className: 'bad',
                        tooltip: badtooltip
                      }, {
                        value: 'Average',
                        text: `${translation.Average}`,
                        className: 'average',
                        tooltip: averagetooltip
                      },
                      {
                        value: 'Good',
                        text: `${translation.Good}`,
                        className: 'good',
                        tooltip: goodtooltip
                      }]
                    }
                  />
                </Block>
              </Block>
            </Col>
          </Row>
        }

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.appetiterisktakingperformance}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="appetite"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Downpour',
                      text: `${translation.Downpour}`
                    }, {
                      value: 'Way',
                      text: `${translation.Way}`
                    },
                    {
                      value: 'Significant',
                      text: `${translation.Significant}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          {formValues && (formValues.civilStatus === 'Married' || formValues.civilStatus === 'Partner') &&
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.partnerappetiteriskperformance}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="appetiteSpouse"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Downpour',
                      text: `${translation.Downpour}`
                    }, {
                      value: 'Way',
                      text: `${translation.Way}`
                    },
                    {
                      value: 'Significant',
                      text: `${translation.Significant}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          }
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.professionalfinance}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isProfessionalFinance"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Not professional',
                      text: `${translation.Notprofessional}`
                    }, {
                      value: 'Professional',
                      text: `${translation.Professional}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          {formValues && (formValues.civilStatus === 'Married' || formValues.civilStatus === 'Partner') &&
          <Col xs={12} md={6}>
            <Block className="form-group mb-0">
              <ControlLabel>{translation.spousefinanceprofessional}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isProfessionalFinanceSpouse"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Not professional',
                      text: `${translation.Notprofessional}`
                    }, {
                      value: 'Professional',
                      text: `${translation.Professional}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          }
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous} </Button>
          <Button type="submit" className="next">{translation.Next} </Button>
        </Block>
      </Form>
    );
  }
}

Knowledge.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.any
};

Knowledge.defaultProps = {
  formValues: null
};

export default connect(
  state => ({
    // initialValues: state.companies.resendInviteItem,
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(Knowledge));
