import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Svg } from './../../components';

class FormTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkStep = (tab, key) => {
    const { step, validStep } = this.props;
    if (step > tab) {
      if(typeof validStep[key] === 'boolean' && !validStep[key]) {
        return 'invalid-step';
      } else {
        return 'active-step';
      }
    } else if (step === tab) {
      return 'current-step';
    }
    return '';
  };

  goToStep = (step) => {
    this.props.goToPage(step);
  }

  render() {
    const { isSecondSub, isDIY, translation } = this.props;
    return (
      <div className="form-left-side">
        <ul id="progressbar" className="form-steps">
          <li onClick={() => { this.goToStep(1); }} className={this.checkStep(1, 'yourDemand')}>
            <div className="icon-show">
              <i className="material-icons">mail_outline</i>
            </div>
            {translation.YourDemand}
          </li>

          <li onClick={() => { this.goToStep(2); }} className={this.checkStep(2, 'identity')}>
            <div className="icon-show">
              <Svg type="identity" />
            </div>
            {translation.Identity}
          </li>
          {isSecondSub === 'yes' &&
            <li onClick={() => { this.goToStep(3); }} className={this.checkStep(3)}>
              <div className="icon-show">
                <Svg type="identity" />
              </div>
              {translation.CosubscriberIdentity}
            </li>
          }

          <li onClick={() => { this.goToStep(4); }} className={this.checkStep(4)}>
            <div className="icon-show">
              <Svg type="beneficiaries" />
            </div>
            {translation.Beneficiaries}
          </li>

          <li onClick={() => { this.goToStep(5); }} className={this.checkStep(5)}>
            <div className="icon-show">
              <Svg type="citizen" />
            </div>
            {translation.USCitizen}
          </li>

          <li onClick={() => { this.goToStep(6); }} className={this.checkStep(6)}>
            <div className="icon-show">
              <Svg type="political-mandate" />
            </div>
            {/* Political exposure only show the question after US questions */}
            {translation.PoliticalMandate}
          </li>

          <li onClick={() => { this.goToStep(7); }} className={this.checkStep(7)}>
            <div className="icon-show">
              <Svg type="informations" />
            </div>
            {translation.ProfessionalInformations}
          </li>

          <li onClick={() => { this.goToStep(8); }} className={this.checkStep(8)}>
            <div className="icon-show">
              <Svg type="annual-income" />
            </div>
            {translation.Annualincome}
          </li>

          <li onClick={() => { this.goToStep(9); }} className={this.checkStep(9)}>
            <div className="icon-show">
              <Svg type="savings-products" />
            </div>
            {translation.SavingsProducts}
          </li>

          <li onClick={() => { this.goToStep(10); }} className={this.checkStep(10)}>
            <div className="icon-show">
              <Svg type="real-estate" />
            </div>
            {translation.RealEstate}
          </li>

          <li onClick={() => { this.goToStep(11); }} className={this.checkStep(11)}>
            <div className="icon-show">
              <Svg type="debt" />
            </div>
            {translation.Debt}
          </li>


          <li onClick={() => { this.goToStep(12); }} className={this.checkStep(12)}>
            <div className="icon-show">
              <Svg type="knowledge" />
            </div>
            {translation.Knowledge}
          </li>


          <li onClick={() => { this.goToStep(13); }} className={this.checkStep(13)}>
            <div className="icon-show">
              <Svg type="practices" />
            </div>
            {translation.Practices}
          </li>

          <li onClick={() => { this.goToStep(14); }} className={this.checkStep(14)}>
            <div className="icon-show">
              <i className="material-icons">
                all_out
              </i>
            </div>
            {translation.Goals}
          </li>
          {isDIY === false &&
            <li onClick={() => { this.goToStep(15); }} className={this.checkStep(15)}>
              <div className="icon-show">
                <Svg type="asset-allocation" />
              </div>
              {translation.ASSETALLOCATIONCHOICE}
            </li>
          }
          {isDIY === true &&
            <li onClick={() => { this.goToStep(16); }} className={this.checkStep(16)}>
              <div className="icon-show">
                <Svg type="select-fund-icon" />
              </div>
              {translation.SELECTFUNDS}
            </li>
          }

          <li onClick={() => { this.goToStep(17); }} className={this.checkStep(17)}>
            <div className="icon-show">
              <Svg type="charity-svg" />
            </div>
            {translation.Charity}
          </li>
          <li onClick={() => { this.goToStep(18); }} className={this.checkStep(18, 'yourDocuments')}>
            <div className="icon-show">
              <Svg type="your-document" />
            </div>
            {translation.YOURDOCUMENTS}
          </li>
        </ul>
      </div>

    );
  }
}

export default connect(
  state => ({
    translation: state.translation.keys
  })
)(FormTabs);
