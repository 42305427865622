import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Block } from './../../components';

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, translation } = this.props;
    return (
      <Form className="startpage-information-form" onSubmit={handleSubmit}>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group">
              <p>Vous allez remplir un questionnaire en vue de l’ouverture de votre prochain contrat d’Assurance-Vie / de capitalisation. Je vous prie de bien vouloir :</p>
              <ul>
                <li>Préparer les documents nécessaires : pièce d’identité, justificatif de domicile (moins de 3 mois), RIB, toute pièce pouvant justifier de l’origine des fonds ;</li>
                <li>Remplir le questionnaire sans fermer le navigateur. Vos données seraient perdues ;</li>
                <li>Remplir les informations à votre disposition.</li>
              </ul>
              <p>Vous serez contacté sous 24h pour compléter et finaliser votre souscription. Si vous souhaitiez prendre rendez-vous dès maintenant : <a href="https://calendly.com/luxavie/montage-dossier">https://calendly.com/luxavie/montage-dossier</a></p>
            </Block>

          </Col>
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button type="submit" className="next" style={{
            fontFamily: 'neusa_next_stdwide_regular',
          textTransform: "uppercase",
            background: "#293566",
          borderRadius: "0px"
          }}  >Remplir Le Formulaire</Button>
        </Block>

      </Form>
    );
  }
}

StartPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

StartPage.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    initialValues: {
      isSecondSubscriber: 'no',
      gender: 'sir',
      phone: '+33',
      amountToInvest:  0,
      managementType:  'DIY',
      assetAllocationChoice: 'conservative',
      delegateType: 'Multisupports',
      charityFee:  '',
      isCharity: 'no',
    },
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // validate: reduxFormValidator({
  //   amountToInvest: {
  //     required: true,
  //   },
  //   email: {
  //     required: true,
  //     format: 'email',
  //   }
  // }),
})(StartPage));
