import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import superagent from 'superagent';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Button } from 'react-bootstrap';

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Block } from './../../components';
import ArrowIc from '../../style/images/arrow-ic.svg';
import { paymentDetail } from '../../redux/modules/auth';
import constants from '../../constants/constants';

class ThankyouPage extends PureComponent {
  constructor(props) {
    super(props);
    const url2 = new URL(window.location);
    
    const params = new URLSearchParams(url2.search);
    const hostedpageId = params.get('hostedpage_id');
    const charityId = params.get('p');

    let pageFor = 'THANKYOU';
    if (hostedpageId) {
      pageFor = 'PAYMENT';
    } else if (charityId) {
      pageFor = 'CHARITY';
    }

    this.paymentDetail(hostedpageId);
    this.state = {
      hostedpageId,
      page: 1,
      loader: false,
      chartyLs: [],
      charityId,
      charity: {},
      pageFor
    };
  }

  componentDidMount = () => {
    this.charity();
  }

  onChangeIframe = (charityId) => {
    const { chlist } = this.state;
    let charity;
    if (chlist && chlist.length > 0) {
      const index = chlist.findIndex(c => c.post_id === Number(charityId));
      if (index !== -1) {
        charity = chlist[index];
      }

      this.setState({ charity });
    }
  }

  charity = async () => {
    const { translation } = this.props;
    const charitylist = await superagent.get(`${constants.BLOG_URL}wp-json/api/v1/charity`);
    let chlist = JSON.parse(charitylist.text);


    let charity;
    const { charityId } = this.state;
    if (charityId && chlist && chlist.length > 0) {
      const index = chlist.findIndex(c => c.post_id === Number(charityId));
      if (index !== -1) {
        charity = chlist[index];
        chlist = chlist.filter(c => c.post_id !== charity.post_id);
        chlist.unshift(charity);
      }
    }

    const chartyLs = chlist.map(item => (
      <div key="" >
        <img src={item.webdesign.url} alt={item.title} />
        <Button onClick={() => { this.onChangeIframe(item.post_id); }} className="donate-btn">{translation.Donate}</Button>
      </div>
    ));
    this.setState({ chartyLs, chlist, charity });
  }

  paymentDetail = async (hostedpageId) => {
    const { dispatch } = this.props;
    const data = {};
    data.hostedpageId = hostedpageId;
    await dispatch(paymentDetail(data));
  }

  render() {
    const { translation } = this.props;
    const { charity, charityId, chartyLs, pageFor } = this.state;
    return (
      <Block>
        <Header />
        <Block className="sub-header form-page">
          <h1>{translation.ThanksYou} </h1>
        </Block>
        <Block className="gt3_breadcrumb">
          <Block className="container">
            <Block className="breadcrumbs">
              <a href={constants.BLOG_URL}>Home</a>
              <img src={ArrowIc} alt="" />
              <span className="current">{translation.ThanksYou}</span>
            </Block>
          </Block>
        </Block>
        <section className="login-content regration-page wp-form-page">
          <Block className="container" style={{ minHeight: '300px' }}>
            <Block className="d-flex justify-content-center align-items-center sub-page-heading">
              {/* {charityId && <h2>{translation.ThanksCompletecharityProcess}</h2>} */}
              {pageFor === 'THANKYOU' &&
                <h2>{translation.ThankYou}</h2>
              }
              {pageFor === 'PAYMENT' &&
                <h2>{translation.ThankYouPayment}</h2>
              }
              {(pageFor === 'CHARITY' || charityId) &&
                <h2>{translation.ThanksCompletecharityProcess}</h2>
              }
            </Block>
            <Block className="d-flex justify-content-center align-items-center">
              {pageFor === 'THANKYOU' &&
                <h3>{translation.ThankYouText}</h3>
              }
              {pageFor === 'PAYMENT' &&
                <h3>{translation.ThankYouPaymentText}</h3>
              }
              {pageFor === 'CHARITY' &&
                <h3 className="sub-header-h3">{translation.ThankYouCharityText}</h3>
              }
            </Block>


            {pageFor === 'CHARITY' &&
              <Block className="r-slider-com">
                {chartyLs && chartyLs.length > 0 &&
                  <Slider>
                    {chartyLs}
                  </Slider>
                }
              </Block>
            }

            {charity && charity.iframe_url &&
              <Block className="iframe-area" >
                <iframe title={charity && charity.title} width="500" heigh="550" src={charity && charity.iframe_url} />
              </Block>
            }
          </Block>
        </section>
        <Footer />
      </Block>
    );
  }
}

export default connect(
  state => ({
    translation: state.translation.keys
  }))(ThankyouPage);
