import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import userImg from '../../style/images/user.png';

import { Block, Svg } from './../../components';
import NavLink from '../Layout/NavLink';

class AdminLeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMin: false,
      isExpand: ''
    };
  }

  componentWillReceiveProps = (nextProps) => {
    console.log(nextProps);
  }

  handleNavToggle = () => {
    const { navMin } = this.state;
    if (navMin) {
      document.body.classList.remove('sidenav-toggled');
    } else {
      document.body.classList.add('sidenav-toggled');
    }
    this.setState({
      navMin: !navMin
    });
  }

  expandDashboardLi = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: isExpand !== '' ? '' : 'is-expanded'
    });
  }

  expandDashboardLi = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: isExpand !== '' ? '' : 'is-expanded'
    });
  }


  render() {
    const { isExpand } = this.state;
    const { me, translation } = this.props;

    const imgSrc = me && me.avatar ? me.avatar : userImg;
    return (
      <aside className="app-sidebar">

        <a className="app-sidebar__toggle">
          <Svg type="menu-toggle" onClick={this.handleNavToggle} />
        </a>

        <Block className="app-sidebar__user">
          <img className="app-sidebar__user-avatar" src={imgSrc} alt="User" />
          <Block>
            <div className="online-circle">&nbsp;</div>
            {me && me.firstName &&
              <p className="app-sidebar__user-name">{me.firstName}</p>
            }
            {me && me.lastName &&
              <p className="app-sidebar__user-designation">{me.lastName}</p>
            }
          </Block>
        </Block>
        <ul className="app-menu">
          <li className={`treeview ${isExpand}`}>
            <ul className="treeview-menu">
              <li>
                <NavLink type="child" to="/dashboard">{translation.Dashboard} </NavLink>
              </li>
              <li>
                <NavLink type="child" to="/fund-list">{translation.FundList}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/historical-flow">{translation.TransactionHistory}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/detail-holding">{translation.DetailHolding}</NavLink>
              </li>
              <li>
                {/* <a className="treeview-item">Detail by Asset Class</a> */}
                <NavLink type="child" to="/detail-asset">{translation.DetailAssetClass}</NavLink>
              </li>
              <li>
                {/* <a className="treeview-item">Detail by Geographic Countries</a> */}
                <NavLink type="child" to="/detail-geographic-countries">{translation.DetailGeographicCountries}</NavLink>

              </li>
              <li>
                {/* <a className="treeview-item">Detail by Sector</a> */}
                <NavLink type="child" to="/detail-sector">{translation.DetailSector}</NavLink>

              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/admin/config">
              <div className="app-menu__icon config_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.Config}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/change-password">
              <div className="app-menu__icon change_password_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.Changepassword}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/admin-linking">
              <div className="app-menu__icon link_policy_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.LinkPolicy}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/funds">
              <div className="app-menu__icon link_policy_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.ManageFunds}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/risk-profile">
              <div className="app-menu__icon link_policy_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.LinkRiskProfile}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/manage-users">
              <div className="app-menu__icon link_policy_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.ManageUsers}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/manage-user-funds">
              <div className="app-menu__icon link_policy_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.ManageUserFunds}</span>
            </NavLink>
          </li>
        </ul>
        <hr className="menu-divider" />
        <ul className="app-menu">
          <li>
            <a className="app-menu__item">
              <span className="app-menu__label">{translation.Contactus}</span>
            </a>
          </li>


          {/* <li>
            <a className="app-menu__item">
              <span className="app-menu__label">Lost your password</span>
            </a>
          </li> */}

        </ul>
      </aside>
    );
  }
}

AdminLeftMenu.propTypes = {
  me: PropTypes.any,
  translation: PropTypes.any.isRequired
};

AdminLeftMenu.defaultProps = {
  me: null
};

export default connect(
  state => ({
    me: state.auth.user,
    translation: state.translation.keys
  }))(AdminLeftMenu);

