import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';

import { Block } from './../../components';

class PracticesInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={12} className="stretch-card">

        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.PracticesInformation}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.savingsinvestmentproductspast}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.isPastInvestment || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Whatmanagementmethodsknow}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.managementMethods || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.RemarksSectionpossesrelationship}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.practiceRemarks || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.everinvestedEquityfunds}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.investedInEquityFunds || 'N/A'}</span>
              }
            </p>

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.whichinvestmentmedia}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.investmentMedia || 'N/A'}</span>
              }
            </p>

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.yourknowledgeFrenchEuropeancorporatemarkets}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.knowledgeOfFrench || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.oftentrackinvestments}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.howOftenTrack || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.eversuffereddeclinefinancialinvestments}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.sufferedDecline || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.FinancialMarketFallNegativeReturnTypeText}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.financialMarketFall || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.FinancialMarketIncreasePositiveReturnTypeText}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.financialMarketIncrease || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.KindOfSecurities}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.financialSecurities || 'N/A'}</span>
              }
            </p>           
          </Block>

        </Block>
      </Col>

    );
  }
}

PracticesInformation.propTypes = {
  showPracticesModal: PropTypes.func,
  userData: PropTypes.any
};

PracticesInformation.defaultProps = {
  showPracticesModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(PracticesInformation);

