import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'react-bootstrap';
import _ from 'lodash';

import { AuthenticatedUser, Block, PortfolioDetailsTabLink, SelectPortfolio } from './../../components';
import { LeftMenuSidebar, DashboardHeader, PageTitle } from '../../components/Layout';
import {
  getPortfolioHoldingsDetails
} from '../../redux/modules/fund';
import { frenchDate } from '../../utils/common';

class DetailHolding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioHoldingsDetails(portfolioId));
      this.updateData();
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const { dispatch } = this.props;
    if (this.props.portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioHoldingsDetails(nextProps.portfolioId));
      this.updateData();
    }
  }

  updateData = () => {
    const { portfolioHoldingsDetails } = this.props;
    let data;
    // console.log('portfolioHoldingsDetails', portfolioHoldingsDetails);
    if (portfolioHoldingsDetails) {
      data = portfolioHoldingsDetails.map((item) => {
        const valueEur = item.fundValueEur;
        const fundUnits = item.fundUnits;
        const costPrice = item.startFundPrice;
        const fundPerformance = item.fundPerformance;
        const lastPrice = item.fundPrice;
        const lastPriceDate = item.fundPriceDate;
        const eurP = ((lastPrice - costPrice) * fundUnits).toFixed(2);
        const P = (((lastPrice - costPrice) / costPrice) * 100).toFixed(2);
        const Pv = isNaN(P) ? 0 : P;
        const w = ((valueEur / item.totalFundValueEur) * 100).toFixed(2);
        const weight = isNaN(w) ? 0 : w;
        return ({
          holding: item.replicationStrategy,
          name: item.name,
          ISIN: item.fundISIN,
          currency: item.fundValueCurrency,
          assetClass: item.assetClass,
          costPrice: costPrice || '-',
          lastPrice: lastPrice || '-',
          lastPriceDate,
          // value: (lastPrice * fundUnits).toFixed(2),
          value: (item.fundValueEur).toFixed(2),
          perEur: Number(eurP),
          performance: Number(fundPerformance.toFixed(2)),
          computedPerf: Number(Pv),
          weight
        });
      });
    }
    this.setState({
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      data,
      column,
      direction
    } = this.state;

    const { translation } = this.props;

    let tableData;
    if (data) {
      let totalvalue = 0;
      let totalperf = 0;
      let totalWeight = 0;
      tableData = data.map((item) => {
        totalvalue += Number(item.value);
        totalperf += item.perEur;
        totalWeight += Number(item.weight);
        return (
          <tr key={item.id}>
            {/* <td>{item.holding}</td> */}
            <td>{item.name}</td>
            <td>{item.ISIN}</td>
            <td>{item.currency}</td>
            <td>{item.assetClass}</td>
            <td>{item.costPrice}</td>
            <td>{item.lastPrice}</td>
            <td>{frenchDate(item.lastPriceDate)}</td>
            <td>{item.value}</td>
            <td>{item.perEur}</td>
            <td>{item.performance}%</td>
            <td>{item.computedPerf}%</td>
            <td>{item.weight}%</td>
          </tr>
        );
      });
      tableData.push(
        <tr key="total">
          {/* <td>{item.holding}</td> */}
          <td>{translation.TOTAL}</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>{totalvalue.toFixed(2)}</td>
          <td>{totalperf.toFixed(2)}</td>
          <td />
          <td />
          <td>{totalWeight > 99.9 ? (100).toFixed(2) : totalWeight.toFixed(2)}%</td>
        </tr>
      );
    }

    return (
      <AuthenticatedUser>
        <PageTitle title="Detailed Holding" />
        <DashboardHeader />
        <LeftMenuSidebar />
        <Block className="app-content">
          <Row>
            <Col sm={12} md={12}>
              <Block className="tile min-height-cover">
                <Block className="tile-header-part d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.PortfolioDetails}</h3>
                  <SelectPortfolio />
                </Block>
                <PortfolioDetailsTabLink />

                <Block className="d-block mt-4">
                  <Block className="table-responsive custom-table">
                    <Table className="table">
                      <thead>
                        <tr>
                          {/* <th
                            className={column === 'holding' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('holding')}
                          >
                            Holdings
                          </th> */}
                          <th
                            className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('name')}
                          >
                            {translation.Name}
                          </th>
                          <th
                            className={column === 'ISIN' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('ISIN')}
                          >
                            {translation.IsIncode}
                          </th>
                          <th
                            className={column === 'currency' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('currency')}
                          >
                            {translation.Currency}
                          </th>
                          <th
                            className={column === 'assetClass' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('assetClass')}
                          >
                            {translation.AssetClass}
                          </th>
                          <th
                            className={column === 'costPrice' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('costPrice')}
                          >
                            {translation.CostPrice}
                          </th>
                          <th
                            className={column === 'lastPrice' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('lastPrice')}
                          >
                            {translation.LastPrice}
                          </th>
                          <th
                            className={column === 'lastPriceDate' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('lastPriceDate')}
                          >
                            {translation.LastPriceDate}
                          </th>
                          <th
                            className={column === 'value' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('value')}
                          >
                            {translation.Value} (€)
                          </th>
                          <th
                            className={column === 'perEur' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('perEur')}
                          >
                            {translation.Performance} (€)
                          </th>
                          <th
                            className={column === 'performance' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('performance')}
                          >
                            {translation.Performance} (%)
                          </th>
                          <th
                            className={column === 'computedPerf' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('computedPerf')}
                          >
                            {translation.ComputedPerformance} (%)
                          </th>
                          <th
                            className={column === 'weight' ? `sorting ${direction}` : 'sorting'}
                            onClick={this.handleSort('weight')}
                          >
                            {translation.Weighting} (%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData}
                      </tbody>
                    </Table>
                  </Block>
                </Block>
              </Block>
            </Col>
          </Row>
        </Block>
      </AuthenticatedUser>
    );
  }
}

DetailHolding.propTypes = {
  dispatch: PropTypes.func,
  portfolioHoldingsDetails: PropTypes.any,
  portfolioId: PropTypes.any,
  translation: PropTypes.any.isRequired
};

DetailHolding.defaultProps = {
  dispatch: null,
  portfolioHoldingsDetails: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    portfolioHoldingsDetails: state.fund.portfolioHoldingsDetails,
    translation: state.translation.keys
  }))(DetailHolding);
