import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form, Tooltip } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import ChkBox from '../../formInputs/ChkBox';
import RadioGroup from '../../formInputs/RadioGroup';
import TextArea from '../../formInputs/TextArea';

import CustomRadioGroup from '../../formInputs/RadioGroup/CustomRadioGroup';


const lowtooltip = (
  <Tooltip id="lowtooltip">
    Very Bad
  </Tooltip>
);
const averagetooltip = (
  <Tooltip id="averagetooltip">
    Average
  </Tooltip>
);
const goodtooltip = (
  <Tooltip id="goodtooltip">
    Very Good
  </Tooltip>
);

class Practises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;

    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4> {translation.PRACTICESSAVINGSINVESTMENTPRODUCTS}</h4>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.savingsinvestmentproductspast}? </ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isPastInvestment"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        {formValues.isPastInvestment === 'yes' &&
          <React.Fragment>
            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group">
                  <ControlLabel>{translation.Whatmanagementmethodsknow}?</ControlLabel>
                  <Block className="d-flex flex-wrap">
                    <Field
                      name="managementMethods['Autonomous']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Autonomous}
                    />
                    <Field
                      name="managementMethods['Advisory']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Advisory}
                    />
                    <Field
                      name="managementMethods['Under mandate']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Undermandate}
                    />
                  </Block>
                </Block>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Field
                  name="practiceRemarks"
                  component={TextArea}
                  label={translation.RemarksSectionpossesrelationship}
                  placeholder=""
                />
              </Col>
            </Row>

            {/* <h4 className="mt-2">{translation.PRACTICESSAVINGSINVESTMENTPRODUCTS}</h4> */}
            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group mb-0">
                  <ControlLabel>{translation.everinvestedEquityfunds}?</ControlLabel>
                  <Block className="d-flex flex-wrap">
                    <Field
                      name="investedInEquityFunds"
                      component={RadioGroup}
                      className="radio-input mr-4 mb-0"
                      options={
                        [{
                          value: 'Yes, I was satisfied',
                          text: `${translation.YesIsatisfied}`
                        }, {
                          value: 'Yes, I was not satisfied and I was ready to invest again',
                          text: `${translation.YesInotsatisfiedreadyinvestagain}`
                        }, {
                          value: "No, but that's what I'm thinking of now",
                          text: `${translation.NoIthinkingnow}`
                        }]
                      }
                    />
                  </Block>
                </Block>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group">
                  <ControlLabel>{translation.whichinvestmentmedia}?</ControlLabel>
                  <Block className="d-flex flex-wrap">
                    <Field
                      name="investmentMedia['Live shares (live shares)']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Liveshares}
                    />
                    <Field
                      name="investmentMedia['UCITS (SICAV or FCP)']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.UCITS}
                    />
                    <Field
                      name="investmentMedia['AND F']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.ANDF}
                    />
                    <Field
                      name="investmentMedia['FCPI (Mutual Fund for Innovation) or FCPR (Mutual Fund at Risk)']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.FCPIFCPRRisk}
                    />

                    <Field
                      name="investmentMedia['Other (options, alternative management)']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Otheralternativemanagement}
                    />

                    <Field
                      name="investmentMedia['Structured products']"
                      component={ChkBox}
                      className="animated-checkbox mr-4 mb-0"
                      label={translation.Structuredproducts}
                    />

                  </Block>
                </Block>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group mb-0">
                  <ControlLabel>{translation.yourknowledgeFrenchEuropeancorporatemarkets}?</ControlLabel>
                  <Block className="d-flex flex-wrap choice-allcation rating-one">
                    <Field
                      name="knowledgeOfFrench"
                      component={CustomRadioGroup}
                      className="radio-input mr-4 mb-0"
                      options={
                        [{
                          value: 'Low',
                          text: `${translation.Low}`,
                          className: 'bad',
                          tooltip: lowtooltip
                        }, {
                          value: 'Average',
                          text: `${translation.Average}`,
                          className: 'average',
                          tooltip: averagetooltip
                        }, {
                          value: 'Good',
                          text: `${translation.Good}`,
                          className: 'good',
                          tooltip: goodtooltip
                        }]
                      }
                    />
                  </Block>
                </Block>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group mb-0">
                  <ControlLabel>{translation.oftentrackinvestments}?</ControlLabel>
                  <Block className="d-flex flex-wrap">
                    <Field
                      name="howOftenTrack"
                      component={RadioGroup}
                      className="radio-input mr-4 mb-0"
                      options={
                        [{
                          value: 'Weekly',
                          text: `${translation.Weekly}`
                        }, {
                          value: 'Monthly',
                          text: `${translation.Monthly}`
                        }, {
                          value: 'Quarterly',
                          text: `${translation.Quarterly}`
                        }, {
                          value: 'Annually',
                          text: `${translation.Annually}`
                        }]
                      }
                    />
                  </Block>
                </Block>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <Block className="form-group">
                  <ControlLabel>{translation.eversuffereddeclinefinancialinvestments}?</ControlLabel>
                  <Block className="d-flex flex-wrap">
                    <Field
                      name="sufferedDecline"
                      component={RadioGroup}
                      className="radio-input mr-4 mb-0"
                      options={
                        [{
                          value: 'No',
                          text: `${translation.No}`
                        }, {
                          value: 'Yes, a drop of less than 15%',
                          text: `${translation.Yesdroplessthan} 15%`
                        }, {
                          value: 'Yes, a decrease between 15% and 25%',
                          text: `${translation.Yesdecreasebetween}`
                        }, {
                          value: 'Yes, a decrease between 25% and 35%',
                          text: `${translation.Yesdecreasebetween2535}`
                        }, {
                          value: 'Yes, a drop of over 35%',
                          text: `${translation.Yesdropover}`
                        }]
                      }
                    />
                  </Block>
                </Block>
              </Col>
            </Row>
          </React.Fragment>
        }
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group">
              <ControlLabel>{translation.FinancialMarketFallNegativeReturnTypeText}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="financialMarketFall"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: `${translation.Between} 0% et -5%`,
                      text: `${translation.Between} 0% et -5%`
                    }, {
                      value: `${translation.Between} -5% et -20%`,
                      text: `${translation.Between} -5% et -20%`
                    }, {
                      value: `${translation.GreaterThan} -20%`,
                      text: `${translation.GreaterThan} -20%`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group">
              <ControlLabel>{translation.FinancialMarketIncreasePositiveReturnTypeText} </ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="financialMarketIncrease"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: `${translation.Between} 0% et 5%`,
                      text: `${translation.Between} 0% et 5%`
                    }, {
                      value: `${translation.Between} 5% et 20%`,
                      text: `${translation.Between} 5% et 20%`
                    }, {
                      value: `${translation.GreaterThan} 20%`,
                      text: `${translation.GreaterThan} 20%`
                    }]                
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        
        {formValues.isPastInvestment === 'yes' &&
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group">
              <ControlLabel>{translation.KindOfSecurities}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="financialSecurities['in financial securities with a prudent management focus.']"
                  component={ChkBox}
                  className="animated-checkbox mr-4 mb-0"
                  label={translation.KindOfSecuritiesLabel1}
                />
                <Field
                  name="financialSecurities['in financial securities with a balanced management focus.']"
                  component={ChkBox}
                  className="animated-checkbox mr-4 mb-0"
                  label={translation.KindOfSecuritiesLabel2}
                />
                <Field
                  name="financialSecurities['in financial securities with an offensive, complex or sophisticated management orientation.']"
                  component={ChkBox}
                  className="animated-checkbox mr-4 mb-0"
                  label={translation.KindOfSecuritiesLabel3}
                />
              </Block>
            </Block>
          </Col>
        </Row>
        }


        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous} </Button>
          <Button type="submit" className="next"> {translation.Next} </Button>
        </Block>
      </Form>
    );
  }
}

Practises.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  previousPage: PropTypes.func.isRequired,
  formValues: PropTypes.object
};

Practises.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(Practises));
