import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import { Donut } from './../../components/Charts';

class DetailAssetChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  render() {
    const {
      portfolioAssetAllocation,
      translation
    } = this.props;

    const sectorData = portfolioAssetAllocation || [];

    return (
      <Block>
        <p>{translation.Portfolioassetclassbreakdown}</p>
        <Block className="d-block mt-4">
          <Donut data={sectorData} />
        </Block>
      </Block>
    );
  }
}


DetailAssetChart.propTypes = {
  portfolioAssetAllocation: PropTypes.any,
  translation: PropTypes.any.isRequired
};

DetailAssetChart.defaultProps = {
  portfolioAssetAllocation: null,
};

export default connect(
  state => ({
    portfolioAssetAllocation: state.chart.portfolioAssetAllocation,
    translation: state.translation.keys
  })
)(DetailAssetChart);
