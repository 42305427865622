import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues, isValid } from 'redux-form';
import { connect } from 'react-redux';
// import { reduxFormValidator } from 'valirator';
import { combineValidators, isRequired } from 'revalidate';
import PropTypes from 'prop-types';

import RadioGroup from '../../formInputs/RadioGroup';
import TextBox from '../../formInputs/TextBox';
import DropDown from '../../formInputs/DropDown';
import DatePicker from '../../formInputs/DatePicker';
import { Block } from './../../components';

class IdentityStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps){
    this.props.isValid(nextProps.valid)
  }

  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    return (
      <Form className="second-subscriber" onSubmit={handleSubmit}>
        <h4>{translation.YOURIDENTITY}</h4>

        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.Type}</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="gender"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'sir',
                      text: `${translation.Sir}`
                    }, {
                      value: 'madam',
                      text: `${translation.Madam}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

        </Row>

        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>{translation.FirstName} <span className="require red-text">*</span></ControlLabel>
            <Field
              name="firstName"
              component={TextBox}
              placeholder=""
            />
          </Col>

          <Col xs={12} md={4}>
            <ControlLabel>{translation.LastName} <span className="require red-text">*</span></ControlLabel>
            <Field
              name="lastName"
              component={TextBox}
              placeholder=""
            />
          </Col>

          <Col xs={12} md={4}>
            <Block className="calender-div">
              <Field
                name="dateOfBirth"
                component={DatePicker}
                label={translation.DateofBirth}
                placeholder=""
                className="form-control"
                readOnly
                disabledDays={{ after: new Date() }}
              />
              <i className="material-icons">date_range</i>
            </Block>
          </Col>

        </Row>

        <Row>

          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.CiviStatus}</ControlLabel>
              <Field
                name="civilStatus"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Passport Issuing Country"
                component={DropDown}
                options={[
                  { key: 'Single', text: `${translation.Single}`, value: 'Single' },
                  { key: 'Married', text: `${translation.Married}`, value: 'Married' },
                  { key: 'Divorced', text: `${translation.Divorced}`, value: 'Divorced' },
                  { key: 'Widower', text: `${translation.Widower}`, value: 'Widower' },
                  { key: 'Partner', text: `${translation.Partner}`, value: 'Partner' }
                ]}
              />
            </Block>
          </Col>

          {formValues && (formValues.civilStatus === 'Married' || formValues.civilStatus === 'Partner') &&
            <React.Fragment>
              <Col xs={12} md={4}>
                <Block className="form-group">
                  <ControlLabel>{translation.WeddingStatus}</ControlLabel>
                  <Field
                    name="weddingStatus"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage="Passport Issuing Country"
                    component={DropDown}
                    options={[
                      { key: 'one', text: `${translation.Communityreducedtoacquests}`, value: `${translation.Communityreducedtoacquests}` },
                      { key: 'two', text: `${translation.Universalcommunity}`, value: `${translation.Universalcommunity}` },
                      { key: 'three', text: `${translation.Separationofproperty}`, value: `${translation.Separationofproperty}` }
                    ]}
                  />
                </Block>
              </Col>
              <Col xs={12} md={4}>
                <Block className="calender-div">
                  <Field
                    name="weddingSince"
                    component={DatePicker}
                    label={translation.Since}
                    placeholder=""
                    className="form-control"
                    readOnly
                    disabledDays={{ after: new Date() }}
                  />
                  <i className="material-icons">date_range</i>
                </Block>
              </Col>
            </React.Fragment>
          }
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Field
              name="taxtIdNumber"
              component={TextBox}
              label={`${translation.Taxidentificationnumber}`}
              placeholder=""

            />
          </Col>
        </Row>
        
        <Row>
          <Col xs={4} md={3}>
            <Field
              name="streetNumber"
              component={TextBox}
              label={`${translation.Streetnumber}`}
              placeholder=""
            />
          </Col>
          <Col xs={8} md={9}>
            <Field
              name="way"
              component={TextBox}
              label={translation.Voie}
              placeholder=""

            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Field
              name="postalCode"
              component={TextBox}
              label={`${translation.PostalCode}`}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={4}>
            <ControlLabel>{translation.city}</ControlLabel>
            <Field
              name="city"
              component={TextBox}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.Country}</ControlLabel>
              <Field
                name="country"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Country"
                component={DropDown}
                options={[
                  { key: 'France', text: 'France', value: 'France' },
                  { key: 'Angleterre', text: 'Angleterre', value: 'Angleterre' },
                  { key: 'Allemagne', text: 'Allemagne', value: 'Allemagne' },
                  { key: 'Belgique', text: 'Belgique', value: 'Belgique' },
                  { key: 'Espagne', text: 'Espagne', value: 'Espagne' },
                  { key: 'Italie', text: 'Italie', value: 'Italie' },
                  { key: 'Autriche', text: 'Autriche', value: 'Autriche' },
                  { key: 'Luxembourg', text: 'Luxembourg', value: 'Luxembourg' }
                ]}
              />
            </Block>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Field
              name="idCardPassport"
              component={TextBox}
              label={`${translation.IDcard} / ${translation.PassportID}`}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.PassportIssuingCountry}</ControlLabel>
              <Field
                name="passportCountry"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage={translation.PassportIssuingCountry}
                component={DropDown}
                options={[
                  { key: 'France', text: 'France', value: 'France' },
                  { key: 'Angleterre', text: 'Angleterre', value: 'Angleterre' },
                  { key: 'Allemagne', text: 'Allemagne', value: 'Allemagne' },
                  { key: 'Belgique', text: 'Belgique', value: 'Belgique' },
                  { key: 'Espagne', text: 'Espagne', value: 'Espagne' },
                  { key: 'Italie', text: 'Italie', value: 'Italie' },
                  { key: 'Autriche', text: 'Autriche', value: 'Autriche' },
                  { key: 'Luxembourg', text: 'Luxembourg', value: 'Luxembourg' }
                ]}
              />
            </Block>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn">
              <ControlLabel>{translation.Isthereasecondsubscriber}?</ControlLabel>
              <Block className="d-flex">
                <Field
                  name="isSecondSubscriber"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>
      </Form>
    );
  }
}

IdentityStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isvalid: PropTypes.func,
  previousPage: PropTypes.func.isRequired,
  formValues: PropTypes.any,
  translation: PropTypes.any.isRequired
};

IdentityStep.defaultProps = {
  formValues: null
};

const validate = combineValidators({
  firstName: isRequired({ message: 'est requis' }),
  lastName: isRequired({ message: 'est requis' }),
  // civilStatus: isRequired({ message: 'est requis' }),
  // city: isRequired({ message: 'est requis' }),
  // country: isRequired({ message: 'est requis' })
});

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys,
    valid: isValid('signupform')(state),
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(IdentityStep));
