import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { getFormValues } from 'redux-form'
import { Alert, Col} from 'react-bootstrap'
import Header from '../../components/Layout/Header'
// import Footer from '../../components/Layout/Footer';
import { Block } from './../../components'
import { signUp } from '../../redux/modules/auth'
import {
  AnnualIncomeStep,
  AssetAllocationStep,
  BeneficiariesStep,
  CharityStep,
  DebtStep,
  StartPage,
  DemandStep,
  FeeStep,
  FormTabs,
  GoalStep,
  Identity2ndSubscriberStep,
  IdentityStep,
  Knowledge,
  PoliticalMandateStep,
  Practises,
  ProfessionalInformationStep,
  RealEstateStep,
  SavingProductStep,
  SelectFundsStep,
  USCitizenStep,
  YourDocumentsStep
} from '../../components/SignUp'

const defaultValidForm = {
  yourDemand: false,
  identity: false,
  yourDocuments: false
}

class SignUp extends PureComponent {

  newData = new FormData()

  constructor(props) {
    super(props);
    let params = {}

    this.state = {
      page: 0,
      loader: false,
      validStep: { ...defaultValidForm },
      erroMessage: '',
      message: '',
      status: params.status || null
    };
  }

  searchToObject() {
    var pairs = this.props.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === "") continue;

      pair = pairs[i].split("=");
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  componentDidMount = () => {
    this.loadCallpage();
  }

  componentWillUnmount = () => {
    if (this.state.status) {
      this.unloadCallpage();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { formValues } = this.props;
    if (formValues !== nextProps.formValues) {
      this.setState({ erroMessage: '' });
      this.setState({ message: '' });
    }
  }

  loadCallpage = () => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js';
    script.setAttribute('id', 'callpageio-1');
    script.async = false;
    document.head.appendChild(script);
    const __cp = {
      id: '49_K3qm-BM66zdh_iLbwrESsxEVASxcpzb9dzyMNt7s',
      version: '1.1'
    };
    const cp = document.createElement('script');
    cp.setAttribute('id', 'callpageio-2');
    cp.type = 'text/javascript';
    cp.async = false;
    cp.src = '++cdn-widget.callpage.io+build+js+callpage.js'.replace(/[+]/g, '/').replace(/[=]/g, '.');
    const s = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(cp, s);
    } else {
      const { children } = document.head;
      if (children.length) {
        document.head.insertBefore(cp, children[children.length - 1])
      }
    }
    if (window.callpage) {
      // alert('You could have only 1 CallPage code on your website!');
    } else {
      window.callpage = (method) => {
        if (method === '__getQueue') {
          return this.methods;
        } else if (method) {
          if (typeof window.callpage.execute === 'function') {
            // return window.callpage.execute.apply(this, arguments);
          }
          (this.methods = this.methods || []).push({
            // argumentss
          });
        }
      };
      window.callpage.__cp = __cp;
      window.callpage('api.button.autoshow');
    }
  }

  unloadCallpage = () => {
    const callpageio1 = document.getElementById('callpageio-1');
    const callpageio2 = document.getElementById('callpageio-2');
    const callpageWrapper = document.getElementById('callpageWrapper');
    if (callpageio1 && callpageio2) {
      callpageio1.remove();
      callpageio2.remove();
      callpageWrapper.remove();
    }
  }

  onSubmit = formData => {
    console.log("SignUp -> formData", formData)
    const { formValues } = this.props;
    const { page } = this.state;

    if (page === 18) {
      // add attachment to formdata
      // const files = document.getElementById('mi-files').files;
      // if (files) {
      //   if (this.newData.has('attachment')) {
      //     this.newData.set('attachment', files[0]);
      //   } else {
      //     this.newData.append('attachment', files[0]);
      //   }
      // }

      if ((formValues.managementType !== 'DIY' && (formValues.managementType !== 'delegate' || formValues.delegateType !== 'Multisupports')) || !formValues) {
        this.beforeSaveUser(formData)
        this.saveUser(formData)
        return
      }
    }



    this.nextPage(formData)
  }

  nextPage = (formData) => {
    const { formValues } = this.props;
    const { page } = this.state;

    console.log("SignUp -> formValues", formValues)

    const isSecondSub = (formValues && formValues.isSecondSubscriber) ? formValues.isSecondSubscriber : null;
    const isDIY = (formValues && formValues.managementType === 'DIY') || false;
    let add = 1;
    if ((isSecondSub !== 'yes' && page === 2) || (isDIY === false && page === 15) || (isDIY === true && page === 14)) {
      add = 2;
    }
    this.setState({ page: page + add });
    this.feeCalcu();
  }

  getCheckValues = (obj) => {

    const res = [];
    const fields = Object.keys(obj);
    fields.map((field) => {
      if (obj[field] === true) {
        res.push(field);
      }
      return true;
    });
    return res.join();
  }

  getFieldObject = (fieldType) => {
    let r;
    if (fieldType === 'beneficiary') {
      r = {
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        percentage: null
      };
    } else if (fieldType === 'wealth') {
      r = {
        compnayLifeInsurance: null,
        amountLifeInsurance: null,
        fundsLifeInsurance: null,
        yearLifeInsurance: null
      };
    } else if (fieldType === 'PEA') {
      r = {
        totalPayment: null,
        OpeningYear: null
      };
    }
    return r;
  }

  beforeSaveUser = (formData) => {
    console.log(formData)
    if (!this.state.charityErrorM) {
      const fields = Object.keys(formData);
      const data = formData;
      fields.map((field) => {
        if (field.includes('_')) {
          const fieldType = field.split('_')[0];
          const fieldName = field.split('_')[1];
          const fieldKey = Number(field.split('_')[2]) - 1;
          if (!data[`${fieldType}_${fieldKey}`]) {
            data[`${fieldType}_${fieldKey}`] = this.getFieldObject(fieldType);
          }
          if (data[`${fieldType}_${fieldKey}`]) {
            data[`${fieldType}_${fieldKey}`][fieldName] = formData[field];
            delete data[field];
          }
        }
        if (field === 'investmentMedia') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'managementMethods') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'financialSecurities') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'amountTocharity') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'charityToInvest') {
          const tifOptions = [];

          Object.keys(data[field]).map(key =>
            tifOptions.push(`${key}_${data[field][key]}`)
          );
          data.lfcharit = tifOptions;
          delete data[field];
        }

        if (field === 'acharityToInvest') {
          const adtcharity = [];

          Object.keys(data[field]).map(key =>
            adtcharity.push(`${key}_${data[field][key]}`)
          );
          data.aditional = adtcharity;
          delete data[field];
        }
        return true;
      });

      const dataFields = Object.keys(data);
      dataFields.map((field) => {
        if (field.includes('_')) {
          const fieldType = field.split('_')[0];
          if (fieldType === 'beneficiary') {
            if (!data.beneficiaries) {
              data.beneficiaries = [];
            }
            data.beneficiaries.push(data[field]);
          } else {
            if (!data[fieldType]) {
              data[fieldType] = [];
            }
            data[fieldType].push(data[field]);
          }
          delete data[field];
        }
        return true;
      });

      // this.saveUser(data);
    }
  }

  saveUser = async (data) => {
    const { dispatch, translation } = this.props;
    let isError = false,
      isErrorYourDemand = false;
    const formData = data;

    if (!formData.firstName || !formData.lastName) {
      this.setState({ erroMessage: `${translation.ErrorMessageName}` });
      this.isvalid('identity', false);
      isError = true;
    } else {
      this.isvalid('identity', true);
    }

    if (formData.phone && formData.phone.length <= 8) {
      this.setState({ erroMessage: 'numéro de téléphone non valide' });
      isErrorYourDemand = true;
      isError = true;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
      this.setState({ erroMessage: `${translation.ErrorMessageEmailNotValid}` });
      isErrorYourDemand = true;
      isError = true;
    }

    this.isvalid('yourDemand', !isErrorYourDemand);

    if (formData.iAgree !== true) {
      this.setState({ erroMessage: `${translation.ErrorMessageIAgree}` });
      this.isvalid('yourDocuments', false);
      isError = true;
    } else {
      this.isvalid('yourDocuments', true);
    }
    if (formData.iAgree) {
      console.log('it selected');
    }
    if (!formData.iAgree) {
      console.log('not selected');
    }

    if (isError === false) {
      this.setState({ erroMessage: '' });

      try {
        this.setState({ loader: true });
        if (formData.managementType === 'DIY') {
          formData.assetAllocationChoice = null;
          formData.delegateType = null;
        }

        Object.keys(formData).map((key) => {
          if (key === 'attachment') {
            return false;
          }
          console.log(key, formData[key]);

          if (this.newData.has(key)) {
            this.newData.set(key, formData[key]);
          } else {
            this.newData.append(key, formData[key]);
          }
          return true;
        });
        console.log("formdata", formData);

        // return false;
        let object = {};
        this.newData.forEach((value, key) => { object[key] = value });
        const json = JSON.stringify(object);
        console.log("json", json);

        await dispatch(signUp(formData));
        // const link = await dispatch(signUp(this.newData));
        // if(link) {
        //   this.props.history.push(link);
        // }
        // return false;
        // this.props.history.push('');
        // if (Number(formData.LuxavieFee) > 0) {
        //   const pay = await dispatch(signUpPayment(formData, res));
        //   // console.log('resthis =>>', pay.data.hostedpage.url);
        //   this.setState({ loader: false });
        //   this.setState({ message: 'Invoce sent on your email' });
        //   window.location.assign(pay.data.hostedpage.url);
        // } else {
        //   window.location.replace('/thankyou');
        // }
        this.setState({ loader: false });
      } catch (error) {
        console.log("error", error)
        this.setState({ message: error });
        this.setState({ loader: false });
        return error;
      }
      return true;
    }
  }

  previousPage = () => {
    const { formValues } = this.props;
    const { page } = this.state;
    const isSecondSub = (formValues && formValues.isSecondSubscriber) ? formValues.isSecondSubscriber : null;
    const isDIY = (formValues && formValues.managementType === 'DIY') || false;
    let sub = 1;
    if ((isSecondSub !== 'yes' && page === 4) || (isDIY === false && page === 17) || (isDIY === true && page === 16)) {
      sub = 2;
    }
    this.setState({ page: page - sub });
  }

  goToPage = (page) => {
    console.log("goToPage -> this.newData", this.newData, this.state, this.props)
    this.setState({ page });
  }

  feeCalcu = () => {
    const { formValues } = this.props;
    if (formValues.managementType === 'delegate') {
      switch (formValues.assetAllocationChoice) {
        case 'conservative':
          this.setState({ dmf: 290 });
          break;
        case 'defensive':
          this.setState({ dmf: 290 });
          break;
        case 'balanced':
          this.setState({ dmf: 340 });
          break;
        case 'dynamic':
          this.setState({ dmf: 390 });
          break;
        case 'offensive':
          this.setState({ dmf: 440 });
          break;
        case 'flexible':
          this.setState({ dmf: 440 });
          break;
        default:
          this.setState({ dmf: 0 });
          break;
      }
    } else {
      this.setState({ dmf: 0 });
    }
    if (formValues.amountToInvest > 50000 && formValues.amountToInvest < 100000) {
      this.setState({ fee: 450 });
      const licf = (formValues.amountToInvest * 0.65) / 100;
      this.setState({ licf });
    }
    if (formValues.amountToInvest > 99999 && formValues.amountToInvest < 200000) {
      this.setState({ fee: 800 });
      const licf = (formValues.amountToInvest * 0.60) / 100;
      this.setState({ licf });
    }
    if (formValues.amountToInvest > 199999 && formValues.amountToInvest < 300000) {
      this.setState({ fee: 1320 });
      const licf = (formValues.amountToInvest * 0.60) / 100;
      this.setState({ licf });
    }
    if (formValues.amountToInvest > 299999 && formValues.amountToInvest < 500000) {
      this.setState({ fee: 2000 });
      const licf = (formValues.amountToInvest * 0.50) / 100;
      this.setState({ licf });
    }
    return this.state.fee;
  }

  isvalid = (key, valid) => {
    const { validStep: newValidStep } = this.state;

    if (newValidStep[key] === valid) return false

    newValidStep[key] = valid;
    console.log("isvalid -> valid", key, valid);
    this.setState({ validStep: newValidStep });
  }

  render() {
    const { page, loader, validStep, erroMessage, message } = this.state;
    const { formValues, signUpError, translation } = this.props;
    const isSecondSub = (formValues && formValues.isSecondSubscriber) ? formValues.isSecondSubscriber : null;
    const isDIY = (formValues && formValues.managementType === 'DIY') || false;

    return (
      <Block>
        <Header />
        <Block className="sub-header form-page">
          <h1>{translation.RegisterNewAccount}</h1>
        </Block>

        {/* <Block className="gt3_breadcrumb">
          <Block className="container">
            <Block className="breadcrumbs">
              <Link to="/">{translation.home}</Link>
              <img src={ArrowIc} alt="" />
              <span className="current">{translation.registernewaccount}</span>
            </Block>
          </Block>
        </Block> */}

        <section className="login-content regration-page wp-form-page ">
          <Block className="container">
            {page > 0 && <Block className="d-flex justify-content-center mb-5 align-items-center sub-page-heading">
              <div>
                <h2>{translation.Pleasefilltheformbelow}</h2>
              </div>

            </Block>}

            {page > 0 && <Block className="d-flex justify-content-center align-items-center mb-5">
              <div>

                <label>{translation.FormTagLine}</label>
              </div>

            </Block>
            }

            {erroMessage &&
              <Alert bsStyle="danger" className="mt-3 mb-3 common-error">{erroMessage}</Alert>
            }
            {signUpError &&
              <Alert bsStyle="danger" className="mt-3 mb-3 common-error">{signUpError}</Alert>
            }
            {(message && !signUpError) &&
              <Alert variant={typeof message === 'string' ? "primary" : 'danger'} className="mt-3 mb-3 common-error">{typeof message === 'string' ? message : "Unknown error!"}</Alert>
              // <div className="alert alert-primary mt-3 text-align">{typeof this.state.message === 'string' ? this.state.message : "Unknown error!" }</div>
            }
            <Col xs={12} md={6} className="m-auto" >
              {page == 0 && <Block className="d-flex justify-content-center mb-5 align-items-center sub-page-heading">
                <div>
                  <h2>Avant de commencer</h2>
                </div>

              </Block>}
              {page === 0 && <StartPage onSubmit={this.nextPage} />}
            </Col>
            <Block className="jinvest-form form-middle-area">
                {page > 0 && <FormTabs goToPage={this.goToPage} isSecondSub={isSecondSub} isDIY={isDIY} step={page} validStep={validStep} />}
              <Block className="steps-div">
                
                {page === 1 && <DemandStep onSubmit={this.nextPage} isValid={this.isvalid.bind(this, 'yourDemand')} />}
                {page === 2 && <IdentityStep onSubmit={this.nextPage} previousPage={this.previousPage} isValid={this.isvalid.bind(this, 'identity')} />}
                {page === 3 && <Identity2ndSubscriberStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 4 && <BeneficiariesStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 5 && <USCitizenStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 6 && <PoliticalMandateStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 7 &&
                  <ProfessionalInformationStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 8 && <AnnualIncomeStep moveNext={this.nextPage} previousPage={this.previousPage} />}
                {page === 9 && <SavingProductStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 10 && <RealEstateStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 11 && <DebtStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 12 && <Knowledge onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 13 && <Practises onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 14 && <GoalStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 15 && <AssetAllocationStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 16 && <SelectFundsStep moveNext={this.nextPage} previousPage={this.previousPage} />}
                {page === 17 && <CharityStep onSubmit={this.nextPage} previousPage={this.previousPage} />}
                {page === 18 && <YourDocumentsStep onSubmit={this.onSubmit} previousPage={this.previousPage} loader={loader} isValid={this.isvalid.bind(this, 'yourDocuments')} />}
                {page === 19 && <FeeStep saveUser={this.saveUser} previousPage={this.previousPage} loader={loader} />}
                {/* {page === 18 && <h2> licf: {this.state.licf} TF: {this.state.fee} DMF: {this.state.dmf}</h2>} */}
              </Block>
            </Block>
          </Block>
        </section>
      </Block>
    );
  }
}

SignUp.propTypes = {
  formValues: PropTypes.object,
  signUpError: PropTypes.any,
  translation: PropTypes.any.isRequired
};

SignUp.defaultProps = {
  formValues: null,
  signUpError: undefined,
  signUpPayment: undefined
};

export default connect(
  state => ({
    // initialValues: state.companies.resendInviteItem
    formValues: getFormValues('signupform')(state),
    signUpError: state.auth.signUpError,
    signUpPayment: state.auth.signUpPayment,
    translation: state.translation.keys
  }))(SignUp);
