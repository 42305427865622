import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { Row, Table, Col, Form, Button, ControlLabel } from 'react-bootstrap';
import _ from 'lodash';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import { Block } from './../../components';
import { getFundList, setSelectFunds } from '../../redux/modules/fund';
import './SelectFunds.css';

class SelectFundsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null,
      weightError: null,
      value: 10,
      authofill: false,
      numPages: null,
      pageNumber: 1,
      selectedAssetClass: 'All'
    };
  }
  componentDidMount = async () => {
    this.getAllFunds();
    this.updateSelectedFunds();
  }

  getAllFunds = async () => {
    const { dispatch } = this.props;
    await dispatch(getFundList());
  }

  updateSelectedFunds = () => {
    const { signupSelectedFunds } = this.props;
    this.setState({
      selectArray: signupSelectedFunds.newfund
    });
    for (const ky in signupSelectedFunds.weightadd) {
      if (ky) {
        this.setState({
          [`weightadd_${ky}`]: signupSelectedFunds.weightadd[ky]
        });
      }
    }
  }

  selectFund = (ISIN) => {
    const { fundList } = this.props;
    let filterData = fundList;
    filterData = filterData.filter(f => f.ISIN === ISIN);
    const selectArray = this.state.selectArray || [];
    selectArray.push(filterData[0]);
    this.setState({
      selectArray
    });
    this.setState({ authofill: false });
    this.setState({ addnewfund: '' });
  }

  removeFund = (ISIN) => {
    const array = this.state.selectArray;
    const check = array.filter(f => f.ISIN !== ISIN);
    this.setState({
      selectArray: check
    });
    this.setState({ [`weightadd_${ISIN}`]: 0 });
  }

  removeAddedFund = (ISIN) => {
    const array = this.state.data;
    const check = array.filter(f => f.ISIN !== ISIN);
    this.setState({
      data: check
    });
    const filterData = array.filter(f => f.ISIN === ISIN);
    const remove = this.state.remove || [];
    remove.push(filterData[0]);
    this.setState({
      remove
    });
    this.setState({ [`weight_${ISIN}`]: 0 });
  }

  DicWeighage = (id, weight) => {
    let valueUp = this.state[id] || weight;
    valueUp--;
    this.setState({ [id]: valueUp });
  };

  Incweightage = (id, weight) => {
    let valueUpd = this.state[id] || weight;
    valueUpd++;
    if (valueUpd <= 100) {
      this.setState({ [id]: valueUpd });
    }
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  SaveUpdate = async () => {
    this.setState({
      weightError: ''
    });
    let sumWight = 0;
    let weightError = false;
    // console.log('selectArray1', this.state.selectArray);
    const dataArray = {};
    const data = this.state;
    const fields = Object.keys(data);
    fields.map((field) => {
      if (field.includes('_')) {
        const fieldType = field.split('_')[0];
        const fieldName = field.split('_')[1];
        if (fieldType === 'weight') {
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          if (this.state[field] !== 0) {
            dataArray[fieldType][fieldName] = this.state[field];
            sumWight += this.state[field];
          }
        }
        if (fieldType === 'weightadd') {
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          if (this.state[field] !== 0) {
            dataArray[fieldType][fieldName] = this.state[field];
            sumWight += this.state[field];
          }
        }
      }
      return true;
    });
    if (this.state.selectArray) {
      dataArray.newfund = this.state.selectArray;
    }

    if (this.state.remove) {
      dataArray.remove = this.state.remove;
    }
    const { dispatch, portfolioId } = this.props;
    dataArray.portfolioId = portfolioId;
    if (sumWight === 100) {
      await dispatch(setSelectFunds(dataArray));
      // const switchAdd = await dispatch(switchFunds(dataArray));
      // if (switchAdd.status === 'Switch Unblock') {
      //   const path = await dispatch(switchPdf());
      //   this.setState({
      //     filepath: path.filepath,
      //     success: 'Switch request Saved!'
      //   });
      // }
      // if (switchAdd.status === 'Switch Block') {
      //   this.setState({
      //     weightError: 'Switch request is blocked'
      //   });
      // }
    } else {
      weightError = this.props.translation.SumofWeightingshouldbeequalto100;
      this.setState({
        weightError
      });
    }
    let res = false;
    if (weightError === false) {
      res = true;
    }
    return res;
  }

  handleSubmit = async () => {
    const res = await this.SaveUpdate();
    const { moveNext } = this.props;
    if (res === true) {
      moveNext();
    }
  }

  previousPage = async () => {
    const res = await this.SaveUpdate();
    if (res === true || res === false) {
      const { previousPage } = this.props;
      previousPage();
    }
  }


  render() {
    const {
      column,
      direction,
      selectArray,
      weightError
    } = this.state;
    const { handleSubmit, fundList, signupSelectedFunds } = this.props;
    let newfunds;
    if (fundList) {
      newfunds = fundList.map((item) => {
        return ({
          name: item.name,
          ISIN: item.ISIN,
          currency: item.currency,
          assetClass: item.assetClass
        });
      });
    }

    let selectedData;
    const selectedFunds = selectArray || [];
    // console.log('selectArray =>', selectArray);
    if (selectArray || signupSelectedFunds.newfund) {
      selectedData = selectedFunds.map((item) => {
        // console.log(this.state[`weightadd_${item.ISIN}`]);
        return (
          <tr key={item.ISIN}>
            <td>
              {item.name}
              <br />
              <span className="select-fund-isin">{item.ISIN}</span>
            </td>
            {/* <td>{item.ISIN}</td> */}
            <td>
              <div className="custom-range">
                <button
                  className="minus-btn"
                  onClick={() => { this.DicWeighage(`weightadd_${item.ISIN}`, item.weight); }}
                >-</button>
                <input
                  name="expectation2"
                  type="number"
                  value={Number(this.state[`weightadd_${item.ISIN}`] || 0)}
                  className="input-value"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    this.setState({ [`weightadd_${item.ISIN}`]: value > 100 ? 100 : value });
                  }}
                />
                <button
                  className="plus-btn"
                  onClick={() => { this.Incweightage(`weightadd_${item.ISIN}`, 0); }}
                >+</button>
                <InputRange
                  value={this.state[`weightadd_${item.ISIN}`] || 0}
                  maxValue={100}
                  minValue={1}
                  onChange={(value) => {
                    const val = Number(value);
                    this.setState({ [`weightadd_${item.ISIN}`]: val > 100 ? 100 : val });
                  }}
                />
                <button
                  className="remove-btn"
                  onClick={() => { this.removeFund(item.ISIN); }}
                ><i className="fa fa-trash" /></button>
              </div>
            </td>
          </tr>
        );
      });
    }

    let fundData;
    if (newfunds) {
      fundData = newfunds.map((item, index) => {
        const { selectedAssetClass } = this.state;
        const filterData = selectedFunds.filter(i => i.ISIN === item.ISIN);
        if (!filterData.length) {
          if (selectedAssetClass !== 'All' && item.assetClass === selectedAssetClass) {
            return (
              <tr key={`td_${index}`}>
                <td>
                  {item.name}
                  <br />
                  <span className="select-fund-isin">{item.ISIN}</span>
                </td>
                <td>
                  <button onClick={() => { this.selectFund(item.ISIN); }} className="add_fund_btn">+</button>
                </td>
              </tr>
            );
          } else if (selectedAssetClass === 'All') {
            return (
              <tr key={`td_${index}`}>
                <td>
                  {item.name}
                  <br />
                  <span className="select-fund-isin">{item.ISIN}</span>
                </td>
                <td>
                  <button onClick={() => { this.selectFund(item.ISIN); }} className="add_fund_btn">+</button>
                </td>
              </tr>
            );
          }
        }
        return true;
      });
    }

    
    const { translation } = this.props;

    const assetClassOptions = [
      { key: 'Select', text: translation.ShowAll, value: 'All' },
      { key: 'Equity', text: translation.Equity, value: 'Equity' },
      { key: 'Fixed Income', text: translation.FixedIncome, value: 'Fixed Income' },
      { key: 'Commodity', text: translation.Commodity, value: 'Commodity' }
    ];


    return (
      <Block className="">
        <Row>
          <Col sm={12} md={7}>
            <h4>{translation.SELECTFUNDS}</h4>
            <ControlLabel>{translation.SelectAssetClass} &nbsp;</ControlLabel>
            <Dropdown
              placeholder={translation.AssetClass}
              search
              selection
              options={assetClassOptions}
              onChange={(e, { value }) => { this.setState({ selectedAssetClass: value }); }}
            />
          </Col>
          <Col sm={12} md={7}>
            <br />
          
           
          </Col>
        </Row>
        <Row>
          {/* <h4>All Funds</h4> */}
          <Col sm={12} md={5}>
            <Block className="funds-list-outer mb-2">
              <Table>
                <tr>
                  <th>{translation.Name}</th>
                  <th>{translation.Action}</th>
                </tr>
                {fundData}
              </Table>
            </Block>
          </Col>

          {/* <h4>Selected Funds</h4> */}
          <Col sm={12} md={7}>
            <Block className="d-block mb-5 funds-list-outer">
              <Block className="table-responsive custom-table">
                <Table className="table">
                  <thead>
                    <tr>
                      <th
                        className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('name')}
                      >
                        {translation.Name}
                      </th>
                      <th
                        className={column === 'weight' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('weight')}
                      >
                        {translation.Weight}(%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedData}
                  </tbody>
                </Table>
              </Block>
            </Block>

          </Col>
        </Row>

        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Row>
            <Col md={12}>
              {weightError &&
                <Block className="alert alert-danger text-center">
                  {weightError}
                </Block>
              }
            </Col>
          </Row>
          <Block className="form-group btn-container d-flex justify-content-center mt-5">
            <Button type="button" onClick={this.previousPage} className="btn btn-primary btn-block previous">
              {translation.Previous}
            </Button>
            <Button type="submit" className="next">{translation.Next}</Button>
          </Block>
        </Form>

      </Block>
    );
  }
}
SelectFundsStep.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  moveNext: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  fundList: PropTypes.any,
  signupSelectedFunds: PropTypes.any
};

SelectFundsStep.defaultProps = {
  dispatch: null,
  portfolioHoldingsDetails: null,
  fundList: null,
  portfolioId: undefined,
  signupSelectedFunds: null
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    fundList: state.fund.fundList,
    portfolioHoldingsDetails: state.fund.portfolioHoldingsDetails,
    signupSelectedFunds: state.fund.signupSelectedFunds,
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(SelectFundsStep));
