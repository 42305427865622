import { reducer as toastrReducer } from 'react-redux-toastr';

import auth from './modules/auth';
import fund from './modules/fund';
import chart from './modules/chart';
import risk from './modules/risk';
import translation from './modules/translation';
import admin from './modules/admin';

export default {
  auth,
  fund,
  chart,
  risk,
  translation,
  admin,
  toastr: toastrReducer
};
