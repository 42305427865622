import React from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import constants from '../constants/constants';


export const Markup = ({ htmlString, htmlTag, ...rest }) => {
  const custom = rest || {};
  htmlTag = htmlTag || 'div';
  custom.dangerouslySetInnerHTML = { __html: htmlString };
  return React.createElement(htmlTag, custom);
};

// const cryptr = new Cryptr(constants.CRPTYRTOKEN);
const secretkey = constants.CRPTYRTOKEN;

/**
 * return encrypted string
 * @param data
 */
// const cryptData = data => cryptr.encrypt(data);
export const cryptData = (data) => {
  const str = CryptoJS.AES.encrypt(data.toString(), secretkey);
  return str.toString().replace(new RegExp('/', 'g'), '7b9a');
};

/**
 * Returns decrypted data
 * @param data
 */
// const decryptData = data => cryptr.decrypt(data);
// const decryptData = data => CryptoJS.AES.decrypt(data.toString(), secretkey);
export const decryptData = (data) => {
  const d = data.replace(new RegExp('7b9a', 'g'), '/');
  const bytes = CryptoJS.AES.decrypt(d, secretkey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const frenchDate = date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
