import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GeoChart } from 'react-chartkick';

export default class Geo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, colors } = this.props;

    return (
      <GeoChart suffix="%" colors={colors} data={data} />
    );
  }
}

Geo.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array
};

Geo.defaultProps = {
  colors: ['#029ae5', '#029ae5'],
  data: [['United States', 44.2], ['Germany', 23], ['Brazil', 22], ['India', 22]]
};
