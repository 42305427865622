import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Label } from 'react-bootstrap';

import { Block, Modal, Button } from './../../components';
import AddEditFeeForm from './AddEditFeeForm';

class ManageFees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  render() {
    const { userData, userId, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Modal
          title="Edit Fee Form"
          show={this.state.showModal}
          close={() => { this.setState({ showModal: false }); }}
        >
          <AddEditFeeForm
            close={() => { this.setState({ showModal: false }); }}
            userId={userId}
          />
        </Modal>
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.LuxavieFees}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Amounttoinvest}</Label>
              {userData && userData.userinfo && userData.userinfo.amountToInvest &&
                <span>{userData.userinfo.amountToInvest}€</span>
              }
              {userData && userData.userinfo && !userData.userinfo.amountToInvest &&
                <span>N/A</span>
              }

            </p>

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.ManagementTypeText}</Label>
              {userData && userData.userinfo &&
                <span style={{ textTransform: 'uppercase' }}>{userData.userinfo.managementType || 'N/A'}</span>
              }
            </p>
            {userData && userData.userinfo && userData.userinfo.managementType === 'delegate' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.DelegateType}</Label>
                {userData && userData.userinfo &&
                  <span style={{ textTransform: 'uppercase' }}>{userData.userinfo.delegateType || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.managementType === 'delegate' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.ASSETALLOCATIONCHOICE}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.assetAllocationChoice || 'N/A'}</span>
                }
              </p>
            }
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.LuxavieFees}</Label>
              {/* {userData && userData.userinfo &&
                <span>{userData.userinfo.luxavieFee || 'N/A'}</span>
              } */}

              {userData && userData.userinfo && userData.userinfo.luxavieFee &&
                <span>{userData.userinfo.luxavieFee}€</span>
              }
              {userData && userData.userinfo && !userData.userinfo.luxavieFee &&
                <span>N/A</span>
              }
            </p>

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.DelegatedManagementfees}</Label>
              {/* {userData && userData.userinfo &&
                <span>{userData.userinfo.delegateFee || 'N/A'}</span>
              } */}
              {userData && userData.userinfo && userData.userinfo.delegateFee &&
                <span>{userData.userinfo.delegateFee}€</span>
              }
              {userData && userData.userinfo && !userData.userinfo.delegateFee &&
                <span>N/A</span>
              }

            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.CharityDonation}</Label>
              {/* {userData && userData.userinfo &&
                <span>{userData.userinfo.charityFee || 'N/A'}</span>
              } */}

              {userData && userData.userinfo && userData.userinfo.charityFee &&
                <span>{userData.userinfo.charityFee}€</span>
              }
              {userData && userData.userinfo && !userData.userinfo.charityFee &&
                <span>N/A</span>
              }
            </p>
            <Block className="profile-btn-area">
              <Button className="btn" onClick={() => { this.setState({ showModal: true }); }}>Edit Fee</Button>
            </Block>
          </Block>
        </Block>
      </Col>

    );
  }
}

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(ManageFees);
