import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { AuthenticatedUser, Block, Button, Loading } from './../../components';
import { LeftMenuSidebar, DashboardHeader } from '../../components/Layout';
import {
  Description,
  Exposition,
  Identity,
  SectorBreakdown,
  Valuation,
  ChartFile,
  Performance
}
  from '../../components/FundDetail';

import {
  getFundDetail,
  getBenchmarkPerFundDetail,
  getFundDetailPerformance,
  getBenchmarkList
} from '../../redux/modules/fund';
import {
  getFundPerformaceChartData,
  getBenchmarkPerformaceChartData
} from '../../redux/modules/chart';
import { cryptData, decryptData } from './../../utils/common';

class FundDetail extends Component {
  constructor(props) {
    super(props);
    const { location: { pathname } } = this.props;
    const fundId = pathname.split('/')[pathname.split('/').length - 1];
    this.state = {
      fundId,
      benchmarkId: undefined,
      period: '1M'
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const { fundId, period } = this.state;
    await dispatch(getFundDetail(fundId));
    const { fund } = this.props;
    if (fund && fund.id) {
      dispatch(getFundPerformaceChartData(cryptData(fund.id), period));
      dispatch(getFundDetailPerformance(cryptData(fund.id)));
      dispatch(getBenchmarkList());
    }
  }

  getBenchmark = (benchmarkId) => {
    const { dispatch } = this.props;
    const { period } = this.state;
    dispatch(getBenchmarkPerformaceChartData(benchmarkId, period));
    dispatch(getBenchmarkPerFundDetail(benchmarkId));
    this.setState({
      benchmarkId: Number(decryptData(benchmarkId))
    });
  }

  getChart = (period) => {
    const { dispatch, fund } = this.props;
    const { benchmarkId } = this.state;
    dispatch(getFundPerformaceChartData(cryptData(fund.id), period));
    if (benchmarkId) {
      dispatch(getBenchmarkPerformaceChartData(cryptData(benchmarkId), period));
    }
    this.setState({
      period
    });
  }

  render() {
    const { fund, loading } = this.props;
    return (
      <AuthenticatedUser>
        {loading &&
          <Loading />
        }
        <DashboardHeader />
        <LeftMenuSidebar />
        {!loading &&
          <Block className="app-content">
            <Row>
              <Col sm={12} md={12}>
                <Block className="tile page-heading-outer">
                  <Block className="d-flex flex-wrap justify-content-between align-items-center page-heading heading-with-back-link">
                    {fund &&
                      <h1 className="uppercase">{fund.name}</h1>
                    }
                    <Link to="/fund-list" className="cs-back-link">
                      <Button className="btn with-border back-btn">Back to List</Button>
                    </Link>
                  </Block>
                </Block>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} className="stretch-card">
                <Description />
              </Col>
              <Col sm={12} md={8} className="stretch-card">
                <ChartFile getChart={this.getChart} getBenchmark={this.getBenchmark} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} className="stretch-card">
                <Identity />
              </Col>
              <Col sm={12} md={4} className="stretch-card">
                <Exposition />
              </Col>
              <Col sm={12} md={4} className="stretch-card">
                <SectorBreakdown />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} className="stretch-card">
                <Valuation />
              </Col>
              <Col sm={12} md={8} className="stretch-card">
                <Performance benchmarkId={this.state.benchmarkId} />
              </Col>
            </Row>

          </Block>
        }

      </AuthenticatedUser>
    );
  }
}

FundDetail.propTypes = {
  dispatch: PropTypes.func,
  location: PropTypes.object,
  fund: PropTypes.any,
  loading: PropTypes.any
};

FundDetail.defaultProps = {
  dispatch: null,
  location: null,
  fund: null,
  loading: false
};

export default connect(
  state => ({
    fund: state.fund.fundDetails,
    loading: state.fund.fundDetailsBusy
  }))(FundDetail);
