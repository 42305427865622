import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col, ControlLabel, Button, Form, Alert } from 'react-bootstrap';
// import { reduxFormValidator } from 'valirator';
import { combineValidators, isRequired, composeValidators, createValidator } from 'revalidate';
import PropTypes from 'prop-types';
import { createNumberMask } from 'redux-form-input-masks';

import TextBox from '../../formInputs/TextBox';
import PhoneNumber from '../../formInputs/PhoneNumber';
import { Block } from './../../components';
import RadioGroup from '../../formInputs/RadioGroup';

const currencyMask = createNumberMask({
  decimalPlaces: 0
});

class EmailStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '0'
    };
  }

  componentWillReceiveProps(nextProps){
    this.props.isValid(nextProps.valid)
  }

  checkAmount = () => {
    const { formValues } = this.props;
    let amountValueError = false;
    if (formValues.amountToInvest < 50000) {
      amountValueError = true;
    }
    this.setState({ amountValueError });
  }

  handleSubmit = () => {
    const { handleSubmit, formValues } = this.props;
    if (formValues.amountToInvest >0) {
      handleSubmit();
    }
  }

  render() {
    const { handleSubmit, translation, formValues } = this.props;

    return (
      <Form className="first-step" onSubmit={handleSubmit(this.handleSubmit)}>
        <h4>{translation.Yourdemand}</h4>

        <Row className="">
          <Col xs={12} md={6}>
            <ControlLabel>{`${translation.Amounttoinvest} (€)`}<span className="require red-text">*</span></ControlLabel>
            <Block className="curreny-input position-relative">
              <Field
                name="amountToInvest"
                component={TextBox}
                onBlur={this.checkAmount}
                placeholder=""
                {...currencyMask}
              />
              <span className="input-group-addon">€</span>
            </Block>
            {this.state.amountValueError &&
              <Alert bsStyle="warning" className="mt-3 mb-1">{translation.Amountshouldbegreaterthan} 125,000</Alert>
            }
          </Col>

          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn management-type-check">
              <ControlLabel>{translation.Yourmanagementtype}</ControlLabel><span className="require red-text">*</span>
              <Block className="d-flex flex-wrap">
                <Field
                  name="managementType"
                  component={RadioGroup}
                  className="radio-input d-flex text-nowrap mb-0"
                  onChange={this.handleChange}
                  options={
                    [{
                      value: 'DIY',
                      text: `${translation.Doityourself}`
                    }, {
                      value: 'delegate',
                      text: `${translation.Delegateyourassetmanagement}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>
        <Row className="">
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn management-type-check">
              {formValues && formValues.managementType === 'DIY' &&
                <ControlLabel className="signup-form-text">Vous vous apprêtez à souscrire un contrat d’assurance-vie multisupport dont vous assurerez le pilotage</ControlLabel>
              }
              {formValues && formValues.managementType === 'delegate' && formValues.amountToInvest >= 400000 &&
                <Block className="d-flex flex-wrap">
                  <ControlLabel className="signup-form-text">Vous vous apprêtez à souscrire un contrat d’assurance-vie au sein duquel nous piloterons un ou plusieurs FAS</ControlLabel>
                </Block>
              }
            </Block>
          </Col>
        </Row>


        <Row className="">
          <Col xs={12} md={6}>
            <Field
              name="email"
              component={TextBox}
              label={translation.Email}
              placeholder=""
              required
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="phone"
              component={PhoneNumber}
              label={translation.PhoneNumber}
              placeholder={translation.PhoneNumber}
              country="FR"
              required
            />
            
          </Col>
       
          
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

EmailStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isvalid: PropTypes.func,
  formValues: PropTypes.any,
  translation: PropTypes.any.isRequired
};

EmailStep.defaultProps = {
  formValues: null,
};

const urlParams = new URLSearchParams(window.location.search);

const isValidEmail = createValidator(
  message => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return 'email non valid';
    }
  },
  'Invalid Email ID'
);


const isPhoneValid = createValidator(
  message => (value) => {
    if (!value) {
      return 'est requis';
    } else if (value.length <= 8) {
      return 'numéro de téléphone non valide';
    }
  },
  'Invalid Email ID'
);


const validate = combineValidators({
  amountToInvest: isRequired({ message: 'est requis' }),
  phone: composeValidators(
    isRequired({ message: 'est requis' }),
    isPhoneValid
  )(),
  email: composeValidators(
    isRequired({ message: 'est requis' }),
    isValidEmail
  )(),
});


export default connect(
  state => ({
    initialValues: {
      isSecondSubscriber: 'no',
      gender: 'sir',
      phone: '+33',
      amountToInvest: urlParams.get('amount') || 0,
      managementType: urlParams.get('management') || 'DIY',
      assetAllocationChoice: urlParams.get('risk_profile') || 'conservative',
      delegateType: 'FAS',
      charityFee: urlParams.get('charity') || '',
      isCharity: urlParams.get('charity') ? 'yes' : 'no',
    },
    valid: isValid('signupform')(state),
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(EmailStep));
