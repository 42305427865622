import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import DropDown from '../../formInputs/DropDown';
import { Block } from './../../components';
import FieldsBenificiaries from './FieldsBenificiaries';

class BeneficiariesStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    return (
      <Form className="beneficiaries-information-form" onSubmit={handleSubmit}>

        <h4>{translation.BENEFICIARIES}</h4>

        <Row>
          <Col xs={12} md={12}>

            <Block className="form-group">
              <ControlLabel>{translation.Willthebeneficiaryincaseofdeathbe}?</ControlLabel>
              <Field
                name="beneficiary"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="beneficiary"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.TheSubscriber} (s)`, value: 'The Subscriber (s)' },
                  { key: 'two', text: `${translation.Thelegalheirs}`, value: 'The legal heirs' },
                  { key: 'three', text: `${translation.Thesurvivinginsured}`, value: 'The surviving insured' },
                  { key: 'four', text: `${translation.Thespouseisfailingthechildren}`, value: 'The spouse (x) is, failing the children' },
                  { key: 'five', text: `${translation.Other}`, value: 'other' }
                ]}
              />
            </Block>

          </Col>
        </Row>
        {formValues && formValues.beneficiary === 'other' &&
          <Block id="other-beneficiary" className="other-beneficiary">
            <h4>{translation.OtherBENEFICIARIES}</h4>
            <FieldsBenificiaries dispatch={this.props.dispatch} />

            {/* <Row>
            <Col xs={12} md={6}>
              <Field
                name="beneficiaryEmail"
                component={TextBox}
                label="Email"
                placeholder=""
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                name="beneficiaryPhone"
                component={TextBox}
                label="Phone Number"
                placeholder=""
                type="number"
              />
            </Col>
          </Row> */}
          </Block>
        }

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

BeneficiariesStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

BeneficiariesStep.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(BeneficiariesStep));
