import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const btn = ({ className, withRef, onClick, ...props }) => (
  <Button ref={withRef} onClick={onClick} className={className} {...props} />
);

btn.propTypes = {
  withRef: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

btn.defaultProps = {
  className: null,
  onClick: null,
  withRef: null,
};

export default btn;
