import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';

import DropDown from '../../formInputs/DropDown';
// import TextBox from '../../formInputs/TextBox';
import RadioGroup from '../../formInputs/RadioGroup';
// import TextArea from '../../formInputs/TextArea';
import { Block } from './../../components';

class DebtStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: ''
    };
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    return (
      <Form className="annualIncome-information-form" onSubmit={handleSubmit}>
        <h4>{translation.DEBT}</h4>
        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.haveanydebt}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isDebt"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'Yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'No',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
        </Row>

        {formValues.isDebt === 'Yes' &&
          <React.Fragment>
            <Row>
              <Col xs={12} md={4}>
                <Block className="form-group">
                  <ControlLabel>{translation.RemainingCapital}</ControlLabel>
                  <Field
                    name="remainingCapital"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage={translation.RemainingCapital}
                    component={DropDown}

                    options={[
                      { key: 'one', text: `${translation.Lessthan} 100k€`, value: '< 100' },
                      { key: 'two', text: `${translation.Between} 250k€ et 500k€`, value: '250 - 500' },
                      { key: 'three', text: `${translation.Between} 500k€ et 750k€`, value: '500 - 750' },
                      { key: 'four', text: `${translation.Between} 750k€ et 1,2M€`, value: '750 - 1,2M' },
                      { key: 'five', text: `${translation.GreaterThan} 1,2M€`, value: '>1,2M' }
                    ]}
                 
                  />
                </Block>
              </Col>
              <Col xs={12} md={4}>
                <Block className="form-group">
                  <ControlLabel>{translation.AnnualRepayment}</ControlLabel>
                  <Field
                    name="annualRepayment"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage={translation.AnnualRepayment}
                    component={DropDown}
                    options={[
                      { key: 'one', text:  `${translation.Between} 1k€ et 50k€`, value: '1K - 50K' },
                      { key: 'two', text: `${translation.Between} 50k€ et 100k€`, value: '50K - 100K' },
                      { key: 'three', text: `${translation.Between} 100k€ et 140k€`, value: '100K - 140K' },
                      { key: 'four', text: `${translation.GreaterThan} 140k€`, value: '> 140K' }
                    ]}
                   
                  />
                </Block>
              </Col>
              <Col xs={12} md={4}>
                <Block className="form-group">
                  <ControlLabel>{translation.RemainingTime}</ControlLabel>
                  <Field
                    name="remainingTime"
                    search
                    fluid
                    multiple={false}
                    selection
                    selectOnBlur={false}
                    noResultsMessage={translation.RemainingTime}
                    component={DropDown}
                    options={[
                      { key: 'one', text: `1 - 5 ${translation.years}`, value: '1 - 5 years' },
                      { key: 'two', text: `5 - 10 ${translation.years}`, value: '5 - 10 Years' },
                      { key: 'three', text: `10 - 15 ${translation.years}`, value: '10 - 15 years' },
                      { key: 'four', text: `15 - 20 ${translation.years}`, value: '15 - 20 years' },
                      { key: 'five', text: `${translation.GreaterThan} 20 ${translation.years}`, value: 'more than 20 years' }
                    ]}
                  />
                </Block>
              </Col>
            </Row>
          </React.Fragment>
        }

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous} </Button>
          <Button type="submit" className="next">{translation.Next} </Button>
        </Block>

      </Form>
    );
  }
}

DebtStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

DebtStep.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(DebtStep));
