import React from 'react'; // eslint-disable-line
import { ControlLabel, FormGroup } from 'react-bootstrap';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'

const TextBox = ({ input, label, required, meta, ...rest }) => {
  const { touched, error } = meta || {};
  const custom = rest || {};

  if (input && !input.value && custom && custom.disabled && custom.emptyValue) {
    input.value = custom.emptyValue;
  }

  if (custom && custom.emptyValue) {
    delete custom.emptyValue;
  }

  const onChange = (input && input.onChange) || custom.onChange;
  if (custom.onChange) {
    delete custom.onChange;
  }

  const handleChnage = (value) => {
    input.value = value;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormGroup className="contry-phone-list">
      {label && <ControlLabel>{label} {required && <span className="require red-text">*</span>}</ControlLabel>}
      <PhoneInput
        flagUrl='https://flagicons.lipis.dev/flags/4x3/'
        flags={flags}
        value={input.value}
        onChange={handleChnage}
        national={input.value && formatPhoneNumber(input.value, 'National')}
        international={input.value && formatPhoneNumber(input.value, 'International')}
        {...custom}
      />
      {touched && error && <span className="help-block">{touched && error ? error : ''}</span>}
    </FormGroup>
  );
};

export default TextBox;
