import React, { Component } from 'react';

import { AuthenticatedAdmin } from '../../components';

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AuthenticatedAdmin>
        <div>hello</div>
      </AuthenticatedAdmin>
    );
  }
}
