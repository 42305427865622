import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import moment from 'moment';

import { Block, Loading } from './../../components';

class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { fund, fundDetailPerformance, benchmarkPerFundDetail, benchmarkList, benchmarkId, loading } = this.props;
    const year = moment().format('YYYY');
    const oneYear = moment().subtract(1, 'years').format('YYYY');
    const twoYear = moment().subtract(2, 'years').format('YYYY');

    let name;
    if (benchmarkId) {
      name = benchmarkList.filter(b => b.id === benchmarkId)[0].exchnageName;
    }

    return (
      <Block className="tile">
        {loading === true &&
          <Loading />
        }
        <Block className="d-flex justify-content-between align-items-center">
          <h3 className="tile-title">Performance</h3>
        </Block>
        <Block className="table-responsive custom-table">
          <Table className="table table-bordered">

            <thead>
              <tr>
                <th width="30%">Name</th>
                <th width="17.5%">Currency</th>
                <th width="17.5%">
                  {year} ({moment().format('DD-MM-YYYY')})
                </th>
                <th width="17.5%">{oneYear}</th>
                <th width="17.5%">{twoYear}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fund && fund.name ? fund.name : ''}</td>
                <td>{fund && fund.currency ? fund.currency : ''}</td>
                <td>{fundDetailPerformance ? `${fundDetailPerformance[year]}%` : ''}</td>
                <td>{fundDetailPerformance ? `${fundDetailPerformance[oneYear]}%` : ''}</td>
                <td>{fundDetailPerformance ? `${fundDetailPerformance[twoYear]}%` : ''}</td>
              </tr>
              { benchmarkPerFundDetail && Object.keys(benchmarkPerFundDetail).length > 0 &&
              <tr>
                <td>{name}</td>
                <td>EUR</td>
                <td>{benchmarkPerFundDetail ? `${benchmarkPerFundDetail[year]}%` : ''}</td>
                <td>{benchmarkPerFundDetail ? `${benchmarkPerFundDetail[oneYear]}%` : ''}</td>
                <td>{benchmarkPerFundDetail ? `${benchmarkPerFundDetail[twoYear]}%` : ''}</td>
              </tr>
              }
            </tbody>

          </Table>
        </Block>
      </Block>
    );
  }
}

Performance.propTypes = {
  fund: PropTypes.any,
  fundDetailPerformance: PropTypes.any,
  benchmarkPerFundDetail: PropTypes.any,
  benchmarkList: PropTypes.any,
  benchmarkId: PropTypes.any,
  loading: PropTypes.bool,
};

Performance.defaultProps = {
  fund: null,
  fundDetailPerformance: null,
  benchmarkPerFundDetail: null,
  benchmarkList: null,
  benchmarkId: null,
  loading: false,
};

export default connect(
  state => ({
    fund: state.fund.fundDetails,
    fundDetailPerformance: state.fund.fundDetailPerformance,
    benchmarkPerFundDetail: state.fund.benchmarkPerFundDetail,
    loading: state.fund.benchmarkPerFundDetailBusy,
    benchmarkList: state.fund.benchmarkList
  })
)(Performance);

