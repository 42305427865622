import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Table, Row, Form, Button } from 'react-bootstrap';
import { Field, reduxForm, reset } from 'redux-form';
import { reduxFormValidator } from 'valirator';
import moment from 'moment';
import './Transaction.css';

import { Block, SelectPortfolio, Loading, Pagination } from './../../components';
import { userFundsDetail } from '../../redux/modules/auth';
import DatePicker from '../../formInputs/DatePicker';
import { frenchDate } from '../../utils/common';

class TransactionHistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0, // for pagination
      activePage: 1, // for pagination
      pageLimit: 12, // for pagination
      pageData: [] // for pagination
    };
  }

  componentDidMount = () => {
    const { portfolioId } = this.props;
    if (portfolioId) {
      this.userFunds(portfolioId);
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioId !== nextProps.portfolioId) {
      this.userFunds(nextProps.portfolioId);
      this.props.dispatch(reset('filterform'));
    }
  }


  userFunds = async (portfolioId) => {
    const { dispatch } = this.props;
    const res = await dispatch(userFundsDetail(portfolioId, '1M'));
    this.updateData(this.props.userFunds);
    this.setState({
      mainData: this.props.userFunds
    });
    return res;
  }
  
  updateData = (userFunds) => {
    const data = [];
    userFunds.map((item) => {
      const type = item.transactionType;
      let typeCheck = false;
      if (type === 'SWITCH' || type === 'SWITCH_COOLING_OFF') {
        typeCheck = true;
      }
      let transactionType = item.transactionType;
      if (typeCheck) {
        if (type === 'SWITCH' && Number(item.fundUnits) > 0) {
          transactionType = 'BUY';
        } else if (type === 'SWITCH' && Number(item.fundUnits) < 0) {
          transactionType = 'SELL';
        } else {
          transactionType = type.toString().replace(new RegExp('_', 'g'), ' ');
        }
        data.push(
          <tr key={item.transId}>
            <td>{frenchDate(item.fundDate)}</td>
            <td>{transactionType}</td>
            <td>{item.fundAmountEUR}€</td>
          </tr>
        );
      } else {
        transactionType = transactionType.toString().replace(new RegExp('_', 'g'), ' ');
        data.push(
          <tr key={item.transId}>
            <td>{frenchDate(item.fundDate)}</td>
            <td>{transactionType}</td>
            <td>{item.netPremium}€</td>
          </tr>
        );
      }
      return true;
    });

    this.setState({
      data
    }, () => {
      this.updatePageData();
    });
  }

  filterFundList = (formData) => {
    const { mainData } = this.state;
    const { startdate, enddate } = formData;
    const newData = mainData.filter(t =>
      moment(startdate, 'DD-MM-YYYY').isSameOrBefore(t.fundDate) &&
      moment(enddate, 'DD-MM-YYYY').isSameOrAfter(t.fundDate)
    );
    console.log(startdate, enddate);
    this.updateData(newData);
  }

  onPageChange = (e, { activePage }) => {
    this.setState({
      activePage
    }, () => {
      this.updatePageData();
    });
  }

  updatePageData = () => {
    const { activePage, pageLimit, data } = this.state;
    const startIndex = (activePage - 1) * pageLimit;
    const endIndex = (activePage * pageLimit) - 1;
    const pageData = [];
    const totalPages = Math.ceil(data.length / pageLimit);
    data.map((i, key) => {
      if (key >= startIndex && key <= endIndex) {
        pageData.push(i);
      }
      return true;
    });

    this.setState({
      totalPages,
      pageData
    });
  }

  clearForm = () => {
    this.props.dispatch(reset('filterform'));
    const { mainData } = this.state;
    this.updateData(mainData);
  }

  render() {
    const { handleSubmit, userFundsBusy, translation } = this.props;
    const {
      pageData
    } = this.state;

    return (
      <Col sm={12} md={12}>
        <Block className="tile min-height-cover">

          <Block className="history-header-part d-flex justify-content-between">

            <h3 className="tile-title">{translation.HistoricalFlow}</h3>

            <Block className="form-group select-portfolio-history d-flex justify-content-between align-items-center">
              <SelectPortfolio />
            </Block>

            <Block className="tile-header-part d-flex justify-content-between align-items-center mb-4">
              <Form onSubmit={handleSubmit(this.filterFundList)}>
                <Block className="filter-top d-flex justify-content-end align-items-center">
                  <Block className="form-group search-input calender-div start-date">
                    <Field
                      name="startdate"
                      component={DatePicker}
                      label={translation.StartDate}
                      placeholder=""
                      className="form-control"
                      readOnly
                      disabledDays={{ after: new Date() }}
                    />
                    <i className="material-icons">date_range</i>
                  </Block>
                  <Block className="form-group list-form calender-div end_date">
                    <Field
                      name="enddate"
                      component={DatePicker}
                      label={translation.EndDate}
                      placeholder=""
                      className="form-control"
                      readOnly
                      disabledDays={{ after: new Date() }}
                    />
                    <i className="material-icons">date_range</i>
                  </Block>
                  <Block className="form-group search-btn">
                    <Button type="submit" className="btn">{translation.Search}</Button>
                    <Button type="button" className="btn" onClick={this.clearForm}>{translation.Clear}</Button>
                  </Block>
                </Block>
              </Form>
            </Block>

          </Block>

          <Row>
            <Col sm={12} md={12} className="">
              {userFundsBusy &&
                <Loading />
              }
              <Block className="table-responsive custom-table">
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>{translation.Date}</th>
                      <th>{translation.TransactionType}</th>
                      <th>{translation.TOTAL}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData}
                  </tbody>
                </Table>
                {this.state.totalPages > 1 &&
                <Pagination
                  activePage={this.state.activePage}
                  totalPages={this.state.totalPages}
                  onPageChange={this.onPageChange}
                />
                }
              </Block>
              <Block className="text-center">
                {pageData && pageData.length === 0 &&
                  <React.Fragment>
                    <br />
                    <h4>{translation.NoRecordFound}</h4>
                  </React.Fragment>
                }
              </Block>
            </Col>
          </Row>
        </Block>
      </Col>
    );
  }
}


TransactionHistoryDetail.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  portfolioId: PropTypes.any,
  userFunds: PropTypes.array,
  userFundsBusy: PropTypes.any,
  translation: PropTypes.any.isRequired
};

TransactionHistoryDetail.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  portfolioId: null,
  userFunds: null,
  userFundsBusy: false
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    userFunds: state.auth.userFunds,
    userFundsBusy: state.auth.userFundsBusy,
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'filterform',
  // destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    startdate: {
      required: true,
    },
    enddate: {
      required: true,
    }
  }),
})(TransactionHistoryDetail));
