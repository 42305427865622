import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';
import { Block } from './../../components';

class GoalsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    return (
      <Col sm={12} md={12} lg={6} className="col-lg-6 stretch-card">
        <Block className="tile">
          <Block className="personal-profile mt-0 pt-0 no-border">
            <h3 className="tile-title">{translation.GoalsInformation}</h3>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.SearchForCapitalGains}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.capitalGains || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.CreatePrecautionarySaving}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.precautionarySavings || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.Searchforincome}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.searchForIncome || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.PreparationForRetirement}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.forRetirement || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.TransferOfCapital}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.transferAtDeath || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.Wyouexpectyourinvestment}?</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.expectationType || 'N/A'}</p>
              }
            </Block>
            <Block className="d-flex justify-content-between align-items-center info">
              <Label> {translation.SpecifyYourExpectation}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.expectation || 'N/A'}</p>
              }
            </Block>
          
            <Block className="d-flex justify-content-between align-items-center info">
              <Label> {translation.PercentageTotalAssetsDoesThisInvestmentRepresent}?</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.percentageOfAssets || 'N/A'}</p>
              }
            </Block>

            <Block className="d-flex justify-content-between align-items-center info">
              <Label>{translation.ExpectedDuration}?</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.durationOfInvestment || 'N/A'}</p>
              }
            </Block>
          </Block>

        </Block>
      </Col>

    );
  }
}

GoalsProfile.propTypes = {
  showGoalsModal: PropTypes.func,
  userData: PropTypes.any
};

GoalsProfile.defaultProps = {
  showGoalsModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(GoalsProfile);

