import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Row, Col, ControlLabel } from 'react-bootstrap';
import { reduxFormValidator } from 'valirator';
import superagent from 'superagent';
import { createNumberMask } from 'redux-form-input-masks';

import { Block, Button } from './../../components';
import RadioGroup from '../../formInputs/RadioGroup';
import TextBox from '../../formInputs/TextBox';
import constants from '../../constants/constants';

const currencyMask = createNumberMask({
  decimalPlaces: 0
});

class CharityStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charity: undefined
    };
  }

  componentDidMount = () => {
    this.charity();
  }

  charity = async () => {

    const { formValues } = this.props;
    let charityId = 0;
    if (formValues.charityCompany) {
      const charityData = formValues.charityCompany.split('_');
      charityId = charityData[1];
    }
    const charitylist = await superagent.get(`${constants.BLOG_URL}wp-json/api/v1/charity`);
    const chlist = JSON.parse(charitylist.text);
    const chartyLs = chlist.map((item) => {
      if (Number(charityId) === Number(item.post_id)) {
        this.selectCharity(item);
      }
      return (
        <td key={item.post_id} className="text-center">
          <div className="radio-input">
            <div className="ui radio checkbox radio-input mr-4 mb-0">
              <Field
                component="input"
                name="charityCompany"
                type="radio"
                value={`${item.title}_${item.post_id}`}
                onChange={() => { this.selectCharity(item); }}
              />
              <label>{item.title} </label>
            </div>
          </div>
          {/* <img alt="" src={item.webdesign.url} width="120px" /> */}
        </td>
      );
    });
    this.setState({ chartyLs });
  };


  handleChange(formValues) {

    if (formValues.isCharity === 'yes') {
      // alert('form charity not selected');
      formValues.charityFee = 0;
    }

  }

  selectCharity = (charity) => {
    this.setState({ charity });
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    const { charity } = this.state;  
    return (
      <Form className="fee-step" onSubmit={handleSubmit}>
        <h4>Charity</h4>
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn management-type-check">
              <ControlLabel>{translation.Doyouwanttomakeadonation} <small>(1)</small></ControlLabel>

              <Block className="d-flex flex-wrap">
                <Field
                  name="isCharity"
                  component={RadioGroup}
                  className="radio-input d-flex text-nowrap mb-0"
                  onChange={() => { this.handleChange(formValues); }}
                  options={
                    [{
                      value: 'yes',
                      text: translation.Yes
                    }, {
                      value: 'no',
                      text: translation.No
                    }]
                  }
                />
                <small>(1) {translation.CharityText}:</small>

              </Block>
            </Block>

          </Col>

        </Row>

        {formValues && formValues.isCharity === 'yes' &&
          <Row>
            <Col xs={12} md={6}>
              <Block className="form-group new-radio-btn management-type-check">
                <Block className="curreny-input position-relative">
                  <Field
                    name="charityFee"
                    component={TextBox}
                    label={translation.CharityAmount}
                    onBlur={this.checkAmount}
                    placeholder=""
                    {...currencyMask}
                  />
                  <span className="input-group-addon">€</span>
                </Block>
              </Block>
            </Col>
          </Row>
        }
        {formValues && formValues.isCharity === 'yes' &&
          <Block className="calculated-info-area calculated-info-area-charity">
            <h3>{translation.SelectCharity}:</h3>
            <p className="signup-form-text">{translation.Wewillpaythecharityamount}</p>
            <Block className="table-responsive charity-icon-table">
              <table className="table table-bordered">
                {this.state.chartyLs &&
                  this.state.chartyLs
                }
              </table>
            </Block>
          </Block>
        }

        {charity && charity.iframe_url && formValues && formValues.isCharity === 'yes' &&
          <Block className="iframe-area" >
            <iframe title={charity && charity.title} width="500" heigh="550" src={charity && charity.iframe_url} />
          </Block>
        }

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next btn btn-default">{translation.Next}</Button>
        </Block>
      </Form>
    );
  }
}

export default connect(
  state => ({
    // initialValues: { isSecondSubscriber: 'yes', dateOfBirthSecSub: '16-10-2018' },
    formValues: getFormValues('signupform')(state),
    charity: state.auth.charity,
    signupSelectedFunds: state.fund.signupSelectedFunds,
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
  }),
})(CharityStep));

