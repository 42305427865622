import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FreeScrollBar from 'react-free-scrollbar';


import { Block } from './../../components';


class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { fund } = this.props;
    return (
      <Block className="tile">
        <Block className="d-flex justify-content-between align-items-center">
          <h3 className="tile-title">Description</h3>
        </Block>
        <FreeScrollBar
          className="h-350"
          autohide
        >
          <Block className="d-block">
            {fund &&
              <p>{fund.description}</p>
            }
          </Block>
        </FreeScrollBar>
      </Block>
    );
  }
}

Description.propTypes = {
  fund: PropTypes.any
};

Description.defaultProps = {
  fund: null
};

export default connect(
  state => ({
    fund: state.fund.fundDetails
  }))(Description);

