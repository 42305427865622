import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl } from 'react-bootstrap';

import { cryptData, decryptData } from './../../utils/common';
import { Block } from './../../components';

import { setPortfolioId } from '../../redux/modules/auth';
import { getPortfolioList } from '../../redux/modules/fund';

class SelectPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    await dispatch(getPortfolioList());
    const { portfolioList, portfolioId } = this.props;
    if (portfolioList && portfolioList.length && !portfolioId) {
      const pId = portfolioList[0].id;
      dispatch(setPortfolioId(cryptData(pId)));
    }
  }

  handleChnage = (e) => {
    const { dispatch } = this.props;
    const portfolioId = e.target.value;
    dispatch(setPortfolioId(cryptData(portfolioId)));
  }

  render() {
    const {
      portfolioList,
      translation,
      portfolioId
    } = this.props;
    const value = portfolioId ? decryptData(portfolioId) : portfolioId;

    let benchmarkListOps;
    if (portfolioList) {
      benchmarkListOps = portfolioList.map((item) => {
        return (
          <option key={item.id} value={item.id}>{item.policyNumber}</option>
        );
      });
    }

    return (
      <Block className="form-group list-form d-flex justify-content-between align-items-center">
        <ControlLabel>{translation.SelectPortfolio}</ControlLabel>
        <FormControl
          onChange={this.handleChnage}
          componentClass="select"
          placeholder="select"
          className="custom-select"
          value={value}
        >
          {/* <option value={cryptData(0)}>Select</option> */}
          {benchmarkListOps}
        </FormControl>
      </Block>
    );
  }
}


SelectPortfolio.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolioList: PropTypes.any,
  portfolioId: PropTypes.any,
  translation: PropTypes.any.isRequired
};

SelectPortfolio.defaultProps = {
  portfolioList: null,
  portfolioId: undefined,
};

export default connect(
  state => ({
    portfolioList: state.fund.portfolioList,
    portfolioId: state.auth.selectedPortfolioId,
    translation: state.translation.keys
  })
)(SelectPortfolio);
