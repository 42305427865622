const config = {
  development: {
    apiUrl: 'https://api.luxavie.fr'
  },
  production: {
    apiUrl: 'https://api.luxavie.fr'
  },
  staging: {
    apiUrl: 'https://api.luxavie.fr'
  }
};
console.log("REACT_APP_ENV=" + process.env.REACT_APP_ENV);
export default config[process.env.REACT_APP_ENV || 'development'];
