import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { Field } from 'redux-form';

import { Block } from './../../components';
import { setSignupMultiFields } from './../../redux/modules/auth';
import DropDown from '../../formInputs/DropDown';

class FieldsWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.signUpMultiFields.wealth
    };
  }

  addLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows + 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.wealth = rows + 1;
    dispatch(setSignupMultiFields(obj));
  }

  deleteLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows - 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.wealth = rows - 1;
    dispatch(setSignupMultiFields(obj));
  }

  render() {
    const openingYearsOps = [];
    const { translation } = this.props;
    for (let i = 1982; i <= 2018; i++) {
      const r = {
        key: i, text: i, value: i
      };
      openingYearsOps.push(r);
    }
    const { rows } = this.state;
    const fields = [];
    for (let i = 1; i <= rows; i++) {
      fields.push(
        <Row key={i} className="add-row" id="add-row">
          <Col xs={12} md={3}>
            <Block className="form-group">
              <ControlLabel>{translation.InsuranceCompany} </ControlLabel>
              <Field
                name={`wealth_compnayLifeInsurance_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Insurance Company"
                component={DropDown}
                options={[
                  { key: '1', text: 'Assureur', value: 'Assureur' },
                  { key: '2', text: 'Allianz', value: 'Allianz' },
                  { key: '3', text: 'Altaprofits', value: 'Altaprofits' },
                  { key: '4', text: 'Assurancevie.com', value: 'Assurancevie.com' },
                  { key: '5', text: 'Aviva Assurances', value: 'Aviva Assurances' },
                  { key: '6', text: 'AXA', value: 'AXA' },
                  { key: '7', text: 'BforBank', value: 'BforBank' },
                  { key: '8', text: 'BNP Paribas', value: 'BNP Paribas' },
                  { key: '9', text: 'Caisse d’Epargne', value: 'Caisse d’Epargne' },
                  { key: '10', text: 'CNP Assurances', value: 'CNP Assurances' },
                  { key: '11', text: 'Crédit Agricole', value: 'Crédit Agricole' },
                  { key: '12', text: 'Crédit Mutuel', value: 'Crédit Mutuel' },
                  { key: '13', text: 'Direct Assurance', value: 'Direct Assurance' },
                  { key: '14', text: 'Gan Assurances', value: 'Gan Assurances' },
                  { key: '15', text: 'GMF', value: 'GMF' },
                  { key: '16', text: 'Groupama', value: 'Groupama' },
                  { key: '17', text: 'Groupe Rouge', value: 'Groupe Rouge' },
                  { key: '18', text: 'HSBC Assurances', value: 'HSBC Assurances' },
                  { key: '19', text: 'ING Direct – Assurance vie', value: 'ING Direct – Assurance vie' },
                  { key: '20', text: 'Lifeside Patrimoine', value: 'Lifeside Patrimoine' },
                  { key: '21', text: 'MAAF', value: 'MAAF' },
                  { key: '22', text: 'MACIF', value: 'MACIF' },
                  { key: '23', text: 'MAIF', value: 'MAIF' },
                  { key: '24', text: 'Malakoff Médéric', value: 'Malakoff Médéric' },
                  { key: '25', text: 'Marketing Distribution – MD', value: 'Marketing Distribution – MD' },
                  { key: '26', text: 'Matmut', value: 'Matmut' },
                  { key: '27', text: 'MMA', value: 'MMA' },
                  { key: '28', text: 'Monceau Assurances', value: 'Monceau Assurances' },
                  { key: '29', text: 'Natixis Assurances', value: 'Natixis Assurances' },
                  { key: '30', text: 'Nortia', value: 'Nortia' },
                  { key: '31', text: 'Société Générale', value: 'Société Générale' },
                  { key: '32', text: 'SwissLife', value: 'SwissLife' },
                  { key: '33', text: 'SwissLife Direct', value: 'SwissLife Direct' }
                ]}
              />
            </Block>
          </Col>

          <Col xs={12} md={3}>
            <Block className="form-group">
              <ControlLabel>{translation.Amount}</ControlLabel>
              <Field
                name={`wealth_amountLifeInsurance_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Amount"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.Lessthan} 60k€`, value: '< 60K' },
                  { key: 'two', text: `${translation.Between} 60k€ et 100k€`, value: '60K - 100K' },
                  { key: 'three', text: `${translation.Between} 100k€ et 150k€`, value: '100K - 150K' },
                  { key: 'four', text: `${translation.GreaterThan} 150k€`, value: '> 150K' }
                ]}
              />
            </Block>
          </Col>

          <Col xs={12} md={3}>
            <Block className="form-group">
              <ControlLabel>% {translation.Funds} €</ControlLabel>
              <Field
                name={`wealth_fundsLifeInsurance_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Funds"
                component={DropDown}
                options={[
                  { key: 'one', text: '0-30%', value: '0-30%' },
                  { key: 'two', text: '30% - 60%', value: '30% - 60%' },
                  { key: 'three', text: '60% - 100%', value: '60 % - 100 % ' }
                ]}
              />
            </Block>
          </Col>

          <Col xs={12} md={3}>
            <Block className="form-group">
              <ControlLabel>{translation.OpeningYear}</ControlLabel>
              <Field
                name={`wealth_yearLifeInsurance_${i}`}
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Opening Year"
                component={DropDown}
                options={openingYearsOps}
              />
            </Block>
          </Col>

          {i !== 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.deleteLine}>
              <i className="material-icons red-text">remove_circle</i>
            </Block>
          }
          {i === 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.addLine}>
              <i className="material-icons">add_circle</i>
            </Block>
          }
        </Row>
      );
    }
    return (
      <React.Fragment>
        {fields}
      </React.Fragment>
    );
  }
}

FieldsWealth.propTypes = {
  dispatch: PropTypes.func,
  signUpMultiFields: PropTypes.any
};

FieldsWealth.defaultProps = {
  dispatch: null,
  signUpMultiFields: {}
};

export default connect(
  state => ({
    signUpMultiFields: state.auth.signUpMultiFields,
    translation: state.translation.keys
  })
)(FieldsWealth);
