import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Col, Row, Table } from 'react-bootstrap';
import Iframe from 'react-iframe';
import _ from 'lodash';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import TextBox from '../../formInputs/TextBox';
import './NewPortfolioHolding.css';

import { Block, SelectPortfolio } from './../../components';
import {
  getPortfolioHoldingsDetails, switchPdf
} from '../../redux/modules/fund';
import { addFunds, switchFunds } from '../../redux/modules/auth';

class AddPortfolioHolding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null,
      weightError: null,
      value: 10,
      authofill: false,
      numPages: null,
      pageNumber: 1,
      loading: false
    };
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioHoldingsDetails(portfolioId));
      await dispatch(addFunds(portfolioId));
      this.updateData();
      this.getAllFunds();
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const { dispatch } = this.props;
    if (this.props.portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioHoldingsDetails(nextProps.portfolioId));
      await dispatch(addFunds(nextProps.portfolioId));
      this.updateData();
      this.getAllFunds();
    }
  }

  onDocumentLoad({ numPages }) {
    this.setState({ numPages });
  }
  getAllFunds = () => {
    const { addNewFunds } = this.props;
    const newfunds = addNewFunds.map((item) => {
      return ({
        name: item.name,
        ISIN: item.ISIN,
        currency: item.currency,
        assetClass: item.assetClass,
        replicationStrategy: item.replicationStrategy || 'N/A'
      });
    });
    this.setState({
      newfunds,
      datafund: newfunds
    });
    // console.log('newfunds', newfunds);
  }

  fundFilter = (evt) => {
    if (!evt.target.value) {
      this.setState({
        authofill: false
      });
      return;
    }
    let filterData = this.state.datafund;
    filterData = filterData.filter(f => f.name.toLowerCase().includes(evt.target.value.trim()));
    this.setState({
      newfunds: filterData,
      addnewfund: null
    });
    this.setState({ authofill: true });
    console.log('filterData', filterData);
  }
  selectFund = (ISIN) => {
    let filterData = this.state.datafund;
    filterData = filterData.filter(f => f.ISIN === ISIN);
    const selectArray = this.state.selectArray || [];
    selectArray.push(filterData[0]);
    this.setState({
      selectArray,
      [`weightadd_${filterData[0].ISIN}`]: 1,
      authofill: false,
      addnewfund: ''
    }, () => {
      this.SumWeight();
    });
  }

  removeFund = (ISIN) => {
    const array = this.state.selectArray;
    const check = array.filter(f => f.ISIN !== ISIN);
    this.setState({
      selectArray: check
    });
    this.setState({ [`weightadd_${ISIN}`]: 0 });
    this.SumWeight();
  }

  removeAddedFund = (ISIN) => {
    const array = this.state.data;
    const check = array.filter(f => f.ISIN !== ISIN);
    this.setState({
      data: check
    });
    const filterData = array.filter(f => f.ISIN === ISIN);
    const remove = this.state.remove || [];
    remove.push(filterData[0]);
    this.setState({
      remove
    });
    this.setState({ [`weight_${ISIN}`]: 0 });
    console.log('remove', remove);
    this.SumWeight();
  }

  updateData = () => {
    const { portfolioHoldingsDetails } = this.props;
    let data;
    // console.log('portfolioHoldingsDetails', portfolioHoldingsDetails);
    if (portfolioHoldingsDetails) {
      data = portfolioHoldingsDetails.map((item) => {
        const valueEur = item.fundValueEur;
        const fundUnits = item.fundUnits;
        const costPrice = item.startFundPrice;
        const fundPerformance = item.fundPerformance;
        const lastPrice = item.fundPrice;
        const lastPriceDate = item.fundPriceDate;
        const eurP = ((lastPrice - costPrice) * fundUnits).toFixed(2);
        const P = (((lastPrice - costPrice) / costPrice) * 100).toFixed(2);
        const Pv = isNaN(P) ? 0 : P;
        const w = ((valueEur / item.totalFundValueEur) * 100).toFixed(2);
        const weight = isNaN(w) ? 0 : w;
        return ({
          holding: item.replicationStrategy,
          name: item.name,
          ISIN: item.fundISIN,
          currency: item.fundValueCurrency,
          assetClass: item.assetClass,
          costPrice: costPrice || '-',
          lastPrice: lastPrice || '-',
          lastPriceDate,
          // value: (lastPrice * fundUnits).toFixed(2),
          value: (item.fundValueEur).toFixed(2),
          perEur: Number(eurP),
          performance: Number(fundPerformance.toFixed(2)),
          computedPerf: Number(Pv),
          weight
        });
      });
    }
    this.setState({
      data
    }, () => {
      this.SumWeight();
    });
    data.map((item) => {
      this.setState({ [`weight_${item.ISIN}`]: item.weight });
      return true;
    });
  }

  Incweightage = (id, weight) => {
    // let valueUpd = Math.round(this.state[id] || weight);
    let valueUpd = (this.state[id] || this.state[id] >= 0) ?
      this.state[id] : weight;
    valueUpd = Math.round(valueUpd);
    valueUpd++;
    if (valueUpd <= 100) {
      this.setState({ [id]: valueUpd }, () => {
        this.SumWeight();
      });
    }
  };

  SumWeight = async () => {
    let sumWight = 0;
    console.log('selectArray1', this.state.selectArray);
    const dataArray = {};
    const data = this.state;
    const fields = Object.keys(data);
    fields.map((field) => {
      if (field.includes('_')) {
        const fieldType = field.split('_')[0];
        const fieldName = field.split('_')[1];
        if (fieldType === 'weight') {
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          if (this.state[field] !== 0) {
            dataArray[fieldType][fieldName] = this.state[field];
            sumWight += parseFloat(this.state[field]);
          }
        }
        if (fieldType === 'weightadd') {
          console.log('field', field);
          console.log('data', this.state[field]);
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          if (this.state[field] !== 0) {
            dataArray[fieldType][fieldName] = this.state[field];
            sumWight += parseFloat(this.state[field]);
          }
        }
      }
      return true;
    });
    this.setState({
      weightSum: sumWight
    });
  }

  SaveUpdate = async () => {
    this.setState({
      weightError: ''
    });
    let sumWight = 0;
    // console.log('selectArray1', this.state.selectArray);
    const dataArray = {};
    const data = this.state;
    const fields = Object.keys(data);
    fields.map((field) => {
      if (field.includes('_')) {
        const fieldType = field.split('_')[0];
        const fieldName = field.split('_')[1];
        if (fieldType === 'weight') {
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          dataArray[fieldType][fieldName] = this.state[field];
          sumWight += parseFloat(this.state[field]);
        }
        if (fieldType === 'weightadd') {
          console.log('field', field);
          console.log('data', this.state[field]);
          if (!dataArray[fieldType]) {
            dataArray[fieldType] = {};
          }
          dataArray[fieldType][fieldName] = this.state[field];
          sumWight += parseFloat(this.state[field]);
        }
      }
      return true;
    });

    // current portfolio update
    let newPortfolio = [];
    let totalBuy = 0;
    const { portfolioHoldingsDetails } = this.props;
    if (portfolioHoldingsDetails) {
      newPortfolio = portfolioHoldingsDetails.map((item) => {
        const valueEur = item.fundValueEur;
        const w = ((valueEur / item.totalFundValueEur) * 100).toFixed(2);
        const weight = Number(isNaN(w) ? 0 : w);
        const newWeight = Number(dataArray.weight[item.fundISIN]);
        let type = 'NOTHING';
        let changeWeightPer = 0;
        if (weight > newWeight) {
          type = 'SELL';
          changeWeightPer = (newWeight === 0) ? 100 : Math.round((newWeight / weight) * 100);
        } else if (weight < newWeight) {
          type = 'BUY';
          totalBuy += newWeight - weight;
        }
        return ({
          ISIN: item.fundISIN,
          currentWeight: weight,
          newWeight,
          type,
          changeWeightPer
        });
      });
    }

    // new funds
    if (this.state.selectArray && this.state.selectArray.length > 0) {
      this.state.selectArray.map((f) => {
        const item = {
          ISIN: f.ISIN,
          currentWeight: 0,
          newWeight: dataArray.weightadd[f.ISIN],
          type: 'BUY'
        };
        totalBuy += dataArray.weightadd[f.ISIN];
        newPortfolio.push(item);
        return true;
      });
    }

    // calculate buy percentage for pdf
    newPortfolio = newPortfolio.map((item) => {
      let changeWeightPer = item.changeWeightPer;
      if (item.type === 'BUY') {
        changeWeightPer = Math.round(((item.newWeight - item.currentWeight) / totalBuy) * 100);
      }
      return ({
        ISIN: item.ISIN,
        currentWeight: item.currentWeight,
        newWeight: item.newWeight,
        type: item.type,
        changeWeightPer
      });
    });

    dataArray.newPortfolio = newPortfolio;

    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      const dataNew = {
        newPortfolio,
        portfolioId
      };
      console.log('weightportfolioId', sumWight);
      if (Math.round(sumWight) === 100) {
        this.setState({
          loading: true
        });
        const switchAdd = await dispatch(switchFunds(dataNew));
        if (switchAdd.status === 'Switch Unblock') {
          const path = await dispatch(switchPdf({ portfolioId }));
          this.setState({
            filepath: path.filepath,
            success: 'Switch request Saved!'
          });
        }
        if (switchAdd.status === 'Switch Block') {
          this.setState({
            weightError: 'Switch request is blocked'
          });
        }
        this.setState({
          loading: false
        });
      } else {
        const weightError = 'Sum of Weighting should be equal to 100';
        this.setState({
          weightError
        });
      }
    }
  }

  DicWeighage = (id, weight) => {
    let valueUp = (this.state[id] || this.state[id] >= 0) ?
      this.state[id] : weight;
    valueUp = Math.round(valueUp);
    valueUp--;
    if ((valueUp > 0 && id.split('_')[0] === 'weightadd') || (valueUp >= 0 && id.split('_')[0] === 'weight')) {
      this.setState({ [id]: valueUp }, () => {
        this.SumWeight();
      });
    }
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  onValueChnage = (value, item, type = 'weight') => {
    if (value >= 0 && value <= 100) {
      this.setState({ [`${type}_${item.ISIN}`]: Math.round(value) }, () => { this.SumWeight(); });
    }
  }

  render() {
    const {
      data,
      column,
      direction,
      loading
    } = this.state;
    // console.log('fundData', newfunds);
    // const { createSwitchPdf } = this.props;
    // console.log('createSwitchPdf', createSwitchPdf);
    let fundData;
    if (this.state.newfunds) {
      fundData = this.state.newfunds.map((item) => {
        // this.setState({ [`weight_${item.id}`]: item.weight });
        // let fundkey = item.ISIN;
        return (
          <li><button onClick={() => { this.selectFund(item.ISIN); }} >{item.name}</button></li>
        );
      });
      // console.log('fundData', fundData);
    }
    let selectedData;
    if (this.state.selectArray) {
      selectedData = this.state.selectArray.map((item) => {
        console.log('item', item);
        return (
          <tr key={item.ISIN}>
            <td>{item.replicationStrategy}</td>
            <td>{item.name}</td>
            <td>{item.ISIN}</td>
            <td>{item.currency}</td>
            <td>{item.assetClass}</td>
            <td>- </td>
            <td>- </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-%</td>
            <td>-%</td>
            <td>
              <div className="custom-range">
                <button
                  className="minus-btn"
                  onClick={() => { this.DicWeighage(`weightadd_${item.ISIN}`, item.weight); }}
                >-</button>
                <input
                  name="expectation2"
                  component={TextBox}
                  label="Please specify your expectations"
                  placeholder=""
                  value={this.state[`weightadd_${item.ISIN}`] || 0}
                  required
                  className="input-value"
                />
                <button className="plus-btn" onClick={() => { this.Incweightage(`weightadd_${item.ISIN}`, 0); }}>+</button>
                <InputRange
                  maxValue={100}
                  minValue={1}
                  value={this.state[`weightadd_${item.ISIN}`] || 0}
                  onChange={(value) => { this.onValueChnage(value, item, 'weightadd'); }}
                />
                {/* <button className="remove-btn" onClick={() => { this.removeFund(item.ISIN); }} ><i className="fa fa-trash" /></button> */}
              </div>
            </td>
          </tr>
        );
      });
    }

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        const weightValue = (this.state[`weight_${item.ISIN}`] || this.state[`weight_${item.ISIN}`] >= 0) ?
          this.state[`weight_${item.ISIN}`] : item.weight;
        return (
          <tr key={item.ISIN}>
            <td>{item.holding}</td>
            <td>{item.name}</td>
            <td>{item.ISIN}</td>
            <td>{item.currency}</td>
            <td>{item.assetClass}</td>
            <td>{item.costPrice}</td>
            <td>{item.lastPrice}</td>
            <td>{item.lastPriceDate}</td>
            <td>{item.value}</td>
            <td>{item.perEur}</td>
            <td>{item.performance}%</td>
            <td>{item.weight}%</td>
            <td>
              <div className="custom-range">
                <button className="minus-btn" onClick={() => { this.DicWeighage(`weight_${item.ISIN}`, item.weight); }} >-</button>
                <input
                  name="expectation"
                  value={weightValue}
                  required
                  className="input-value"
                  onChange={(e) => { this.onValueChnage(e.target.value, item); }}
                />
                <button className="plus-btn" onClick={() => { this.Incweightage(`weight_${item.ISIN}`, item.weight); }}>+</button>
                <InputRange
                  maxValue={100}
                  minValue={0}
                  value={weightValue}
                  onChange={(value) => { this.onValueChnage(value, item); }}
                />
                {/* <button className="remove-btn" onClick={() => { this.removeAddedFund(item.ISIN); }} ><i className="fa fa-trash" /></button> */}
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <Row>
        <Col sm={12} md={12}>
          <Block className="tile">
            <Block className="tile-header-part d-flex justify-content-between align-items-center">
              <h3 className="tile-title">New Portfolio Holding</h3>
              <Iframe
                url={this.state.filepath}
                width="10px"
                height="10px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />

              <SelectPortfolio />
              {/* <Block className="form-group list-form d-flex justify-content-between align-items-center">
                <ControlLabel>Select a Portfolio</ControlLabel>
                <select className="form-control custom-select">
                  <option>General Investing</option>
                  <option>Option 2</option>
                </select>
              </Block> */}
            </Block>
            {/* <PortfolioDetailsTabLink /> */}

            <Block className="d-block">
              <Block className="table-responsive custom-table">
                <Table className="table">
                  <thead>
                    <tr>
                      <th
                        className={column === 'holding' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('holding')}
                      >
                        Holdings
                      </th>
                      <th
                        className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('name')}
                      >
                        Name
                      </th>
                      <th
                        className={column === 'ISIN' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('ISIN')}
                      >
                        IsIn code
                      </th>
                      <th
                        className={column === 'currency' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('currency')}
                      >
                        Currency
                      </th>
                      <th
                        className={column === 'assetClass' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('assetClass')}
                      >
                        Asset Class
                      </th>
                      <th
                        className={column === 'costPrice' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('costPrice')}
                      >
                        Cost Price
                      </th>
                      <th
                        className={column === 'lastPrice' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('lastPrice')}
                      >
                        Last Price
                      </th>
                      <th
                        className={column === 'lastPriceDate' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('lastPriceDate')}
                      >
                        Last Price Date
                      </th>
                      <th
                        className={column === 'value' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('value')}
                      >
                        Value (€)
                      </th>
                      <th
                        className={column === 'perEur' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('perEur')}
                      >
                        Performance (€)
                      </th>
                      <th
                        className={column === 'performance' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('performance')}
                      >
                        Performance (%)
                      </th>
                      <th
                        className={column === 'weight' ? `sorting ${direction}` : 'sorting'}
                        onClick={this.handleSort('weight')}
                      >
                        Weighting (%)
                      </th>
                      <th>New Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData}
                    {selectedData}
                    <tr>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        -
                      </td>
                      <td>
                        {this.state.weightSum ? Math.round(this.state.weightSum.toFixed(2)) : ''} %
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Block>

              <div className="fund-list-outer">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <input
                      name="addNewfund"
                      component={TextBox}
                      placeholder="Add a funds by entering name"
                      required
                      className="search-value form-control"
                      value={this.state.addnewfund}
                      // onBlur={() => { this.setState({ authofill: false }); }}
                      onChange={this.fundFilter}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 submit-outer">
                    <button
                      disabled={Math.round(this.state.weightSum) !== 100}
                      className="submit-btn ui button btn"
                      onClick={this.SaveUpdate}
                      loading={loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-sm-12 text-center error-show-area">
                    {this.state.weightError &&
                      <div className="alert alert-danger">{this.state.weightError}</div>
                    }

                    {this.state.success &&
                      <div className="alert alert-success">{this.state.success}</div>
                    }
                  </div>
                </div>


                {this.state.authofill &&
                  <ul className="fund-list-dropdown">{fundData}</ul>
                }
              </div>

            </Block>
          </Block>
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    addNewFunds: state.auth.addNewFunds,
    portfolioHoldingsDetails: state.fund.portfolioHoldingsDetails,
    createSwitchPdf: state.fund.createSwitchPdf,
  }))(AddPortfolioHolding);
