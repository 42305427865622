import React, { Component } from 'react';
import { Icon, Pagination } from 'semantic-ui-react';

export default class pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="text-center">
        <br />
        <Pagination
          defaultActivePage={1}
          activePage={this.props.activePage}
          ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={this.props.totalPages}
          onPageChange={this.props.onPageChange}
        />
      </div>
    );
  }
}
