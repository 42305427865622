import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { reduxFormValidator } from 'valirator';
import { Redirect } from 'react-router';
import { Button } from 'react-bootstrap';
import TextBox from '../../formInputs/TextBox';
import { resetPassword } from '../../redux/modules/auth';


class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    this.state = {
      token
    };
  }

  handleResetPassword = (formData) => {
    const { dispatch } = this.props;
    const { token } = this.state;
    const { password, confirmpassword } = formData;
    dispatch(resetPassword(password, confirmpassword, token));
  }

  render() {
    const { handleSubmit, resetPasswordMessage, location } = this.props;
    const { token } = this.state;
    if (!token) {
      return (
        <Redirect to={{
          pathname: '/',
          state: { from: location },
        }}
        />
      );
    }
    return (
      <div className="login-content">
        <div className="logo">
          <h1 className="login-head">Reset your password</h1>
          <h3>Enter a new password for your account.</h3>
        </div>
        <div className="login-box">
          <form className="login-form" onSubmit={handleSubmit(this.handleResetPassword)} >
            <Field
              name="password"
              component={TextBox}
              label="New Password: "
              placeholder="New Password"
              type="password"
            />
            <Field
              name="confirmpassword"
              component={TextBox}
              label="Confirm Password: "
              placeholder="Confirm Password"
              type="password"
            />
            <div className="form-group btn-container d-flex justify-content-center">
              <Button type="submit" className="btn btn-primary btn-block submit-btn">Change Password</Button>
            </div>

            {resetPasswordMessage &&
              <div className="alert alert-primary mt-3 text-align">{resetPasswordMessage}</div>
            }
          </form>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.object,
  resetPasswordMessage: PropTypes.string
};

ResetPassword.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  location: {},
  resetPasswordMessage: undefined
};

export default connect(
  state => ({
    resetPasswordMessage: state.auth.resetPasswordMessage,
    // roles: getRoles(state),
    // translations: getTranslations(state),
  }))(reduxForm({
    form: 'forgetpasswordform',
    enableReinitialize: true,
    validate: reduxFormValidator({
      password: {
        required: true,
        minLength: 6,
      },
      confirmpassword: {
        rules: {
          required: true,
          matchToProperty: 'password',
          minLength: 6,
        }
      },
    }),
  })(ResetPassword));
