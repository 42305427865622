import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { reduxFormValidator } from 'valirator';
import { Col, Row, Form } from 'react-bootstrap';

import TextBox from '../../formInputs/TextBox';
import { Block, Button } from './../../components';
import { updateUserInfo } from '../../redux/modules/auth';
import { getUserData } from '../../redux/modules/admin';


class AddEditFeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleFormSubmit = async (formData) => {
    console.log(formData);
    console.log('formData coming');
    const data = {
      discountedFee: formData.luxavieFee
    };
    const { dispatch, userId } = this.props;
    this.setState({
      loading: true
    });
    const res = await dispatch(updateUserInfo(data, userId));
    if (res) {
      await dispatch(getUserData(userId));
      this.props.close();
    }
    this.setState({
      loading: false
    });
  }

  render() {
    const { handleSubmit, close } = this.props;
    const { loading } = this.state;
    return (

      <Col sm={12} md={12}>
        <Block>

          <Block className="personal-profile-form-inner">
            <Form className="professional-form" onSubmit={handleSubmit(this.handleFormSubmit)}>
              {/* <h4>Fee</h4> */}
              <Row>
                <Col xs={12} md={12}>
                  <Block className="form-group">
                    <Field
                      name="luxavieFee"
                      component={TextBox}
                      label="Luxavie Fee"
                      placeholder=""
                      type="number"
                    />
                  </Block>
                </Col>
              </Row>

              <Block className="profile-btn-area c-modal-footer d-flex justify-content-between">
                <Button type="button" className="btn grey-btn" onClick={close}>Cancel</Button>
                <Button loading={loading} className="btn">Submit</Button>
              </Block>

            </Form>
          </Block>

        </Block>
      </Col>

    );
  }
}

export default connect(
  state => ({
    initialValues: {
      luxavieFee: state.admin.userData ? state.admin.userData.userinfo.discountedFee : '',
    },
    userData: state.admin.userData,
    loading: state.auth.personalProfileBusy,
    formValues: getFormValues('feeForm')(state)
  })
)(reduxForm({
  form: 'feeForm',
  enableReinitialize: true,
  validate: reduxFormValidator({
    luxavieFee: {
      required: true,
      pattern: /^[0-9]+\.?[0-9]{2}$/
    },
    messages: {
      pattern: 'Not valid',
      required: 'Field required'
    }
  }),
})(AddEditFeeForm));

