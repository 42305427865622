const USER_LIST = 'admin/USER_LIST';
const USER_LIST_SUCCESS = 'admin/USER_LIST_SUCCESS';
const USER_LIST_FAIL = 'admin/USER_LIST_FAIL';

const USER_DATA = 'admin/USER_DATA';
const USER_DATA_SUCCESS = 'admin/USER_DATA_SUCCESS';
const USER_DATA_FAIL = 'admin/USER_DATA_FAIL';

const USER_PORTFOLIO_HOLDINGS_DETAILS = 'admin/USER_PORTFOLIO_HOLDINGS_DETAILS';
const USER_PORTFOLIO_HOLDINGS_DETAILS_SUCCESS = 'admin/USER_PORTFOLIO_HOLDINGS_DETAILS_SUCCESS';
const USER_PORTFOLIO_HOLDINGS_DETAILS_FAIL = 'admin/USER_PORTFOLIO_HOLDINGS_DETAILS_FAIL';

const USER_PORTFOLIO_LIST = 'admin/USER_PORTFOLIO_LIST';
const USER_PORTFOLIO_LIST_SUCCESS = 'admin/USER_PORTFOLIO_LIST_SUCCESS';
const USER_PORTFOLIO_LIST_FAIL = 'admin/USER_PORTFOLIO_LIST_FAIL';

// const SWITCH_USER_FUNDS = 'admin/SWITCH_USER_FUNDS';
// const SWITCH_USER_FUNDS_SUCCESS = 'admin/SWITCH_USER_FUNDS_SUCCESS';
// const SWITCH_USER_FUNDS_FAIL = 'admin/SWITCH_USER_FUNDS_FAIL';

const SET_USER_PORTFOLIO_ID = 'admin/SET_USER_PORTFOLIO_ID';

const FLUSH = 'admin/FLUSH';

const initialState = {
  userListBusy: false,
  userListError: false,
  userList: null,
  userDataBusy: false,
  userDataError: false,
  userData: null,
  userPortfolioDetailsBusy: false,
  userPortfolioDetailsError: false,
  userPortfolioDetails: null,
  userPortfolioListBusy: false,
  userPortfolioListError: false,
  userPortfolioList: null,
  userPortfolioId: undefined
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_LIST:
      return { ...state, userListBusy: true, userListError: null };

    case USER_LIST_SUCCESS:
      return { ...state, userListBusy: false, userList: action.res };

    case USER_LIST_FAIL:
      return { ...state, userListBusy: false, userListError: action.error };

    case USER_DATA:
      return { ...state, userDataBusy: true, userDataError: null };

    case USER_DATA_SUCCESS:
      return { ...state, userDataBusy: false, userData: action.res };

    case USER_DATA_FAIL:
      return { ...state, userDataBusy: false, userDataError: action.error };

    case USER_PORTFOLIO_HOLDINGS_DETAILS:
      return { ...state, userPortfolioDetailsBusy: true, userPortfolioDetailsError: null, userPortfolioDetails: [] };

    case USER_PORTFOLIO_HOLDINGS_DETAILS_SUCCESS:
      return { ...state, userPortfolioDetailsBusy: false, userPortfolioDetails: action.res };

    case USER_PORTFOLIO_HOLDINGS_DETAILS_FAIL:
      return { ...state, userPortfolioDetailsBusy: false, userPortfolioDetailsError: action.error };

    case USER_PORTFOLIO_LIST:
      return { ...state, userPortfolioListBusy: true, userPortfolioListError: null, userPortfolioList: [] };

    case USER_PORTFOLIO_LIST_SUCCESS:
      return { ...state, userPortfolioListBusy: false, userPortfolioList: action.res };

    case USER_PORTFOLIO_LIST_FAIL:
      return { ...state, userPortfolioListBusy: false, userPortfolioListError: action.error };

    // case SWITCH_USER_FUNDS:
    //   return { ...state, userPortfolioListBusy: true, userPortfolioListError: null };

    // case SWITCH_USER_FUNDS_SUCCESS:
    //   return { ...state, userPortfolioListBusy: false, userPortfolioList: action.res };

    // case SWITCH_USER_FUNDS_FAIL:
    //   return { ...state, userPortfolioListBusy: false, userPortfolioListError: action.error };

    case SET_USER_PORTFOLIO_ID:
      return { ...state, userPortfolioId: action.res };

    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}

export const getUserList = () => async (dispatch, getState, api) => {
  dispatch({ type: USER_LIST });

  try {
    const res = await api.get('/account/get-users');
    dispatch({ type: USER_LIST_SUCCESS, res });
  } catch (error) {
    dispatch({ type: USER_LIST_FAIL, error });
  }
};

export const getUserData = id => async (dispatch, getState, api) => {
  dispatch({ type: USER_DATA });

  try {
    const res = await api.get(`/account/get-users/${id}`);
    dispatch({ type: USER_DATA_SUCCESS, res });
  } catch (error) {
    dispatch({ type: USER_DATA_FAIL, error });
  }
};

export const getUserPortfolioHoldingsDetails = (portfolioId, userId) => async (dispatch, getState, api) => {
  dispatch({ type: USER_PORTFOLIO_HOLDINGS_DETAILS });

  try {
    const res = await api.get(`/portfolio/${portfolioId}/holdings/${userId}`);
    dispatch({ type: USER_PORTFOLIO_HOLDINGS_DETAILS_SUCCESS, res });
  } catch (error) {
    dispatch({ type: USER_PORTFOLIO_HOLDINGS_DETAILS_FAIL, error });
  }
};

export const getUserPortfolioList = userId => async (dispatch, getState, api) => {
  dispatch({ type: USER_PORTFOLIO_LIST });

  try {
    const res = await api.get(`/portfolios/${userId}`);
    dispatch({ type: USER_PORTFOLIO_LIST_SUCCESS, res });
  } catch (error) {
    dispatch({ type: USER_PORTFOLIO_LIST_FAIL, error });
  }
};

export const setUserPortfolioId = portfolioId => async (dispatch, getState, api) => {
  dispatch({ type: SET_USER_PORTFOLIO_ID, res: portfolioId });
  return true;
};

export const switchUserFunds = (data, userId) => async (dispatch, getState, api) => {
  // dispatch({ type: SWITCH_USER_FUNDS });

  try {
    const res = await api.post(`/account/${userId}/switch-funds`, { data });
    // dispatch({ type: SWITCH_USER_FUNDS_SUCCESS, res });
    return res;
  } catch (err) {
    // dispatch({ type: SWITCH_USER_FUNDS_FAIL, error: err });
  }
};

export const userSwitchFundsPdf = data => async (dispatch, getState, api) => {
  // dispatch({ type: CREATE_SWITCH_PDF });

  try {
    const res = await api.post('/pdf/switch-user-fund', { data });
    // dispatch({ type: CREATE_SWITCH_PDF_SUCCESS, resHere });
    return res;
  } catch (error) {
    console.log(error, 'error here print');
    // dispatch({ type: CREATE_SWITCH_PDF_FAIL, error });
    return false;
  }
};

export const sendUserPaymentLink = userId => async (dispatch, getState, api) => {
  const host = `${window.location.protocol}//${window.location.host}`;
  const data = { userId, host };
  try {
    const res = await api.post('/account/send-payment-link', { data });
    return res;
  } catch (error) {
    console.log(error, 'error here print');
    return false;
  }
};
