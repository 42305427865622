import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';

import { Block } from './../../components';

class AnnualIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.Annualincome}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.ProfessionalIncome}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.professionalIncome || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.SavingsIncome}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.savingIncome || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.OtherIncome}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.otherIncome || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.OtherIncomeNature}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.otherIncomeNature || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.TotalIncome}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.totalIncome || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.professionalincomepercentage} (%)</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.professionalIncomePer || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Savingincomepercentage} (%)</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.savingIncomePer || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Otherincomepercentage} (%)</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.otherIncomePer || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Predictabledevelopments} (%)</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.predictableDevelopments || 'N/A'}</span>
              }
            </p>

          </Block>

        </Block>
      </Col>

    );
  }
}

AnnualIncome.propTypes = {
  showAnnualIncomeModal: PropTypes.func,
  userData: PropTypes.any
};

AnnualIncome.defaultProps = {
  showAnnualIncomeModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(AnnualIncome);

