import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import { AuthenticatedUser, Block, TransactionHistoryDetail } from './../../components';
import { LeftMenuSidebar, DashboardHeader, PageTitle } from '../../components/Layout';


// import { logout } from '../../redux/modules/auth';

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  render() {
    return (
      <AuthenticatedUser>
        <PageTitle title="Transaction History" />
        <DashboardHeader />
        <LeftMenuSidebar />
        <Block className="app-content">
          <Row>
            <TransactionHistoryDetail />
          </Row>
        </Block>
      </AuthenticatedUser>
    );
  }
}

TransactionHistory.propTypes = {
  dispatch: PropTypes.func,
  // me: PropTypes.object
};

TransactionHistory.defaultProps = {
  dispatch: null,
  // me: {}
};

export default connect(
  state => ({
    // me: state.auth.user,
  }))(TransactionHistory);
