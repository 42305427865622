import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import { AuthenticatedUser, Block, FundListTable } from './../../components';
import { LeftMenuSidebar, DashboardHeader, PageTitle } from '../../components/Layout';

import { flushFundDetail } from '../../redux/modules/fund';
import { flushFundDetailChart } from '../../redux/modules/chart';


import { logout } from '../../redux/modules/auth';

class FundList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { fundDetails, dispatch } = this.props;
    if (fundDetails) {
      dispatch(flushFundDetail());
      dispatch(flushFundDetailChart());
    }
  }

  logout = () => {
    const { dispatch } = this.props;
    dispatch(logout());
  }
  render() {
    return (
      <AuthenticatedUser>
        <PageTitle title="Fund List" />
        <DashboardHeader />
        <LeftMenuSidebar />
        <Block className="app-content">
          <Row>
            <FundListTable />
          </Row>
        </Block>
      </AuthenticatedUser>
    );
  }
}

FundList.propTypes = {
  dispatch: PropTypes.func,
  fundDetails: PropTypes.any
};

FundList.defaultProps = {
  dispatch: null,
  fundDetails: null
};

export default connect(
  state => ({
    fundDetails: state.fund.fundDetails,
  }))(FundList);
