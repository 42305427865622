import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactChartkick, { LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';

ReactChartkick.addAdapter(Highcharts);

export default class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, colors } = this.props;
    // console.log('data =>>', data);
    return (
      <LineChart curve={false} suffix="%" min={null} colors={colors} data={data} />
    );
  }
}

Line.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array
};

Line.defaultProps = {
  colors: ['#029ae5', '#ff917f'],
  data: [
    {
      name: 'Performace',
      data: {
        jan: -20,
        feb: 10,
        mar: 30,
        apr: 9,
        may: 6,
        jun: 2,
        jul: -1
      }
    },
    {
      name: 'benchmark',
      data: {
        jan: 1,
        feb: 3,
        mar: 20,
        apr: 5,
        may: 8,
        jun: 4,
        jul: 9
      }
    }
  ]
};

