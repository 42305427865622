import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, unregisterField } from 'redux-form';

import { Block } from './../../components';
import { setSignupMultiFields } from './../../redux/modules/auth';
import DatePicker from '../../formInputs/DatePicker';
import TextBox from '../../formInputs/TextBox';

class FieldsBenificiaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.signUpMultiFields.beneficiary
    };
  }

  addLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows + 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.beneficiary = rows + 1;
    dispatch(setSignupMultiFields(obj));
  }

  deleteLine = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows - 1
    });
    const { dispatch, signUpMultiFields } = this.props;
    const obj = signUpMultiFields;
    obj.beneficiary = rows - 1;
    dispatch(setSignupMultiFields(obj));
    dispatch(unregisterField('signupform', 'beneficiary_FirstName_2'));
  }

  render() {
    const { rows } = this.state;
    const { translation } = this.props;
    const fields = [];
    for (let i = 1; i <= rows; i++) {
      fields.push(
        <Row key={i} className="add-row">
          <Col xs={12} md={3}>
            <Field
              name={`beneficiary_firstName_${i}`}
              component={TextBox}
              label={`${translation.FirstName}`}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={3}>
            <Field
              name={`beneficiary_lastName_${i}`}
              component={TextBox}
              label={`${translation.LastName}`}
              placeholder=""
            />
          </Col>
          <Col xs={12} md={3}>
            <Block className="calender-div">
              <Field
                name={`beneficiary_dateOfBirth_${i}`}
                component={DatePicker}
                label={`${translation.DateofBirth}`}
                placeholder=""
                className="form-control"
              />
              <i className="material-icons">date_range</i>
            </Block>
          </Col>
          <Col xs={12} md={3}>
            <span className="input-right-ic">%</span>
            <Field
              name={`beneficiary_percentage_${i}`}
              component={TextBox}
              label={`${translation.Percentage}`}
              placeholder=""
              type="number"
            />
          </Col>

          {i !== 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.deleteLine}>
              <i className="material-icons red-text">remove_circle</i>
            </Block>
          }
          {i === 1 &&
            <Block className="add-more-row saving-repeat-1" onClick={this.addLine}>
              <i className="material-icons">add_circle</i>
            </Block>
          }
        </Row>
      );
    }
    return (
      <React.Fragment>
        {fields}
      </React.Fragment>
    );
  }
}

FieldsBenificiaries.propTypes = {
  dispatch: PropTypes.func,
  translation: PropTypes.any.isRequired,
  signUpMultiFields: PropTypes.any
};

FieldsBenificiaries.defaultProps = {
  dispatch: null,
  signUpMultiFields: {}
};

export default connect(
  state => ({
    signUpMultiFields: state.auth.signUpMultiFields,
    translation: state.translation.keys
  })
)(FieldsBenificiaries);
