import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import userImg from '../../style/images/user.png';

import { Block, Svg } from './../../components';
import NavLink from './NavLink';
import { getUserData } from '../../redux/modules/auth';

class LeftMenuSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: 'is-expanded',
      width: window.innerWidth
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    // window.addEventListener('resize', this.updateWindowDimensions);
    dispatch(getUserData());
    this.closeExpanded();
  }

  componentWillReceiveProps = (nextProps) => {
    console.log(nextProps);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth
    });
  }

  closeExpanded = () => {
    const pathname = window.location.pathname;
    const pathArr = [
      '/access-to-profile',
      '/change-password'
    ];
    if (pathArr.includes(pathname)) {
      this.setState({ isExpand: '' });
    }
  }

  handleNavToggle = () => {
    const bodyClasses = document.getElementsByClassName('sidenav-toggled');
    if (bodyClasses.length > 0) {
      document.body.classList.remove('sidenav-toggled');
    } else {
      document.body.classList.add('sidenav-toggled');
    }
  }

  expandDashboardLi = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: isExpand !== '' ? '' : 'is-expanded'
    });
  }

  expandDashboardLi = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: isExpand !== '' ? '' : 'is-expanded'
    });
  }


  render() {
    const { isExpand } = this.state;
    const { userData, translation } = this.props;
    const { me } = this.props;

    console.log('me', userData);

    const imgSrc = me && me.avatar ? me.avatar : userImg;
    return (
      <aside className="app-sidebar">

        <a className="app-sidebar__toggle">
          <Svg type="menu-toggle" onClick={this.handleNavToggle} />
        </a>

        <Block className="app-sidebar__user">
          <Block className="left-profile-img">
            <img className="app-sidebar__user-avatar" src={imgSrc} alt="User" />
          </Block>

          <Block>
            <div className="online-circle">&nbsp;</div>
            {me && me.firstName &&
              <p className="app-sidebar__user-name">{me.firstName}</p>
            }
            {me && me.lastName &&
              <p className="app-sidebar__user-designation">{me.lastName}</p>
            }
            {userData && userData.userinfo.managementType &&
              <p className="app-sidebar__user-designation">{userData.userinfo.managementType}</p>
            }
          </Block>
        </Block>
        <ul className="app-menu">
          <li className={`treeview ${isExpand}`}>
            <a className="app-menu__item" onClick={this.expandDashboardLi}>
              <div className="app-menu__icon">
                <Svg type="dashboard" />
              </div>
              <span className="app-menu__label">{translation.dashboard}</span>
              <i className="treeview-indicator fa fa-angle-right">&nbsp;</i>
            </a>
            <ul className="treeview-menu">
              <li>
                <NavLink type="child" to="/dashboard">{translation.dashboard}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/detail-holding">{translation.detailByHolding}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/past-operations">{translation.pastOperations}</NavLink>
              </li>
              {userData && userData.userinfo && userData.userinfo.managementType === 'DIY' &&
              <li>
                {/* <a className="treeview-item">Detail by Sector</a> */}
                <NavLink type="child" to="/switchfunds">{translation.switchFunds}</NavLink>
              </li>
              }
              
              {userData && userData.userinfo && userData.userinfo.managementType === 'DIY' &&
              <li>
                <NavLink type="child" to="/fund-list">{translation.fundList}</NavLink>
              </li>
              }
              <li>
                <NavLink type="child" to="/historical-flow">{translation.historicalFlow}</NavLink>
              </li>
              
              {/* <li>
                <NavLink type="child" to="/detail-asset">{translation.detailByAssetClass}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/detail-geographic-countries">{translation.detailByGeographicCountries}</NavLink>
              </li>
              <li>
                <NavLink type="child" to="/detail-sector">{translation.detailBySector}</NavLink>
              </li> */}
            </ul>
          </li>

          <li>
            <NavLink to="/access-to-profile">
              <div className="app-menu__icon access_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.accessToProfile}</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink className="app-menu__item" to="#">
              <div className="app-menu__icon billing_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.Billing}</span>
            </NavLink>
          </li>

          <li>
            <a className="app-menu__item">
              <div className="app-menu__icon documents_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.documents}</span>
            </a>
          </li> */}

          <li>
            <NavLink className="app-menu__item" to="/change-password">
              <div className="app-menu__icon change_password_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.changePassword}</span>
            </NavLink>
          </li>

          {/* <li>
            <a className="app-menu__item">
              <div className="app-menu__icon refer_friend_ic">&nbsp;</div>
              <span className="app-menu__label">{translation.referAFriend}</span>
            </a>
          </li> */}

        </ul>
        <hr className="menu-divider" />
        <ul className="app-menu">
          <li>
            <a className="app-menu__item">
              <span className="app-menu__label">{translation.contactUs}</span>
            </a>
          </li>

          {/* <li>
            <a className="app-menu__item">
              <span className="app-menu__label">Lost your password</span>
            </a>
          </li> */}

        </ul>
      </aside>
    );
  }
}

LeftMenuSidebar.propTypes = {
  dispatch: PropTypes.func,
  userData: PropTypes.any,
  me: PropTypes.any,
  translation: PropTypes.any.isRequired
};

LeftMenuSidebar.defaultProps = {
  dispatch: null,
  userData: null,
  me: null
};

export default connect(
  state => ({
    me: state.auth.user,
    userData: state.auth.userData,
    translation: state.translation.keys
  }))(LeftMenuSidebar);

