import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactChartkick, { PieChart } from 'react-chartkick';
import Highcharts from 'highcharts';

ReactChartkick.addAdapter(Highcharts);

export default class Donut extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, colors } = this.props;
    return (
      <PieChart suffix="%" colors={colors} donut data={data} />
    );
  }
}

Donut.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array
};

Donut.defaultProps = {
  data: [['stock', 44], ['other', 23]],
  colors: ['#0399e3', '#43bb43', '#56c5fb', '#ffac2a', '#ff5d8a', '#6610f2',
    '#f06261', '#a958a5', '#3f7c9d', '#9d82fc', '#e3f28e']
};
