import translations from '../../translations';

const TRANSLATION = 'translation/TRANSLATION';

const initialState = {
  keys: translations.FR 
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TRANSLATION:
      return { ...state, keys: action.translation };
    default:
      return state;
  }
}


export const getTranslation = (lang = 'FR') => async (dispatch, getState, api) => {
  if (translations[lang]) {
    dispatch({ type: TRANSLATION, keys: translations[lang] });
  }
};
