import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Table } from 'react-bootstrap';
import { Block } from './../../components';

class Benchmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    this.updateData(this.props.portfolioAssetAllocation);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioAssetAllocation !== nextProps.portfolioAssetAllocation) {
      this.updateData(nextProps.portfolioAssetAllocation);
    }
  }

  updateData = (portfolioAssetAllocation) => {
    let data;
    if (portfolioAssetAllocation) {
      data = portfolioAssetAllocation.map((item) => {
        return ({
          name: item[0],
          stock: item[1],
        });
      });
    }
    this.setState({
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      currentPortfolio
    } = this.props;
    const {
      data,
      column,
      direction
    } = this.state;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        return (
          <tr>
            <td>{item.name}</td>
            <td>{item.stock}%</td>
          </tr>
        );
      });
    }

    return (
      <Block className="d-block">
        <Block className="table-responsive custom-table table-heading-bg">
          <Table className="table">
            <thead>
              <tr>
                <th
                  className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('name')}
                >
                  Asset
                </th>
                <th
                  className={column === 'stock' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('stock')}
                >
                  {currentPortfolio.name}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </Table>
        </Block>
      </Block>
    );
  }
}


Benchmark.propTypes = {
  dispatch: PropTypes.func,
  portfolioAssetAllocation: PropTypes.any,
  currentPortfolio: PropTypes.any,
  portfolioId: PropTypes.any
};

Benchmark.defaultProps = {
  dispatch: null,
  portfolioAssetAllocation: null,
  currentPortfolio: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioAssetAllocation: state.chart.portfolioAssetAllocation,
    portfolioId: state.fund.portfolioId,
    currentPortfolio: state.fund.currentPortfolio,
  }))(Benchmark);

