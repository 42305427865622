import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';
import { Block } from './../../components';

class ProfessionalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.PROFESSIONALINFORMATIONS} </h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Areyouretired}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.isRetired || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.ProfessionalActivity}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.professionalActivity || 'N/A'}</span>
              }
            </p>
            {userData && userData.userinfo && userData.userinfo.isRetired === 'no' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Descriptionoftheprofession}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.professionDescription || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isRetired === 'no' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Exerciseprofessionperson}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.professionExercise || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isRetired === 'no' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Nameofcompany}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.companyName || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinf && userData.userinfo.professionExercise === 'Physical' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.NameofEmployer}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.employerName || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isRetired === 'no' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Activityarea}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.activityArea || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.professionExercise !== 'Physical' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Turnover}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.turnover || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isRetired === 'no' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.Numberofemployees}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.numberOfEmployees || 'N/A'}</span>
                }
              </p>
            }

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.practicingyourprofessionalactivity} </Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.yearsOfPracticing || 'N/A'}</span>
              }
            </p>

          </Block>

        </Block>
      </Col>

    );
  }
}

ProfessionalInformation.propTypes = {
  showProfessionalModal: PropTypes.func,
  userData: PropTypes.any
};

ProfessionalInformation.defaultProps = {
  showProfessionalModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(ProfessionalInformation);

