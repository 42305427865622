import React, { Component } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { reduxFormValidator } from 'valirator';
import superagent from 'superagent';
import ReCAPTCHA from 'react-google-recaptcha';
import constants from '../../constants/constants';
import { Block, Button, Loading } from './../../components';
import './SelectFunds.css';

class FeeStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reCaptcha: false,
      fees: {}
    };
  }

  componentDidMount = () => {
    this.getFee();
  }

  getFee = async () => {
    const { formValues } = this.props;
    const riskProfile = formValues.managementType === 'DIY' ? 'DIY' : this.getRiskProfileName(formValues.assetAllocationChoice);
    const amount = formValues.amountToInvest;
    try {
      let fees = await superagent.get(`${constants.BLOG_URL}wp-json/api/v1/luxi-fee?risk_profile=${riskProfile}&selected_vale=${amount}`);
      if (fees) {
        fees = JSON.parse(fees.text);
      }

      this.setState({
        fees,
        loadingFee: false
      });
    } catch (error) {
      alert(error);

      return error;
    }
  }

  getRiskProfileName = (riskProfile) => {
    let res;
    if (riskProfile === 'conservative' || riskProfile === 'defensive') {
      res = 'Co';
    } else if (riskProfile === 'balanced') {
      res = 'Eq';
    } else if (riskProfile === 'dynamic') {
      res = 'Dy';
    } else if (riskProfile === 'offensive' || riskProfile === 'flexible') {
      res = 'Off';
    }
    return res;
  }

  getCheckValues = (obj) => {
    // console.log(obj);
    const res = [];
    const fields = Object.keys(obj);
    fields.map((field) => {
      if (obj[field] === true) {
        res.push(field);
      }
      return true;
    });
    return res.join();
  }

  getFieldObject = (fieldType) => {
    let r;
    if (fieldType === 'beneficiary') {
      r = {
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        percentage: null
      };
    } else if (fieldType === 'wealth') {
      r = {
        compnayLifeInsurance: null,
        amountLifeInsurance: null,
        fundsLifeInsurance: null,
        yearLifeInsurance: null
      };
    } else if (fieldType === 'PEA') {
      r = {
        totalPayment: null,
        OpeningYear: null
      };
    }
    return r;
  }

  handleSubmit = (formData) => {
    if (this.state.reCaptcha === false) {
      return false;
    }
    const { signupSelectedFunds } = this.props;
    if (!this.state.charityErrorM) {
      const fields = Object.keys(formData);
      const data = formData;
      fields.map((field) => {
        if (field.includes('_')) {
          const fieldType = field.split('_')[0];
          const fieldName = field.split('_')[1];
          const fieldKey = Number(field.split('_')[2]) - 1;
          if (!data[`${fieldType}_${fieldKey}`]) {
            data[`${fieldType}_${fieldKey}`] = this.getFieldObject(fieldType);
          }
          if (data[`${fieldType}_${fieldKey}`]) {
            data[`${fieldType}_${fieldKey}`][fieldName] = formData[field];
            delete data[field];
          }
        }
        if (field === 'investmentMedia') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'managementMethods') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'financialSecurities') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'amountTocharity') {
          data[field] = this.getCheckValues(data[field]);
        }
        if (field === 'charityToInvest') {
          const tifOptions = [];

          Object.keys(data[field]).map(key =>
            tifOptions.push(`${key}_${data[field][key]}`)
          );
          data.lfcharit = tifOptions;
          delete data[field];
        }

        if (field === 'acharityToInvest') {
          const adtcharity = [];

          Object.keys(data[field]).map(key =>
            adtcharity.push(`${key}_${data[field][key]}`)
          );
          data.aditional = adtcharity;
          delete data[field];
        }
        return true;
      });

      const dataFields = Object.keys(data);
      dataFields.map((field) => {
        if (field.includes('_')) {
          const fieldType = field.split('_')[0];
          if (fieldType === 'beneficiary') {
            if (!data.beneficiaries) {
              data.beneficiaries = [];
            }
            data.beneficiaries.push(data[field]);
          } else {
            if (!data[fieldType]) {
              data[fieldType] = [];
            }
            data[fieldType].push(data[field]);
          }
          delete data[field];
        }
        return true;
      });
      data.luxavieFee = this.state.fees.luxi_fee;
      data.payableFee = this.state.fees.luxi_fee;

      if (this.state.fees.risk_profile !== 'DIY') {
        data.delegateFee = this.state.fees.delegate_fee;
        data.payableFee = parseInt(this.state.fees.luxi_fee) + parseInt(this.state.fees.delegate_fee);
      }
      if (data.charityFee > 0) {
        const aplicableCharity = this.getCharityDiscount(data.charityFee, data.managementType);
        data.payableFee = parseInt(data.payableFee) - parseInt(aplicableCharity);
        data.discountedFee = this.getCharityDiscount(data.charityFee, data.managementType);
      } else {
        data.discountedFee = 0;
      }


      data.signupSelectedFunds = signupSelectedFunds;

      this.props.saveUser(data);
    }
  }

  onChange = (value) => {
    let reCaptcha = false;
    if (value) {
      reCaptcha = true;
    }
    this.setState({
      reCaptcha
    });
  };

  getCharityDiscount = (charityFee, managementType) => {
    if (charityFee > 150 && managementType === 'DIY') {
      return 150;
    } else if (charityFee > 300 && managementType === 'delegate') {
      return 300;
    }
    return charityFee;
  }

  render() {
    const { handleSubmit, formValues, loader, previousPage } = this.props;
    const { fees, loadingFee } = this.state;
    const charityFee = formValues.charityFee || 0;
    const charityDiscount = this.getCharityDiscount(charityFee, formValues.managementType);
    const { translation } = this.props;
    let allocationChoice = '';

    if (formValues.assetAllocationChoice === 'conservative') {
      allocationChoice = `${translation.Conservative}`;
    } else if (formValues.assetAllocationChoice === 'defensive') {
      allocationChoice = `${translation.Defensive}`;
    } else if (formValues.assetAllocationChoice === 'balanced') {
      allocationChoice = `${translation.Balanced}`;
    } else if (formValues.assetAllocationChoice === 'dynamic') {
      allocationChoice = `${translation.Dynamic}`;
    } else if (formValues.assetAllocationChoice === 'offensive') {
      allocationChoice = `${translation.Offensive}`;
    } else if (formValues.assetAllocationChoice === 'flexible') {
      allocationChoice = `${translation.Flexible}`;
    }

    return (

      <Form className="fee-step" onSubmit={handleSubmit(this.handleSubmit)}>
        {loadingFee &&
          <Loading />
        }
        <h4>{translation.PaymentDetail} </h4>
        <Block className="calculated-info-area">
          <h3>{translation.Summary}:</h3>
          <Block className="info-area">
            <h4>{translation.InvestAmount}</h4>


            <h4 id="value">{formValues.amountToInvest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}€</h4>
          </Block>
          <Block className="info-area">
            <h4>{translation.ManagementTypeText}</h4>
            <h4 id="Mtype">{formValues.managementType === 'DIY' ? `${translation.DIY}` : `${translation.Delegate}`}</h4>
          </Block>
          <Block className="info-area">
            <h4>{translation.PortfolioRiskChoose} </h4>
            <h4 id="prisk">{formValues.managementType === 'DIY' ? `${translation.DIY}` : allocationChoice}</h4>
          </Block>
          <Block className="info-area">
            <h4>{translation.CharityDonation}</h4>
            <h4><span>{charityFee}</span>€</h4>
          </Block>
          <Block className="info-area">
            <Block className="left-info">
              <h4>{translation.FarisText}</h4>
              <p>{translation.InsuranceCompanyFees} ({fees.percentage}%)</p>
              <p id="Delegatedlab">{translation.LuxavieFees}</p>
              {formValues.managementType === 'delegate' &&
                <p>{translation.DelegatedManagementfees}</p>
              }
            </Block>
            <Block className="right-info">
              <h4> <span className="fee">{fees.total_fee || 0}</span>€</h4>
              <p id="LifeInsurance">{fees.insur_fee || 0}€</p>
              <p id="Delegated"><span id="mi-LuxavieFee">{((fees.luxi_fee || 0) - charityDiscount) || 0}</span>€</p>
              {formValues.managementType === 'delegate' &&
                <p>{fees.delegate_fee}€</p>
              }
            </Block>
          </Block>
        </Block>

        <Block className="calculated-annual-info">
          <h2> {translation.TotalAnnualFee}: <span className="fee">{((fees.total_fee || 0) - charityDiscount) || 0}</span><sup>€</sup></h2>
          <h5>{translation.TaxIncluded}</h5>
        </Block>

        <Block className="captcha-wrapper">
          <ReCAPTCHA
            sitekey="6LdHpZ8UAAAAAP9vnlOZqYLMbN8HEazuYXbHP8T7"
            onChange={this.onChange}
            theme="light"
          />
        </Block>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button loading={loader} disabled={loader || !this.state.reCaptcha} type="submit" className="next btn btn-default">{translation.SendYourRequest}</Button>
        </Block>
      </Form>
    );
  }
}

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    charity: state.auth.charity,
    signupSelectedFunds: state.fund.signupSelectedFunds,
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
  }),
})(FeeStep));

