import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import { Donut } from './../../components/Charts';


class SectorBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getLabel = (d) => {
    console.log(d);
  }
  render() {
    const { fund } = this.props;
    let data = [];
    if (fund && fund.sectorWeight) {
      data = fund.sectorWeight.map((item) => {
        const row = [];
        row.push(item.name);
        row.push(item.equity);
        return row;
      });
    }

    return (

      <Block className="tile">
        <Block className="d-flex justify-content-between align-items-center">
          <h3 className="tile-title">Sector Breakdown</h3>
        </Block>
        <Block className="d-block">
          <Block className="bar-chart-dv">
            {data.length > 0 &&
              <Donut data={data} />
            }
            { data.length === 0 &&
              <h4>No Data Found</h4>
            }
          </Block>
        </Block>
      </Block>

    );
  }
}

SectorBreakdown.propTypes = {
  fund: PropTypes.any
};

SectorBreakdown.defaultProps = {
  fund: null
};

export default connect(
  state => ({
    fund: state.fund.fundDetails
  }))(SectorBreakdown);

