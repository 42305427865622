import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';
import { ControlLabel, OverlayTrigger } from 'react-bootstrap';
import { Svg } from '../../components';


export default class CustomRadioGroup extends React.Component {
  state = {
    selectedValue: ''
  };

  componentWillMount = () => {
    this.updateStateValue(this.props);
  }

  componentWillUpdate(nextProps, nextState) {
    this.updateStateValue(nextProps, nextState);
  }

  updateStateValue = (props, nextState) => {
    const { input, ...custom } = props;
    let value = '';

    if (input && input.value !== null && input.value !== undefined) {
      value = input.value.toString();
    } else if (custom && custom.value !== null && custom.value !== undefined) {
      value = custom.value.toString();
    }

    if (nextState) {
      nextState.selectedValue = value;
      return;
    }

    this.setState({ selectedValue: value });
  };

  handleChange = (e, { value }) => {
    
    const { input, options, ...custom } = this.props;
    // don't change in disabled mode.
    if (custom.disabled) {
      return;
    }

    // to avoid fire twice Redux form events
    if (this.currentSelection === value && this.currentSelection === input.value) {
      return;
    }
    this.currentSelection = value;

    const onChange = (input && input.onChange) || (custom && custom.onChange);
    
    if (onChange) {
      // find item
      const item = (options || []).find(o => o.value.toString() === value);
      // return the exact type value
      onChange(item.value);
    }
    // Set State
    this.setState({ selectedValue: value });


  };

  render = () => {
    const { input, meta, label, options, ...custom } = this.props;
    const { touched, error } = meta || {};
    const name = (input && input.name) || (custom && custom.name);
    const { selectedValue } = this.state;

    console.log("this.state", this.state);

    return (
      <Form.Field
        error={touched && !!error}
        className={custom.className || ''}
      >
        {label && <label>{label}</label>}
        {
          (options || []).map((item) => {
            console.log("Here is selected value", selectedValue);
            const active = selectedValue === item.value.toString() ? 'active' : 'ss';
            console.log("item.value", item.value, active);

            // alert(item.value);
            return (
              <OverlayTrigger key={item.value} placement="top" overlay={item.tooltip}>
                <div className={`custome-radio-box ${active}`}>
                
                  <Svg type={item.value.toLowerCase()} />
                  <div className="radio-input">
                    <Radio
                      name={name}
                      value={item.value.toString()}
                      checked={selectedValue === item.value.toString()}
                      onChange={this.handleChange}
                      {...custom}
                    />
                    <ControlLabel className="label-text">{item.text}</ControlLabel>
                  </div>
                </div>
              </OverlayTrigger>
            );
            // return (
            //   <span key={item.key || item.value} className={item.className}>
            //     <Radio
            //       name={name}
            //       label={item.text}
            //       value={item.value.toString()}
            //       checked={selectedValue === item.value.toString()}
            //       onChange={this.handleChange}
            //       {...custom}
            //     />
            //   </span>
            // );
          })
        }
      </Form.Field>
    );
  }
}

CustomRadioGroup.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
  })).isRequired,
  meta: PropTypes.object,
  custom: PropTypes.object,
  label: PropTypes.string
};

CustomRadioGroup.defaultProps = {
  input: null,
  name: null,
  meta: null,
  custom: null,
  label: null
};
