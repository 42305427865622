import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
// import userImg from '../../style/images/user.png';

import { Block } from './../../components';

class RealDebtInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    // console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={12} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.WEALTHREALESTATEASSETS}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Realestatevaluation}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.realEstateValuation || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Otherassetsnature}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.otherAssetsNature || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.assetstotalvaluation}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.otherAssetsValuation || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.haveanydebt}?</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.isDebt || 'N/A'}</span>
              }
            </p>
            {userData && userData.userinfo && userData.userinfo.isDebt === 'Yes' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.RemainingCapital}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.remainingCapital || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isDebt === 'Yes' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.AnnualRepayment}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.annualRepayment || 'N/A'}</span>
                }
              </p>
            }
            {userData && userData.userinfo && userData.userinfo.isDebt === 'Yes' &&
              <p className="d-flex justify-content-between align-items-center">
                <Label>{translation.RemainingTime}</Label>
                {userData && userData.userinfo &&
                  <span>{userData.userinfo.remainingTime || 'N/A'}</span>
                }
              </p>
            }

          </Block>

        </Block>
      </Col>

    );
  }
}

RealDebtInformation.propTypes = {
  showRealDebitModal: PropTypes.func,
  userData: PropTypes.any
};

RealDebtInformation.defaultProps = {
  showRealDebitModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(RealDebtInformation);

