import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import logo from './../../style/images/main_logo.png';
import phoneic from '../../style/images/phone-ic.png';
// import accountic from '../../style/images/account-ic.png';
// import { Svg } from '../../components';
import constants from '../../constants/constants';

export default class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollClass: ''
    };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    document.body.addEventListener('click', () => { this.toggleClass('remove'); });
    document.getElementById('nav-btn').addEventListener('click', (ev) => {
      this.toggleClass('add');
      ev.stopPropagation();
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.removeEventListener('click', () => { this.toggleClass('remove'); });
    document.getElementById('nav-btn').removeEventListener('click', (ev) => {
      this.toggleClass('add');
      ev.stopPropagation();
    });
  }

  handleScroll = () => {
    const lastScrollY = window.scrollY;
    const { scrollClass } = this.state;

    if (lastScrollY >= 40) {
      if (scrollClass !== 'fixed-header') {
        this.setState({
          scrollClass: 'fixed-header'
        });
      }
    } else if (scrollClass !== '') {
      this.setState({
        scrollClass: ''
      });
    }
  };

  toggleClass = (type = 'add') => {
    const nvBtn = document.getElementById('nav-btn');
    if (nvBtn) {
      if (type === 'add') {
        document.getElementById('nav-btn').classList.add('active');
        document.getElementById('header-nav').classList.add('open');
        document.body.classList.add('menu-open');
      } else {
        nvBtn.classList.remove('active');
        document.getElementById('header-nav').classList.remove('open');
        document.body.classList.remove('menu-open');
      }
    }
  }

  render() {
    const { scrollClass } = this.state;
    return (
      <header className={scrollClass}>
        <div className="container">
          <div className="logo-area">          
            <a href={constants.BLOG_URL}>
              {/* {scrollClass === '' &&
                <Svg type="logo-shape-1" />
              }
              {scrollClass === 'fixed-header' &&
                <Svg type="logo-shape-2" />
              } */}
              <img alt="" src={logo} />
            </a>
          </div>
          <div className="row-spacing">&nbsp;</div>

          <button id="nav-btn" className="mat-button-wrapper menu-toggle">
            <i className="material-icons">menu</i>
          </button>

          <div id="header-nav" className="mobile-right-side">

            <div className="phone-link top-right-links">
              <a href="tel:0805 69 50 70"><img src={phoneic} alt="" /> <span>0805 69 50 70</span></a>
              <div className="free-call-div">Appel gratuit </div>
            </div>

            {/* <div className="account-link top-right-btn">
              <Link to="/" rel="noopener noreferrer" target="_blank"><span>Login</span></Link>
            </div> */}

          </div>


        </div>

      </header>
    );
  }
}

