import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Label } from 'react-bootstrap';

import { Block } from './../../components';


class CoSubscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  render() {
    const { userData, translation } = this.props;
    // const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    console.log('userData', userData);
    return (
      <Col sm={12} md={12} lg={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">{translation.CosubscriberIdentity}</h3>
          </Block>
          <Block className="d-block financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.FirstName}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.firstNameSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.LastName}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.lastNameSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Gender}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.genderSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Email}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.emailSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.PhoneNumber}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.phoneSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.DateofBirth}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.dateOfBirthSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.CiviStatus}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.civilStatusSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.WeddingStatus}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.weddingStatusSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Since}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.weddingSinceSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Streetnumber}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.streetNumberSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Way}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.waySecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.city}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.citySecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.PostalCode}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.postalCodeSecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.Country}</Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.countrySecSub || 'N/A'}</span>
              }
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.PassportID} </Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.idCardPassportSecSub || 'N/A'}</span>
              }
            </p>

            <p className="d-flex justify-content-between align-items-center">
              <Label>{translation.PassportIssuingCountry} </Label>
              {userData && userData.userinfo &&
                <span>{userData.userinfo.passportCountrySecSub || 'N/A'}</span>
              }
            </p>

            
          </Block>
        </Block>
      </Col>
    );
  }
}

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
}))(CoSubscriber);
