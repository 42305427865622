import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Col, Label } from 'react-bootstrap';
// import user from '../../style/images/user.png';

import { Block } from './../../components';

class FinancialInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    return (
      <Col sm={12} md={6} className="stretch-card">
        <Block className="tile">
          <Block className="d-flex justify-content-between align-items-center">
            <h3 className="tile-title">Financial Information</h3>
            {/* <a className="edit-text">Edit</a> */}
          </Block>
          <Block className="d-block financial-info profile-financial-info">
            <p className="d-flex justify-content-between align-items-center">
              <Label>Address line 1</Label>
              <span>Single</span>
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Annual pretax income</Label>
              <span>$5,000</span>
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Dependents (non-spouse)</Label>
              <span>0</span>
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Employment status</Label>
              <span>None</span>
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Federal tax bracket</Label>
              <span>10%</span>
            </p>
            <p className="d-flex justify-content-between align-items-center">
              <Label>Household investable assets</Label>
              <span>$5,000</span>
            </p>
          </Block>
        </Block>
      </Col>


    );
  }
}

export default connect(
  state => ({
    // initialValues: state.companies.resendInviteItem,
    // me: state.auth.user,
    // translations: getTranslations(state),
  }))(FinancialInformation);

