import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, ButtonGroup } from 'react-bootstrap';

import { Block, Button, Loading } from './../../components';
import { Line } from './../../components/Charts';

import { cryptData, decryptData } from './../../utils/common';


class ChartFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      benchmarkId: undefined,
      period: '1M'
    };
  }

  getChart = (period) => {
    this.props.getChart(period);
    this.setState({
      period
    });
  }

  getClass = (e) => {
    const { period } = this.state;
    if (period === e) {
      return 'active';
    }
    return '';
  }

  handleBenchmark = (e) => {
    const benchmarkId = e.target.value;
    this.props.getBenchmark(benchmarkId);
    this.setState({
      benchmarkId: Number(decryptData(benchmarkId))
    });
  }


  render() {
    const { fundPermoance, benchmarkPermoance, benchmarkList, fundPermoanceBusy, benchmarkPermoanceBusy, translation } = this.props;
    const { benchmarkId } = this.state;
    let colors = ['#029ae5', '#ff917f'];
    const data = [
      {
        name: 'Performace',
        data: fundPermoance || {}
      }
    ];

    if (benchmarkPermoance && Object.keys(benchmarkPermoance).length) {
      let name;
      if (benchmarkId) {
        name = benchmarkList.filter(b => b.id === benchmarkId)[0].exchnageName;
      }
      colors = ['#ff917f', '#029ae5'];
      data.unshift(
        {
          name,
          data: benchmarkPermoance || {}
        }
      );
    }

    let benchmarkListOps;
    if (benchmarkList) {
      benchmarkListOps = benchmarkList.map((item) => {
        return (
          <option key={item.id} value={cryptData(item.id)}>{item.exchnageName}</option>
        );
      });
    }

    return (
      <Block className="tile">

        <Block className="tile-header-part d-flex justify-content-between align-items-center mb-4 w-100">
          <h3 className="tile-title mb-0">Chart</h3>
          <ButtonGroup className="chart-group-btn">
            <Button onClick={() => { this.getChart('1M'); }} className={this.getClass('1M')}>1M</Button>
            <Button onClick={() => { this.getChart('1Y'); }} className={this.getClass('1Y')}>1Y</Button>
            <Button onClick={() => { this.getChart('5Y'); }} className={this.getClass('5Y')}>5Y</Button>
            <Button onClick={() => { this.getChart('10Y'); }} className={this.getClass('10Y')}>10Y</Button>
            <Button onClick={() => { this.getChart('YTD'); }} className={this.getClass('YTD')}>YTD</Button>
          </ButtonGroup>
        </Block>

        <Block className="d-block">
          {(fundPermoanceBusy === true || benchmarkPermoanceBusy === true) &&
            <Loading />
          }
          <Block className="bar-chart-dv">
            <Line colors={colors} data={data} />
          </Block>
          <Block className="chart-info d-flex flex-wrap align-items-center justify-content-center mb-0 mt-1">
            <Block className="form-group list-form d-flex justify-content-between align-items-center mb-0">
              <ControlLabel>{translation.SelectNameBanchmark}</ControlLabel>
              <FormControl
                onChange={this.handleBenchmark}
                componentClass="select"
                placeholder={translation.Select}
                className="custom-select"
              >
                <option value={cryptData(0)}>{translation.Select}</option>
                {benchmarkListOps}
              </FormControl>
            </Block>
          </Block>

        </Block>
      </Block>

    );
  }
}

ChartFile.propTypes = {
  dispatch: PropTypes.func,
  fundPermoance: PropTypes.any,
  benchmarkPermoance: PropTypes.any,
  benchmarkList: PropTypes.any,
  getBenchmark: PropTypes.func,
  getChart: PropTypes.func,
  fundPermoanceBusy: PropTypes.bool,
  benchmarkPermoanceBusy: PropTypes.bool,
};

ChartFile.defaultProps = {
  dispatch: null,
  fundPermoance: null,
  benchmarkPermoance: null,
  benchmarkList: null,
  getBenchmark: null,
  getChart: null,
  fundPermoanceBusy: false,
  benchmarkPermoanceBusy: false,
};

export default connect(
  state => ({
    fundPermoance: state.chart.fundPermoance,
    fundPermoanceBusy: state.chart.fundPermoanceBusy,
    benchmarkPermoance: state.chart.benchmarkPermoance,
    benchmarkPermoanceBusy: state.chart.benchmarkPermoanceBusy,
    benchmarkList: state.fund.benchmarkList,
    translation: state.translation.keys
  })
)(ChartFile);
