import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Col, Table, ControlLabel, Form } from 'react-bootstrap';
import _ from 'lodash';

import { Block, Button } from './../../components';
import { cryptData } from './../../utils/common';

import { getFundList } from './../../redux/modules/fund';
import DropDown from '../../formInputs/DropDown';
import TextBox from '../../formInputs/TextBox';

class FundListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      mainData: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    await dispatch(getFundList());
    this.updateData(this.props.fundList);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.fundList !== nextProps.fundList) {
      this.updateData(nextProps.fundList);
    }
  }

  updateData = (fundList) => {
    let data;
    if (fundList) {
      data = fundList.map((item) => {
        return ({
          id: item.id,
          name: item.name,
          ISIN: item.ISIN,
          currency: item.currency,
          assetClass: item.assetClass
        });
      });
    }
    this.setState({
      data,
      mainData: data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  filterFundList = (formData) => {
    console.log('formData', formData);
    const { mainData } = this.state;
    let newData = mainData;
    if (formData.name) {
      newData = newData.filter(f => f.name.toLowerCase().includes(formData.name.toLowerCase().trim()));
    }
    if (formData.assetClass) {
      newData = newData.filter(f => f.assetClass === formData.assetClass);
    }
    this.setState({
      data: newData
    });
  }

  clearFilter = () => {
    const { mainData } = this.state;
    this.setState({
      data: mainData
    });
    this.props.reset();
  }

  render() {
    const { handleSubmit, translation } = this.props;
    const {
      data,
      column,
      direction
    } = this.state;

    console.log(data);
    let fundListData;
    if (data) {
      fundListData = data.map((item) => {
        return (
          <tr key={item.id}>
            <td><Link to={`/fund-list/${cryptData(item.id)}`}>{item.name}</Link></td>
            <td>{item.ISIN}</td>
            <td>{item.currency}</td>
            <td>{item.assetClass}</td>
          </tr>
        );
      });
    }

    return (
      <Col sm={12} md={12}>
        <Block className="tile fund-list-area">
          <Block className="tile-header-part d-flex list-table-header justify-content-between align-items-center mb-4">
            <h3 className="tile-title">{translation.FundList}</h3>
            <Form onSubmit={handleSubmit(this.filterFundList)}>
              <Block className="filter-top d-flex justify-content-end align-items-center">
                <Block className="form-group search-input">
                  <Field
                    name="name"
                    component={TextBox}
                    placeholder="Enter fund name"
                  />
                </Block>
                <Block className="form-group list-form d-flex justify-content-between align-items-center">
                  <ControlLabel>{translation.SelectAssetClass}</ControlLabel>
                  <Field
                    name="assetClass"
                    className=""
                    component={DropDown}
                    placeholder={translation.SelectAssetClass}
                    options={[
                      { key: 'Equity', text: translation.Equity, value: 'Equity' },
                      { key: 'Fixed Income', text: translation.FixedIncome, value: 'Fixed Income' },
                      { key: 'Commodity', text: translation.Commodity, value: 'Commodity' }
                    ]}
                  />
                </Block>
                <Block className="form-group search-btn">
                  <Button className="btn">{translation.Search}</Button>
                  <Button type="button" onClick={this.clearFilter} className="btn">{translation.Clear}</Button>
                </Block>
              </Block>
            </Form>
          </Block>


          <Block className="table-responsive custom-table ">
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                    onClick={this.handleSort('name')}
                  >
                    {translation.Name}
                  </th>
                  <th
                    className={column === 'ISIN' ? `sorting ${direction}` : 'sorting'}
                    onClick={this.handleSort('ISIN')}
                  >
                    {translation.ISIN}
                  </th>
                  <th
                    className={column === 'currency' ? `sorting ${direction}` : 'sorting'}
                    onClick={this.handleSort('currency')}
                  >
                    {translation.Currency}
                  </th>
                  <th
                    className={column === 'assetClass' ? `sorting ${direction}` : 'sorting'}
                    onClick={this.handleSort('assetClass')}
                  >
                    {translation.AssetClass}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fundListData}
              </tbody>
            </Table>
          </Block>
        </Block>
      </Col>
    );
  }
}


FundListTable.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  fundList: PropTypes.array,
  translation: PropTypes.any.isRequired,
  reset: PropTypes.func
};

FundListTable.defaultProps = {
  dispatch: null,
  handleSubmit: null,
  fundList: null,
  reset: null,
};

export default connect(
  state => ({
    fundList: state.fund.fundList,
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'fundListForm',
  // destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  // validate: reduxFormValidator({
  //   name: {
  //     required: true,
  //   },
  //   email: {
  //     required: true,
  //   }
  // }),
})(FundListTable));

