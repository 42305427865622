import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Label } from 'react-bootstrap';
import userImg from '../../style/images/user.png';

import { Block } from './../../components';

class PersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // test: 'helllo'
    };
  }
  render() {
    const { userData, translation } = this.props;
    const imgSrc = userData && userData.avatar ? userData.avatar : userImg;
    return (
      <Col sm={12} md={4} className="stretch-card">
        <Block className="tile">

          <Block className="user profile">
            <Block className="info text-center">
              {imgSrc &&
                <img className="user-img" src={imgSrc} alt="" />
              }
              {userData &&
                <h4>{userData.firstName || 'N/A'} {userData.lastName || ''}</h4>
              }
              {userData && userData &&
                <p>{userData.email || 'N/A'}</p>
              }
            </Block>
          </Block>


          <Block className="personal-profile nw-personal-profile">         
            <h3 className="tile-title">{translation.PersonalProfile}</h3>
            <Block className="info">
              <Label>{translation.PhoneNumber}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.phone || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.Gender}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.gender || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.Streetnumber}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.streetNumber || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.Voie}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.way || 'N/A'}</p>
              }
            </Block>
            
            <Block className="info">
              <Label>{translation.city}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.city || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.Country}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.country || 'N/A'}</p>
              }
            </Block>

            <Block className="info">
              <Label>{translation.PostalCode}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.postalCode || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.CiviStatus}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.civilStatus || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.DateofBirth}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.dateOfBirth || 'N/A'}</p>
              }
            </Block>
            {userData && userData.userinfo && (userData.userinfo.civilStatus === 'Married' || userData.userinfo.civilStatus === 'Partner') &&
              <React.Fragment>
                <Block className="info">
                  <Label>{translation.WeddingStatus}</Label>
                  {userData && userData.userinfo &&
                    <p>{userData.userinfo.weddingStatus || 'N/A'}</p>
                  }
                </Block>
                <Block className="info">
                  <Label>{translation.Since}</Label>
                  {userData && userData.userinfo &&
                    <p>{userData.userinfo.weddingSince || 'N/A'}</p>
                  }
                </Block>
              </React.Fragment>
            }
            <Block className="info">
              <Label>{translation.PassportID}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.idCardPassport || 'N/A'}</p>
              }
            </Block>
            <Block className="info">
              <Label>{translation.PassportIssuingCountry}</Label>
              {userData && userData.userinfo &&
                <p>{userData.userinfo.passportCountry || 'N/A'}</p>
              }
            </Block>
          </Block>

        </Block>
      </Col>

    );
  }
}

PersonalProfile.propTypes = {
  showProfileModal: PropTypes.func,
  userData: PropTypes.any
};

PersonalProfile.defaultProps = {
  showProfileModal: null,
  userData: null
};

export default connect(
  state => ({
    userData: state.admin.userData,
    translation: state.translation.keys
  }))(PersonalProfile);

