import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';

import { Block } from './../../components';

class Valuation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { fund } = this.props;
    return (

      <Block className="tile">
        <Block className="d-flex justify-content-between align-items-center">
          <h3 className="tile-title">Valuation</h3>
        </Block>
        <Block className="d-block financial-info">
          <p className="d-flex justify-content-between align-items-center">
            <Label>PE Ratio</Label>
            {fund &&
              <span>{fund.priceProspectiveEarnings ? `${fund.priceProspectiveEarnings}%` : '-'}</span>
            }
          </p>
          <p className="d-flex justify-content-between align-items-center">
            <Label>Price / Book Ratio</Label>
            {fund &&
              <span>{fund.priceBook ? `${fund.priceBook}%` : '-'}</span>
            }
          </p>
          <p className="d-flex justify-content-between align-items-center">
            <Label>Dividend-Yield</Label>
            {fund &&
              <span>{fund.dividendYieldFactor ? `${fund.dividendYieldFactor}%` : '-'}</span>
            }
          </p>
          <p className="d-flex justify-content-between align-items-center">
            <Label>Price to Sales</Label>
            {fund &&
              <span>{fund.priceSales ? `${fund.priceSales}%` : '-'}</span>
            }
          </p>
        </Block>
      </Block>

    );
  }
}

Valuation.propTypes = {
  fund: PropTypes.any
};

Valuation.defaultProps = {
  fund: null
};

export default connect(
  state => ({
    fund: state.fund.fundDetails
  }))(Valuation);

