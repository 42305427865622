import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';

import { AuthenticatedAdmin, Block, Button } from '../../components';
import { DashboardHeader, PageTitle } from '../../components/Layout';
import { AdminLeftMenu } from '../../components/AdminLayout';
import { getFundList } from './../../redux/modules/fund';
import { RiskList, linkFundsRisk, getRiskDetail } from './../../redux/modules/risk';

class ManageRiskFunds extends Component {
  constructor(props) {
    super(props);
    const pathArr = window.location.pathname.split('/');
    let riskName;
    if (pathArr[3]) {
      riskName = pathArr[3];
    }
    this.state = {
      riskName,
      selectFundOne: 0,
      selectFundTwo: 0
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    dispatch(getFundList());
    dispatch(getRiskDetail());
    const riskprofile = await dispatch(RiskList());
    this.setRiskProfiles(riskprofile);
  }

  setRiskProfiles = (riskprofile) => {
    const { riskName } = this.state;
    let riskProfileOne;
    let riskProfileTwo;

    if (riskprofile) {
      const indexOne = riskprofile.findIndex(r => r.riskName === riskName && r.riskType.includes('< 100'));
      const indexTwo = riskprofile.findIndex(r => r.riskName === riskName && r.riskType.includes('> 100'));
      if (indexOne >= 0) {
        riskProfileOne = riskprofile[indexOne];
      }
      if (indexTwo >= 0) {
        riskProfileTwo = riskprofile[indexTwo];
      }
    }
    this.setState({
      riskProfileOne,
      riskProfileTwo
    });
  }

  selectFundOne = (e, { value }) => {
    this.setState({
      selectFundOne: value
    });
  }

  linkFundOne = async () => {
    const { dispatch } = this.props;
    const { riskProfileOne, selectFundOne } = this.state;
    if (riskProfileOne && riskProfileOne.id && selectFundOne) {
      const data = {
        risk: riskProfileOne.id,
        fund: selectFundOne
      };
      await dispatch(linkFundsRisk(data));
      // console.log('res =>>', res);
      this.setState({
        selectFundOne: 0
      });
      dispatch(getRiskDetail());
    }
  }

  selectFundTwo = (e, { value }) => {
    this.setState({
      selectFundTwo: value
    });
  }

  linkFundTwo = async () => {
    const { dispatch } = this.props;
    const { riskProfileTwo, selectFundTwo } = this.state;
    if (riskProfileTwo && riskProfileTwo.id && selectFundTwo) {
      const data = {
        risk: riskProfileTwo.id,
        fund: selectFundTwo
      };
      await dispatch(linkFundsRisk(data));
      // console.log('res =>>', res);
      this.setState({
        selectFundTwo: 0
      });
      dispatch(getRiskDetail());
    }
  }

  render() {
    const { translation, fundList, riskDetail } = this.props;
    const {
      riskProfileOne,
      riskProfileTwo,
      selectFundOne,
      selectFundTwo
    } = this.state;

    // console.log('riskDetail =>>', riskDetail);
    let riskDetailOne = [];
    if (riskDetail && riskProfileOne) {
      riskDetailOne = riskDetail.filter(r => r.profileId === riskProfileOne.id);
    }

    let riskDetailTwo = [];
    if (riskDetail && riskProfileTwo) {
      riskDetailTwo = riskDetail.filter(r => r.profileId === riskProfileTwo.id);
    }

    const fundListOne = [];
    if (fundList) {
      fundList.map((f) => {
        const index = riskDetailOne.findIndex(r => r.fundId === f.id);
        // console.log('index =>>>', index);
        if (index === -1) {
          fundListOne.push({
            key: f.id,
            value: f.id,
            text: f.name
          });
        }
        return true;
      });
    }

    const fundListTwo = [];
    if (fundList) {
      fundList.map((f) => {
        const index = riskDetailTwo.findIndex(r => r.fundId === f.id);
        // console.log('index =>>>', index);
        if (index === -1) {
          fundListTwo.push({
            key: f.id,
            value: f.id,
            text: f.name
          });
        }
        return true;
      });
    }

    let dataOne = [];
    if (riskDetailOne) {
      dataOne = riskDetailOne.map(item => (
        <tr key={item.id}>
          <td>{item.riskName}</td>
          <td>{item.name}</td>
          <td>{item.fundISIN}</td>
          <td>{item.profileType}</td>
        </tr>
      ));
    }

    let dataTwo = [];
    if (riskDetailTwo) {
      dataTwo = riskDetailTwo.map(item => (
        <tr key={item.id}>
          <td>{item.riskName}</td>
          <td>{item.name}</td>
          <td>{item.fundISIN}</td>
          <td>{item.profileType}</td>
        </tr>
      ));
    }

    return (
      <AuthenticatedAdmin>
        <PageTitle title="Config Settings" />
        <DashboardHeader />
        <AdminLeftMenu />
        <Block className="app-content">
          <Row>
            <Col sm={12} md={12}>
              <Block className="tile page-heading-outer">
                <Block className="d-flex flex-wrap justify-content-between align-items-center page-heading heading-with-back-link">
                  <h1 className="uppercase">{translation.LinkRiskwithfund}</h1>
                  <Link to="/admin/risk-profile" className="ui button btn with-border back-btn">Go Back</Link>
                </Block>
              </Block>
            </Col>
          </Row>

          <Block className="row">
            <Block className="col-md-12 col-sm-12">
              <Block className="tile min-height-cover">

                <Row>
                  <Col sm={12} md={12}>
                    <Block className="tile-header-part d-flex list-table-header justify-content-between align-items-center mb-4">

                      <h3 className="tile-title block-tile-title pt-0x">{`${this.state.riskName} < 100 K`} </h3>

                      <Block className="filter-top d-flex justify-content-end align-items-center">

                        <Block className="form-group d-flex justify-content-end align-items-center">
                          <Dropdown value={selectFundOne} onChange={this.selectFundOne} placeholder="select fund" search selection options={fundListOne} />
                        </Block>
                        <Block className="form-group search-btn">
                          <Button onClick={this.linkFundOne} className="ui button btn">Add</Button>
                        </Block>
                      </Block>

                    </Block>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={12} md={12}>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>
                            {translation.RiskName}
                          </th>
                          <th>
                            {translation.FundName}
                            {/* {translation.description} */}
                            {/* Description */}
                          </th>
                          <th>
                            {translation.IsIncode}
                            {/* Action */}
                          </th>
                          <th>
                            {translation.RiskType}
                          </th>
                        </tr>
                      </thead>
                      {dataOne}
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <Block className="tile-header-part d-flex list-table-header justify-content-between align-items-center mt-6 mb-4">
                      <h3 className="tile-title block-tile-title">{`${this.state.riskName} > 100 K`} </h3>

                      <Block className="filter-top d-flex justify-content-end align-items-center">
                        <Block className="form-group d-flex justify-content-end align-items-center">
                          <Dropdown value={selectFundTwo} onChange={this.selectFundTwo} placeholder="select fund" search selection options={fundListTwo} />
                        </Block>
                        <Block className="form-group search-btn">
                          <Button onClick={this.linkFundTwo} className="ui button ui button btn">Add</Button>
                        </Block>
                      </Block>
                    </Block>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={12} md={12}>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>
                            {translation.RiskName}
                          </th>
                          <th>
                            {translation.FundName}
                            {/* {translation.description} */}
                            {/* Description */}
                          </th>
                          <th>
                            {translation.IsIncode}
                            {/* Action */}
                          </th>
                          <th>
                            {translation.RiskType}
                          </th>
                        </tr>
                      </thead>
                      {dataTwo}
                    </Table>
                  </Col>
                </Row>
              </Block>
            </Block>
          </Block>
        </Block>
      </AuthenticatedAdmin>
    );
  }
}

export default connect(
  state => ({
    fundList: state.fund.fundList,
    adminLinkingError: state.auth.adminLinkingError,
    riskprofile: state.risk.riskprofile,
    newPolicesError: state.auth.newPolicesError,
    riskDetail: state.risk.riskDetail,
    translation: state.translation.keys
  })
)(ManageRiskFunds);
