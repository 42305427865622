import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Block } from './../../components';
// import { Bar } from './../../components/Charts';

class DetailSectorChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    this.updateData(this.props.portfolioSectorWeight);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioSectorWeight !== nextProps.portfolioSectorWeight) {
      this.updateData(nextProps.portfolioSectorWeight);
    }
  }

  updateData = (portfolioSectorWeight) => {
    let data;
    // const { sectorAll } = this.state;
    if (portfolioSectorWeight) {
      data = portfolioSectorWeight.map((item) => {
        return ({
          name: item[0],
          equity: item[1],
        });
      });
      data = _.sortBy(data, (o) => {
        return (o.equity) ? o.equity : '';
      });
      data = data.reverse();
      // if (!sectorAll) {
      //   data.splice(5, 10);
      // }
    }
    this.setState({
      data
    });
  }

  showAllData = () => {
    this.setState({
      sectorAll: true
    }, () => {
      this.updateData(this.props.portfolioSectorWeight);
    });
  }

  showLessData = () => {
    this.setState({
      sectorAll: false
    }, () => {
      this.updateData(this.props.portfolioSectorWeight);
    });
  }

  render() {
    const { data } = this.state;
    const { translation } = this.props;
    let sectorWeightData;
    if (data) {
      sectorWeightData = data.map((item) => {
        const className = (item.name.replace(' ', '-')).toLowerCase();
        return (
          <Block className={`d-flex justify-content-start align-items-center mb-3 ${className}-text`}>
            <Block className="sector-icon home-icon">&nbsp;</Block>
            <Block className="sector-name">
              {item.name}
            </Block>
            <Block className="progress-sector d-flex w-100 align-items-center">
              <Block className="progress-bar">
                <Block className={`progress-width ${className}`} style={{ width: `${item.equity}%` }}>&nbsp;</Block>
              </Block>
              <Block className="progress-text">
                {item.equity}%
              </Block>
            </Block>
          </Block>
        );
      });
    }

    return (
      <Block>
        <Block className="sector-heading d-flex justify-content-between align-items-center">
          <p>{ translation.PortfoliobreakdownbySector } </p>
          {/* {!this.state.sectorAll &&
            <Button onClick={this.showAllData} className="show_all">Show All</Button>
          }
          {this.state.sectorAll &&
            <Button onClick={this.showLessData} className="show_all">Show Less</Button>
          } */}
        </Block>

        <Block className="d-block mt-4">
          {/* <Donut data={sectorData} /> */}
          {/* <Bar suffix="%" data={sectorData} /> */}
          {/* <FreeScrollBar
            className="h-250"
            autohide="true"
          > */}
          {sectorWeightData}
          {/* </FreeScrollBar> */}

        </Block>
      </Block >
    );
  }
}


DetailSectorChart.propTypes = {
  portfolioSectorWeight: PropTypes.any, 
  translation: PropTypes.any.isRequired  
};

DetailSectorChart.defaultProps = {
  portfolioSectorWeight: null,
};

export default connect(
  state => ({
    portfolioSectorWeight: state.chart.portfolioSectorWeight,
    translation: state.translation.keys,
  })
)(DetailSectorChart);
