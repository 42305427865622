import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Table } from 'react-bootstrap';
import { Block } from './../../components';

import {
  getPortfolioSectorDetails
} from '../../redux/modules/fund';

class Breakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioSectorDetails(portfolioId));
      this.updateData();
    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioSectorDetails(nextProps.portfolioId));
      this.updateData();
    }
  }

  updateData = () => {
    const { portfolioSectorDetails } = this.props;
    console.log('portfolioSectorDetails ==>', portfolioSectorDetails);
    let data;
    if (portfolioSectorDetails) {
      data = portfolioSectorDetails.map((item) => {
        return ({
          holding: item.fundDetail.name,
          basicMaterials: item['Basic Materials'],
          consumerCyclical: item['Consumer Cyclical'],
          financialServices: item['Financial Services'],
          realEstate: item['Real Estate'],
          consumerDefensive: item['Consumer Defensive'],
          healthcare: item.Healthcare,
          utilities: item.Utilities,
          communicationServices: item['Communication Services'],
          energy: item.Energy,
          industrials: item.Industrials,
          technology: item.Technology
        });
      });
    }
    data = _.sortBy(data, (o) => {
      return (o.basicMaterials) ? o.basicMaterials : '';
    });
    data = data.reverse();
    this.setState({
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }


  render() {
    const { data, column, direction } = this.state;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        return (
          <tr key={item.holding}>
            <td>{item.holding}</td>
            <td>{item.basicMaterials ? `${item.basicMaterials}%` : '-'}</td>
            <td>{item.consumerCyclical ? `${item.consumerCyclical}%` : '-'}</td>
            <td>{item.financialServices ? `${item.financialServices}%` : '-'}</td>
            <td>{item.realEstate ? `${item.realEstate}%` : '-'}</td>
            <td>{item.consumerDefensive ? `${item.consumerDefensive}%` : '-'}</td>
            <td>{item.healthcare ? `${item.healthcare}%` : '-'}</td>
            <td>{item.utilities ? `${item.utilities}%` : '-'}</td>
            <td>{item.communicationServices ? `${item.communicationServices}%` : '-'}</td>
            <td>{item.energy ? `${item.energy}%` : '-'}</td>
            <td>{item.industrials ? `${item.industrials}%` : '-'}</td>
            <td>{item.technology ? `${item.technology}%` : '-'}</td>
          </tr>
        );
      });
    }

    return (
      <Row>

        <Col sm={12} md={12}>
          <Block className="d-block mt-5">
            <h2 className="heading-2">
              <u>Breakdown per holding (%)</u>
            </h2>
            <Block className="table-responsive custom-table sector-breakdown-table">
              <Table className="table">
                <thead>
                  <tr>
                    <th
                      className={column === 'holding' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('holding')}
                    >
                      Holding
                    </th>
                    <th
                      className={column === 'basicMaterials' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('basicMaterials')}
                    >
                      <Block className="common-sector-td basic-materials-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Basic Materials</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'consumerCyclical' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('consumerCyclical')}
                    >
                      <Block className="common-sector-td consumer-cyclical-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Consumer Cyclical</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'financialServices' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('financialServices')}
                    >
                      <Block className="common-sector-td financial-services-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Financial Services</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'realEstate' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('realEstate')}
                    >
                      <Block className="common-sector-td real-estate-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Real Estate</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'consumerDefensive' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('consumerDefensive')}
                    >
                      <Block className="common-sector-td consumer-defensive-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Consumer Defensive</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'healthcare' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('healthcare')}
                    >
                      <Block className="common-sector-td healthcare-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Healthcare</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'utilities' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('utilities')}
                    >
                      <Block className="common-sector-td utilities-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Utilities</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'communicationServices' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('communicationServices')}
                    >
                      <Block className="common-sector-td communication-services-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Communication Services</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'energy' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('energy')}
                    >
                      <Block className="common-sector-td energy-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Energy</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'industrials' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('industrials')}
                    >
                      <Block className="common-sector-td industrials-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Industrials</span> */}
                      </Block>
                    </th>
                    <th
                      className={column === 'technology' ? `sorting ${direction}` : 'sorting'}
                      onClick={this.handleSort('technology')}
                    >
                      <Block className="common-sector-td technology-td">
                        <Block className="sector-icon mr-2" />
                        {/* <span className="sector-name">Technology</span> */}
                      </Block>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {tableData}
                </tbody>
              </Table>
            </Block>
          </Block>
        </Col>
      </Row>
    );
  }
}


Breakdown.propTypes = {
  dispatch: PropTypes.func,
  portfolioSectorDetails: PropTypes.any,
  portfolioId: PropTypes.any
};

Breakdown.defaultProps = {
  dispatch: null,
  portfolioSectorDetails: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioId: state.auth.selectedPortfolioId,
    portfolioSectorDetails: state.fund.portfolioSectorDetails,
  }))(Breakdown);

