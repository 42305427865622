import React from 'react'; // eslint-disable-line
import { FormControl, ControlLabel, FormGroup } from 'react-bootstrap';


const TextBox = ({ input, label, required, meta, inline, labelBottom, ...custom }) => {
  const { touched, error } = meta || {};
  const hasError = (touched && !!error);

  if (input && !input.value && custom && custom.disabled && custom.emptyValue) {
    input.value = custom.emptyValue;
  }

  if (custom && custom.emptyValue) {
    delete custom.emptyValue;
  }

  return (
    <FormGroup>
      {label && <ControlLabel>{label} {required && <span className="require red-text">*</span>}</ControlLabel>}
      <FormControl
        className={hasError ? 'error' : ''}
        {...input}
        {...custom}
        autoComplete="poo"
      />
      {labelBottom && <span className="input-msg-info">{labelBottom}</span>}
      {touched && error && <span className="help-block">{touched && error ? error : ''}</span>}
    </FormGroup>
  );
};

export default TextBox;
