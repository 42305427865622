import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { AuthenticatedUser, Block, PortfolioDetailsTabLink, SelectPortfolio } from './../../components';
import { LeftMenuSidebar, DashboardHeader, PageTitle } from '../../components/Layout';

import { Benchmark, Breakdown, DetailSectorChart } from './../../components/DetailSector';
import {
  getPortfolioSectorWeights
} from '../../redux/modules/chart';

class DetailSector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const { dispatch, portfolioId } = this.props;
    if (portfolioId) {
      await dispatch(getPortfolioSectorWeights(portfolioId));
      this.setState({ loading: false });
    }
  }
  componentWillReceiveProps = async (nextProps) => {
    const { dispatch } = this.props;
    if (this.props.portfolioId !== nextProps.portfolioId) {
      await dispatch(getPortfolioSectorWeights(nextProps.portfolioId));
    }
  }

  render() {
    const { translation } = this.props;
    return (
      <AuthenticatedUser>
        <PageTitle title="Detailed Holding" />
        <DashboardHeader />
        <LeftMenuSidebar />
        <Block className="app-content">
          <Row>
            <Col sm={12} md={12}>
              <Block className="tile min-height-cover">
                <Block className="tile-header-part heading-width-select d-flex justify-content-between align-items-center">
                  <h3 className="tile-title">{translation.PortfolioDetails}</h3>
                  <SelectPortfolio />
                </Block>
                <PortfolioDetailsTabLink />
                <Row className="mt-4">
                  <Col sm={12} md={7}>
                    <Benchmark />
                  </Col>
                  <Col sm={12} md={5} className="sector-col">
                    <DetailSectorChart />
                  </Col>
                </Row>
                <Breakdown />

              </Block>
            </Col>
          </Row>
        </Block>
      </AuthenticatedUser>
    );
  }
}

DetailSector.propTypes = {
  dispatch: PropTypes.func,
  portfolioSectorWeight: PropTypes.any,
  translation: PropTypes.any.isRequired,
  portfolioId: PropTypes.any
};

DetailSector.defaultProps = {
  dispatch: null,
  portfolioSectorWeight: null,
  portfolioId: undefined
};

export default connect(
  state => ({
    portfolioSectorWeight: state.chart.portfolioSectorWeight,
    portfolioId: state.auth.selectedPortfolioId,
    translation: state.translation.keys
  }))(DetailSector);
