// import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
// import Immutable from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import moduleReducers from './moduleReducers';
import ApiClient from './ApiClient';
import clientMiddleware from './clientMiddleware';
import routerReducer from './reactRouterReducer';

const api = new ApiClient();

export default function configureStore(history, preLoadedState) {
  const middlewares = [
    thunkMiddleware.withExtraArgument(api),
    clientMiddleware(api),
    routerMiddleware(history)
  ];

  if (process.env.NODE_ENV !== 'production') {
    if (!window.devToolsExtension) {
      const { logger } = require('redux-logger');
      middlewares.push(logger);
    }
  }

  const enhancer = compose(
    applyMiddleware(...middlewares),
    // other store enhancers if any,
    window.devToolsExtension ? window.devToolsExtension({
      name: 'Luxavie', actionsBlacklist: ['REDUX_STORAGE_SAVE']
    }) : noop => noop
  );

  const reducers = combineReducers({
    router: routerReducer,
    form: formReducer,
    ...moduleReducers
  });

  const initialState = preLoadedState || {}; // Immutable.fromJS(preLoadedState || {});
  return createStore(reducers, initialState, enhancer);
}
