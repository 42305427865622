import React, { Component } from 'react';
import { Row, Col, ControlLabel, Button, Form } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { reduxFormValidator } from 'valirator';
import PropTypes from 'prop-types';
import { createNumberMask } from 'redux-form-input-masks';

import DropDown from '../../formInputs/DropDown';
import TextBox from '../../formInputs/TextBox';
import RadioGroup from '../../formInputs/RadioGroup';
import { Block } from './../../components';
import FieldsWealth from './FieldsWealth';
import FieldsPEA from './FieldsPEA';

const currencyMask = createNumberMask({
  // decimalPlaces: 2
});

class SavingProductStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, previousPage, formValues, translation } = this.props;
    return (
      <Form className="annualIncome-information-form" onSubmit={handleSubmit}>
        <h4>{translation.WEALTHSAVINGSPRODUCTS1}</h4>
        <Row>
          <Col xs={12} md={6}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.lifeinsurancepolicy}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isLifeInsurance"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>
          <Col xs={12} md={6}>
            <Block className="form-group">
              <ControlLabel>{translation.fortunesecurities}? </ControlLabel>
              <Field
                name="fortuneSecurities"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="fortune-securities"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.Lessthan} 250 k€`, value: `${translation.Lessthan} 250 k€` },
                  { key: 'two', text: `${translation.Between}  250 k€ et 500 k€`, value: `${translation.Between}  250 k€ et 500 k€` },
                  { key: 'three', text: `${translation.Between} 500 k€ et 1250 k€`, value: `${translation.Between} 500 k€ et 1250 k€` },
                  { key: 'four', text: `${translation.Between} 1250 k€ et 2500k€`, value: `${translation.Between} 1250 k€ et 2500k€` },
                  { key: 'five', text: `${translation.GreaterThan} 2500k€`, value: `${translation.GreaterThan} 2500k€` }
                ]}
              />
            </Block>
          </Col>

        </Row>

        {formValues.isLifeInsurance === 'yes' &&
          <React.Fragment>
            {/* <h4>{translation.HOLDINGSAVINGSPRODUCTS}</h4> */}
            <Block id="insurance-policy" className="w-100">
              <Row>
                <Col xs={12} md={6}>
                  <Block className="curreny-input position-relative">
                    <Field
                      name="totalLifeInsurance"
                      component={TextBox}
                      label={translation.totalLifeinsurance}
                      placeholder=""
                      {...currencyMask}
                    />
                    <span className="input-group-addon">€</span>
                  </Block>
                </Col>
              </Row>
              <FieldsWealth />
            </Block>
          </React.Fragment>
        }
        <h4>{translation.WEALTHSAVINGSPRODUCTS2}</h4>
        <Row>
          <Col xs={12} md={12}>
            <Block className="form-group new-radio-btn mb-0">
              <ControlLabel>{translation.havePEA}?</ControlLabel>
              <Block className="d-flex flex-wrap">
                <Field
                  name="isPEA"
                  component={RadioGroup}
                  className="radio-input mr-4 mb-0"
                  options={
                    [{
                      value: 'yes',
                      text: `${translation.Yes}`
                    }, {
                      value: 'no',
                      text: `${translation.No}`
                    }]
                  }
                />
              </Block>
            </Block>
          </Col>

        </Row>

        {formValues.isPEA === 'yes' &&
          <React.Fragment>
            <FieldsPEA />
          </React.Fragment>
        }

        <h4>{translation.SAVINGPRODUCTOTHER}</h4>
        <Row>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.EquityAmount}</ControlLabel>
              <Field
                name="equityAmountPEA"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Equity Amount"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.Lessthan} 10k€`, value: '< 10K' },
                  { key: 'two', text: `${translation.Between} 10k€ et 50k€`, value: '10K - 50K' },
                  { key: 'three', text: `${translation.Between} 50k€ et 250k€`, value: '50K - 250K' },
                  { key: 'four', text: `${translation.Between} 250k€ et 500k€`, value: '250K - 500K' },
                  { key: 'five', text: `${translation.Between} 500k€ et 1M€`, value: '500K - 1M€' },
                  { key: 'six', text: `${translation.Between} 1M€ et 5M€`, value: '1M€ - 5M€' },
                  { key: 'seven', text: `${translation.GreaterThan} 5M€`, value: '> 5M€' }
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.ShorttermAmount}</ControlLabel>
              <Field
                name="shortTermPEA"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="Term Amount"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.Lessthan} 10k€`, value: '< 10K' },
                  { key: 'two', text: `${translation.Between} 10k€ et 50k€`, value: '10K - 50K' },
                  { key: 'three', text: `${translation.Between} 50k€ et 250k€`, value: '50K - 250K' },
                  { key: 'four', text: `${translation.Between} 250k€ et 500k€`, value: '250K - 500K' },
                  { key: 'five', text: `${translation.Between} 500k€ et 1M€`, value: '500K - 1M€' },
                  { key: 'six', text: `${translation.Between} 1M€ et 5M€`, value: '1M€ - 5M€' },
                  { key: 'seven', text: `${translation.GreaterThan} 5M€`, value: '> 5M€' }
                ]}
              />
            </Block>
          </Col>
          <Col xs={12} md={4}>
            <Block className="form-group">
              <ControlLabel>{translation.Cashcome}</ControlLabel>
              <Field
                name="cashToComePEA"
                search
                fluid
                multiple={false}
                selection
                selectOnBlur={false}
                noResultsMessage="cash-come"
                component={DropDown}
                options={[
                  { key: 'one', text: `${translation.Lessthan} 10k€`, value: '< 10K' },
                  { key: 'two', text: `${translation.Between} 10k€ et 50k€`, value: '10K - 50K' },
                  { key: 'three', text: `${translation.Between} 50k€ et 250k€`, value: '50K - 250K' },
                  { key: 'four', text: `${translation.Between} 250k€ et 500k€`, value: '250K - 500K' },
                  { key: 'five', text: `${translation.Between} 500k€ et 1M€`, value: '500K - 1M€' },
                  { key: 'six', text: `${translation.Between} 1M€ et 5M€`, value: '1M€ - 5M€' },
                  { key: 'seven', text: `${translation.GreaterThan} 5M€`, value: '> 5M€' }
                ]}
              />
            </Block>
          </Col>
        </Row>

        <Block className="form-group btn-container d-flex justify-content-center mt-5">
          <Button className="btn btn-primary btn-block previous" onClick={previousPage}>{translation.Previous}</Button>
          <Button type="submit" className="next">{translation.Next}</Button>
        </Block>

      </Form>
    );
  }
}

SavingProductStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired,
  formValues: PropTypes.object
};

SavingProductStep.defaultProps = {
  formValues: {}
};

export default connect(
  state => ({
    formValues: getFormValues('signupform')(state),
    translation: state.translation.keys
  })
)(reduxForm({
  form: 'signupform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormValidator({
    // name: {
    //   required: true,
    // },
    // email: {
    //   required: true,
    // }
  }),
})(SavingProductStep));
