import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Block } from './../../components';
import { NavLink } from './../../components/Layout';

class PortfolioDetailsTabLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { translation } = this.props;
    return (
      <Block className="tabing-content d-flex justify-content-between align-items-center">
        <ul>
          <li>
            <NavLink type="tab" to="/detail-holding">{translation.Holdings}</NavLink>
          </li>

          <li className="">
            <NavLink type="tab" to="/detail-asset">{translation.AssetClasses}</NavLink>
          </li>


          <li className="">
            <NavLink type="tab" to="/detail-geographic-countries">{translation.Geography}</NavLink>
          </li>

          <li className="">
            <NavLink type="tab" to="/detail-sector">{translation.Sectors}</NavLink>
          </li>

        </ul>
      </Block>

    );
  }
}


PortfolioDetailsTabLink.propTypes = {
  translation: PropTypes.any.isRequired
  // dispatch: PropTypes.func,
  // fundList: PropTypes.array
};

PortfolioDetailsTabLink.defaultProps = {
  // dispatch: null
  // fundList: null
};

export default connect(
  state => ({
    translation: state.translation.keys
    // fundList: state.fund.fundList
  }))(PortfolioDetailsTabLink);

