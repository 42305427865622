import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table } from 'react-bootstrap';

import { Block } from './../../components';

class Benchmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: undefined,
      direction: null
    };
  }

  componentDidMount = async () => {
    this.updateData(this.props.portfolioSectorWeight);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.portfolioSectorWeight !== nextProps.portfolioSectorWeight) {
      this.updateData(nextProps.portfolioSectorWeight);
    }
  }

  updateData = (portfolioSectorWeight) => {
    let data;
    if (portfolioSectorWeight) {
      data = portfolioSectorWeight.map((item) => {
        return ({
          name: item[0],
          stock: item[1],
        });
      });
      data = _.sortBy(data, (o) => {
        return (o.stock) ? o.stock : '';
      });

      data = data.reverse();
    }
    this.setState({
      data
    });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, (o) => {
          return (o[clickedColumn]) ? o[clickedColumn] : '';
        }),
        direction: 'ascending'
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const {
      data,
      column,
      direction
    } = this.state;

    let tableData;
    if (data) {
      tableData = data.map((item) => {
        const className = (item.name.replace(' ', '-')).toLowerCase();
        return (
          <tr key={item.name}>
            <td>
              <Block className={`common-sector-td ${className}-td`}>
                <Block className="sector-icon mr-2" />
                <span className="sector-name">{item.name}</span>
              </Block>
            </td>
            <td>{item.stock}%</td>
          </tr>
        );
      });
    }

    return (
      <Block className="d-block">
        <Block className="table-responsive custom-table table-heading-bg">
          <Table className="table">
            <thead>
              <tr>
                <th
                  className={column === 'name' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('name')}
                >
                  Name
                </th>
                <th
                  className={column === 'stock' ? `sorting ${direction}` : 'sorting'}
                  onClick={this.handleSort('stock')}
                >
                  Stock
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </Table>
        </Block>
      </Block>
    );
  }
}


Benchmark.propTypes = {
  portfolioSectorWeight: PropTypes.any,
};

Benchmark.defaultProps = {
  portfolioSectorWeight: null,
};

export default connect(
  state => ({
    portfolioSectorWeight: state.chart.portfolioSectorWeight,
  })
)(Benchmark);

