const MONTHS_LIST = [
  { text: 'January', value: 0 },
  { text: 'February', value: 1 },
  { text: 'March', value: 2 },
  { text: 'April', value: 3 },
  { text: 'May', value: 4 },
  { text: 'June', value: 5 },
  { text: 'July', value: 6 },
  { text: 'August', value: 7 },
  { text: 'September', value: 8 },
  { text: 'October', value: 9 },
  { text: 'November', value: 10 },
  { text: 'December', value: 11 }
];

const BLOG_URL = 'https://www.luxavie.fr/';


export default {
  CRPTYRTOKEN: 'xg8250nbg4klq5b3',
  DATE_FORMAT: 'DD-MM-YYYY',
  MONTHS_LIST,
  BLOG_URL
};
